import * as React from "react";
import styled from "styled-components";
import Color from "../../const/Color";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { useState } from "react";
import FontAwesome from "../../atoms/FontAwesome";
import { showMessageBoxAction } from "../../../modules/messageBoxModule";
import {
  hideOverlayAction,
  showOverlayAction,
} from "../../../modules/overlayModule";
import {
  openPostModalAction,
  selectSpotAction,
  editSingleAction,
  destroyAction as destroyPostAction,
} from "../../../modules/postFormModule";
import {
  destroyAction as destroyCommentAction,
  CommentsState,
  commentsSelector,
  findComment,
} from "../../../modules/commentsModule";
import PostConst from "../../const/PostConst";
import { isSignedIn } from "src/js/redseal/lib/auth-util";
import {
  PostsState,
  postsSelector,
  findPost,
} from "../../../modules/postsModule";
import {
  spotsSelector,
  SpotsState,
  findSpot,
} from "../../../modules/spotsModule";
import {
  TRACKEVENT_CATEGORY,
  gaTrackEvent,
} from "src/js/redseal/lib/analytics-util";
import { accountSelector, AccountState } from "../../../modules/accountModule";
import Zindex from "../../const/Zindex";

interface IProps {
  targetId: number;
  targetType: string;
  isShowModal: boolean;
  setIsShowModal: (isShowModal: boolean) => void;
}

const PostEditOptionModal: React.FC<IProps> = (props): JSX.Element => {
  const dispatch = useDispatch();
  const postsState: PostsState = useSelector(postsSelector);
  const commentsState: CommentsState = useSelector(commentsSelector);
  const spotsState: SpotsState = useSelector(spotsSelector);
  const accountState: AccountState = useSelector(accountSelector);

  // Local state
  const [isShowModalLocal, setIsShowModalLocal] = useState(false);

  /**
   * 閉じるボタン
   * @param event
   */
  const handleClickClose = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsShowModalLocal(false);
    gaTrackEvent(
      TRACKEVENT_CATEGORY.EDIT_OPTION_MODAL,
      `Close${props.targetType}`,
      props.targetId
    );
  };

  /**
   * 編集ボタン
   */
  const handleOnEdit = () => {
    const post = findPost(props.targetId, postsState);
    if (post === null) {
      return;
    }
    const spot = findSpot(post.spot_id, spotsState);

    setIsShowModalLocal(false);
    setTimeout(() => {
      openPostModalAction(dispatch, PostConst.POST_MODE_EDIT);
      selectSpotAction(dispatch, spot.spot_id, spot.name);
      editSingleAction(dispatch, post);
    }, 300);
    gaTrackEvent(
      TRACKEVENT_CATEGORY.EDIT_OPTION_MODAL,
      "EditPost",
      post.post_id
    );
  };

  const isSelfContent = (): boolean => {
    switch (props.targetType) {
      case "Post":
        const post = findPost(props.targetId, postsState);
        if (post === null) {
          return false;
        }
        return accountState.myPosts.indexOf(post.post_id) > -1;
      case "Comment":
        const comment = findComment(
          props.targetId,
          commentsState
        );
        if (comment === null) {
          return false;
        }
        return accountState.myComments.indexOf(comment.comment_id) > -1;
      default:
        return false;
    }
  };

  /**
   * 違反報告ボタン
   */
  const handleOnReport = () => {
    if (props.targetType === null || props.targetId === null) {
      window.alert(
        "予期せぬエラーが発生しました。画面を再読み込みしてください。"
      );
      return;
    }
    gaTrackEvent(
      TRACKEVENT_CATEGORY.EDIT_OPTION_MODAL,
      "Report",
      props.targetId
    );
    location.href = `/info/report?target-type=${props.targetType}&target-id=${props.targetId}`;
  };

  /**
   * 削除ボタン
   */
  const handleOnRemove = () => {
    if (props.targetId === null) {
      window.alert(
        "予期せぬエラーが発生しました。画面を再読み込みしてください。"
      );
      return;
    }

    if (!window.confirm(`${getTargetName()}を削除します。よろしいですか？`)) {
      return;
    }

    showOverlayAction(dispatch, "削除中…");

    switch (props.targetType) {
      case "Post":
        gaTrackEvent(
          TRACKEVENT_CATEGORY.EDIT_OPTION_MODAL,
          "DeletePost",
          props.targetId
        );
        destroyPostAction(
          dispatch,
          props.targetId,
          handleOnPostDestroySuccess,
          handleOnDestroyError
        );
        break;
      case "Comment":
        gaTrackEvent(
          TRACKEVENT_CATEGORY.EDIT_OPTION_MODAL,
          "DeleteComment",
          props.targetId
        );
        destroyCommentAction(
          dispatch,
          props.targetId,
          handleOnCommentDestroySuccess,
          handleOnDestroyError
        );
        break;
      default:
        break;
    }
  };

  const handleOnPostDestroySuccess = (response: IPost) => {
    showMessageBoxAction(dispatch, `${getTargetName()}の削除が完了しました。`);
    setTimeout(() => {
      location.href = "/";
    }, 1000);
  };

  const handleOnCommentDestroySuccess = (response: IComment) => {
    setIsShowModalLocal(false);
    setTimeout(() => {
      hideOverlayAction(dispatch);
      showMessageBoxAction(
        dispatch,
        `${getTargetName()}の削除が完了しました。`
      );
    }, 1000);
  };

  const handleOnDestroyError = () => {
    showMessageBoxAction(
      dispatch,
      `申し訳ありません。${getTargetName()}の削除に失敗しました。時間を空けて再度お試しください。`
    );
    hideOverlayAction(dispatch);
  };

  const handleOnCopyURL = () => {
    gaTrackEvent(
      TRACKEVENT_CATEGORY.EDIT_OPTION_MODAL,
      `CopyPostURL`,
      props.targetId
    );

    const post = findPost(props.targetId, postsState);
    if (post === null) {
      return;
    }
    const shareURL = `${location.protocol}//${location.host}/posts/${
      post.post_id
    }`;

    navigator.clipboard.writeText(shareURL);

    showMessageBoxAction(
      dispatch,
      `投稿ページのURLをクリップボードにコピーしました。`
    );
    hideOverlayAction(dispatch);
    setIsShowModalLocal(false);
  };

  const getTargetName = (): string => {
    return props.targetType === "Post" ? "投稿" : "コメント";
  };

  return (
    <CSSTransition
      in={props.isShowModal}
      timeout={0}
      classNames="post-comment-modal"
      onEntered={() => setIsShowModalLocal(true)}
      unmountOnExit
    >
      <Wrapper theme={{ zindex: Zindex.POST_EDIT_OPTION_MODAL }}>
        <CSSTransition
          in={isShowModalLocal}
          timeout={200}
          classNames="post-comment-content"
          onExited={() => {
            props.setIsShowModal(false);
          }}
        >
          <Content>
            <Header>
              <HeaderTitle>{getTargetName()}の編集</HeaderTitle>
              <Hide onClick={(event) => handleClickClose(event)}>
                <FontAwesome className="fa fa-times" />
              </Hide>
            </Header>
            <OptionGroup>
              {isSignedIn() && isSelfContent() && (
                <OptionButton theme={{ color: Color.SUB_COLOR_RED }}>
                  <button type="button" onClick={handleOnRemove}>
                    削除する
                  </button>
                </OptionButton>
              )}
              {props.targetType === "Post" &&
                isSignedIn() &&
                isSelfContent() && (
                  <OptionButton theme={{ color: Color.SUB_COLOR_BLUE }}>
                    <button type="button" onClick={handleOnEdit}>
                      編集する
                    </button>
                  </OptionButton>
                )}
              {props.targetType === "Post" && (
                <OptionButton theme={{ color: Color.SUB_COLOR_BLUE }}>
                  <button type="button" onClick={handleOnCopyURL}>
                    URLをコピーする
                  </button>
                </OptionButton>
              )}
              {props.targetType === "Post" && (
                <OptionButton theme={{ color: Color.SUB_COLOR_BLUE }}>
                  <button type="button" onClick={handleOnReport}>
                    違反報告
                  </button>
                </OptionButton>
              )}
            </OptionGroup>
          </Content>
        </CSSTransition>
      </Wrapper>
    </CSSTransition>
  );
};
export default PostEditOptionModal;

const Wrapper = styled.div`
  z-index: ${({ theme }) => theme.zindex};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: ${Color.BG_COLOR_MODAL};

  transition: opacity 400ms linear;
  &.post-comment-modal-enter {
    opacity: 0;
  }
  &.post-comment-modal-enter-active {
    opacity: 1;
  }
  &.post-comment-modal-enter-done {
    opacity: 1;
  }
  &.post-comment-modal-exit {
    opacity: 1;
  }
  &.post-comment-modal-exit-active {
    opacity: 0;
  }
  &.post-comment-modal-exit-done {
    opacity: 0;
  }
`;

const Content = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 0;
  background-color: ${Color.BG_COLOR_BOX};
  border-radius: 8px 8px 0 0 / 8px 8px 0 0;
  color: ${Color.FONT_COLOR};
  height: 0;
  width: 600px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }

  transition: height 400ms ease;
  &.post-comment-content-enter {
    height: 0;
  }
  &.post-comment-content-enter-active {
    height: 62%;
  }
  &.post-comment-content-enter-done {
    height: 62%;
  }
  &.post-comment-content-exit {
    height: 62%;
  }
  &.post-comment-content-exit-active {
    height: 0;
  }
  &.post-comment-content-exit-done {
    height: 0;
  }
`;

const Header = styled.div`
  height: 40px;
  width: 100%;
  padding: 8px 16px;
  position: relative;
`;

const HeaderTitle = styled.div`
  width: 100%;
  text-align: center;
  font-weight: bold;
`;

const Hide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40px;
  span {
    cursor: pointer;
  }
`;

const OptionGroup = styled.div`
  margin-top: 8px;
  padding: 0 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const OptionButton = styled.div`
  margin-top: 16px;
  button {
    background: ${({ theme }) => theme.color};
    border: 3px solid ${({ theme }) => theme.color};
    color: white;
    border-radius: 19px;
    padding: 8px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    width: 100%;
    &:disabled {
      background: ${Color.SUB_COLOR_GOLD};
      border: 3px solid ${Color.SUB_COLOR_GOLD};
    }
    &:hover {
      opacity: 0.8;
    }
  }
`;
