import { useEffect } from "react";
import { isIE } from "./ua-util";

export const useIntersection = (ref, callBack: () => void) => {
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        callBack();
      }
    }, options);

    observer.observe(ref.current);

    return () => {
      observer.unobserve(ref.current);
    };
  });
};
