import * as React from "react";
import styled from "styled-components";
import { fontsize } from "../../const/Mixin";

const NoContent: React.FC = (props): JSX.Element => {
  return <Wrapper>まだ投稿はありません。</Wrapper>;
};
export default NoContent;

const Wrapper = styled.div`
  width: 100%;
  ${fontsize(14)}
  text-align: center;
`;
