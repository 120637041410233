import { zeroPadding } from "./string-util";

/**
 * 指定の月の日数を返します
 * @param year
 * @param month
 */
export const daysInMonth = (year: number, month: number): number => {
  return new Date(year, month, 0).getDate();
};

/**
 * 現在の年を返す
 */
export const currentYear = (): number => {
  return currentDateInfo().year;
};

/**
 * 現在の年を返す
 * @param date
 */
const _currentYear = (date: Date): number => {
  return date.getFullYear();
};

/**
 * 現在の月を返す
 */
export const currentMonth = (): number => {
  return currentDateInfo().month;
};

/**
 * 現在の月を返す
 * @param date
 */
const _currentMonth = (date: Date): number => {
  return date.getMonth() + 1;
};

/**
 * 現在の日を返す
 */
export const currentDay = (): number => {
  return currentDateInfo().day;
};

/**
 * 現在の日を返す
 * @param date
 */
const _currentDay = (date: Date): number => {
  return date.getDate();
};

/**
 * 現在の曜日を返す
 */
export const currentWday = (): number => {
  return currentDateInfo().wday;
};

/**
 * 現在の曜日を返す
 * @param date
 */
const _currentWday = (date: Date): number => {
  return date.getDay();
};

export interface DateInfo {
  year: number;
  month: number;
  day: number;
  wday: number;
  isToday: boolean;
  isFeature: boolean;
}

/**
 * 曜日
 */
export const WDAY = {
  0: "日",
  1: "月",
  2: "火",
  3: "水",
  4: "木",
  5: "金",
  6: "土"
};

export const nextMonthDateInfo = (year: number, month: number): DateInfo => {
  if (month === 12) {
    return {
      year: year + 1,
      month: 1,
      day: 1,
      wday: 0,
      isToday: false,
      isFeature: false
    };
  }
  return {
    year: year,
    month: month + 1,
    day: 1,
    wday: 0,
    isToday: false,
    isFeature: false
  };
};

export const prevMonthDateInfo = (year: number, month: number): DateInfo => {
  if (month === 1) {
    return {
      year: year - 1,
      month: 12,
      day: 1,
      wday: 0,
      isToday: false,
      isFeature: false
    };
  }
  return {
    year: year,
    month: month - 1,
    day: 1,
    wday: 0,
    isToday: false,
    isFeature: false
  };
};

/**
 * 現在年月日
 */
export const currentDateInfo = (): DateInfo => {
  return getDateInfo(new Date());
};

/**
 * 指定年月日
 */
export const getDateInfo = (date: Date): DateInfo => {
  const today = new Date();
  return {
    year: _currentYear(date),
    month: _currentMonth(date),
    day: _currentDay(date),
    wday: _currentWday(date),
    isToday: isToday(
      _currentYear(date),
      _currentMonth(date),
      _currentDay(date),
      defaultDateInfo(today)
    ),
    isFeature: false
  };
};

const defaultDateInfo = (date: Date): DateInfo => {
  return {
    year: _currentYear(date),
    month: _currentMonth(date),
    day: _currentDay(date),
    wday: _currentWday(date),
    isToday: false,
    isFeature: false
  };
};

const isToday = (
  year: number,
  month: number,
  day: number,
  today: DateInfo
): boolean => {
  return year === today.year && month === today.month && day === today.day;
};

const isFeature = (
  year: number,
  month: number,
  day: number,
  today: DateInfo
): boolean => {
  const a = `${year}${zeroPadding(month, 2)}${zeroPadding(day, 2)}`;
  const b = `${today.year}${zeroPadding(today.month, 2)}${zeroPadding(today.day, 2)}`;
  return parseInt(a) > parseInt(b);
};

/**
 * 指定の年月の日、曜日情報の配列を返す.
 * @param year
 * @param month
 * @param today
 */
export const daysInMonthInfo = (
  year: number,
  month: number,
  today: DateInfo
): DateInfo[] => {
  const daysInfo = [];
  const days = daysInMonth(year, month);
  const beginningDate = new Date(year, month - 1, 1);
  let _wday = beginningDate.getDay();
  for (let i = 0; i < days; i++) {
    daysInfo.push({
      year: year,
      month: month,
      day: i + 1,
      wday: _wday,
      isToday: isToday(year, month, i + 1, today),
      isFeature: isFeature(year, month, i + 1, today)
    });
    if (_wday > 5) {
      _wday = 0;
    } else {
      _wday++;
    }
  }
  return daysInfo;
};
