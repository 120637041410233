import { RedsealState } from "../store/createStore";
import { createSlice, PayloadAction, Action, Dispatch } from "@reduxjs/toolkit";
import { postContainerDispatcher } from "../../lib/module-util";
import {
  usecaseSpotShow,
  usecaseSpotShowType,
} from "../../domain/usecases/spots";
import { setUsersAction } from "./usersModule";

// state -------
export interface SpotsState {
  spots: ISpot[];
}

const SpotsStateInitialState: SpotsState = {
  spots: [],
};

// payload -------
export interface SetSpotsAction extends Action {
  type: string;
  spots: ISpot[];
}

// selector -------
export const spotsSelector = (state: RedsealState): SpotsState => {
  return state.spots;
};

// reducers -------
export const spotsModule = createSlice({
  name: "spots",
  initialState: SpotsStateInitialState,
  reducers: {
    setSpotsAction: (
      state: SpotsState,
      action: PayloadAction<SetSpotsAction>
    ) => {
      state.spots = mergeState(action.payload.spots, state);
    },
  },
});

// actions -------

export const setSpotsAction = (
  dispatch: Dispatch<Action<any>>,
  spots: ISpot[]
) => {
  dispatch(
    spotsModule.actions.setSpotsAction({
      type: "setSpotsAction",
      spots: spots,
    })
  );
};

export const spotsShowTypeAction = (
  dispatch: Dispatch<Action<any>>,
  spotId: number,
  postType: string,
  postId: number | null,
  page: number,
  onSuccess: (postIdList: number[], hasNext: boolean) => void,
  onError: () => void
) => {
  usecaseSpotShowType(spotId, postType, postId, page)
    .then((response) => {
      postContainerDispatcher(dispatch, response);
      onSuccess(
        response.posts.map((post: IPost) => {
          return post.post_id;
        }),
        response.paginator.has_next
      );
    })
    .catch((e) => {
      console.log(e);
      onError();
    });
};

export const spotsShowAction = (
  spotId: number,
  onSuccess: (user: ICertifiedUser) => void,
  onError: () => void
) => {
  usecaseSpotShow(spotId)
    .then((response) => {
      onSuccess(response);
    })
    .catch((e) => {
      console.log(e);
      onError();
    });
};

// utils -------

const mergeState = (targets: ISpot[], state: SpotsState): ISpot[] => {
  const newState: ISpot[] = [...state.spots];
  for (let i = 0; i < targets.length; i++) {
    if (!updateState(targets[i], newState)) {
      newState.push(targets[i]);
    }
  }
  return newState;
};

const updateState = (target: ISpot, source: ISpot[]): boolean => {
  for (let i = 0; i < source.length; i++) {
    if (
      target.spot_id === source[i].spot_id &&
      target.updated_at > source[i].updated_at
    ) {
      source[i] = target;
      return true;
    }
  }
  return false;
};

export const findSpot = (id: number, state: SpotsState): ISpot | null => {
  for (let i = 0; i < state.spots.length; i++) {
    if (state.spots[i].spot_id === id) {
      return state.spots[i];
    }
  }
  return null;
};
