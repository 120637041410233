import * as React from "react";
import styled from "styled-components";
import FontAwesome from "../../atoms/FontAwesome/FontAwesome";
import Color from "../../const/Color";
import PostConst from "../../const/PostConst";
import { useDispatch, useSelector } from "react-redux";
import {
  PostFormState,
  postFormSelector,
  addAction,
  submitAction,
  selectVisitedDateAction,
  selectShowVisitedDateAction,
  closePostModalAction,
} from "../../../modules/postFormModule";
import IconText from "../../molecules/IconText";
import PostItemList from "../../molecules/PostItemList";
import { formControl, fontsize } from "../../const/Mixin";
import { CSSTransition } from "react-transition-group";
import { useEffect, useState } from "react";
import DatePickerModal from "../DatePickerModal";
import SearchSpotConst from "../../const/SearchSpotConst";
import { openSearchSpotModalAction } from "../../../modules/searchSpotModule";
import { showMessageBoxAction } from "../../../modules/messageBoxModule";
import {
  hideOverlayAction,
  showOverlayAction,
  updateOverlayAction,
} from "../../../modules/overlayModule";
import {
  gaTrackEvent,
  TRACKEVENT_CATEGORY,
} from "src/js/redseal/lib/analytics-util";

// -- Context
export interface IPostFormDatePickerModalContext {
  isShowDatePickerModal: boolean;
  setIsShowDatePickerModal: (isShowDatePickerModal: boolean) => void;
}
export const PostFormDatePickerModalContext = React.createContext<
  IPostFormDatePickerModalContext
>({
  isShowDatePickerModal: false,
  setIsShowDatePickerModal: (isShowDatePickerModal: boolean) => {},
});

/**
 *
 * @param props
 */
const PostForm: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const postFormState: PostFormState = useSelector(postFormSelector);

  // Local state
  const [isShowAddPhotoButton, setIsShowAddPhotoButton] = useState(true);
  const [isShowDatePickerModal, setIsShowDatePickerModal] = useState(false);

  let fileInput = null;

  useEffect(() => {
    // 投稿枚数の上限チェック。上限に達した場合、画像追加ボタンを消す。
    setIsShowAddPhotoButton(
      postFormState.posts.length < PostConst.MAX_POST_NUM
    );
  }, [postFormState.posts.length]);

  const withinLimits = (files: FileList): Boolean => {
    return (
      files.length > PostConst.MAX_POST_NUM ||
      files.length + postFormState.posts.length > PostConst.MAX_POST_NUM
    );
  };

  /**
   * 投稿フォーム クローズ.
   */
  const handleClickClose = () => {
    if (
      postFormState.posts.length > 0 &&
      !window.confirm(
        "編集中の投稿があります。\n編集内容を破棄してこのまま閉じますか？"
      )
    ) {
      return false;
    }
    closePostModalAction(dispatch);
    gaTrackEvent(TRACKEVENT_CATEGORY.POST_MODAL, "ClosePostModal");
  };

  /**
   * 画像追加ボタン.
   */
  const handleClickAddPhoto = () => {
    gaTrackEvent(TRACKEVENT_CATEGORY.POST_MODAL, "AddPhoto");
    fileInput.click();
  };

  /**
   * 画像選択時イベント.
   * @param event
   */
  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (withinLimits(event.target.files)) {
      window.alert(`一度に投稿できるのは${PostConst.MAX_POST_NUM}つまでです。`);
      return;
    }
    addAction(dispatch, postFormState.posts, event.target.files);
  };

  /**
   * 寺社検索モーダル 表示イベント.
   */
  const handleClickSearch = () => {
    gaTrackEvent(TRACKEVENT_CATEGORY.POST_MODAL, "OpenSearchSpotModal");
    openSearchSpotModalAction(dispatch, SearchSpotConst.MODE_POST);
  };

  /**
   * 訪問日クリックイベント.
   */
  const handleClickVisitedDate = () => {
    gaTrackEvent(TRACKEVENT_CATEGORY.POST_MODAL, "VisitedDate");
    setIsShowDatePickerModal(true);
  };

  /**
   * 訪問日ラジオ 選択イベント
   * @param event
   */
  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    selectShowVisitedDateAction(dispatch, parseInt(event.target.value));
    gaTrackEvent(
      TRACKEVENT_CATEGORY.POST_MODAL,
      "SelectShowVisitedDate",
      parseInt(event.target.value)
    );
  };

  /**
   * 投稿ボタン
   */
  const handleOnSubmit = () => {
    if (postFormState.spotId === null) {
      window.alert("投稿する寺社を選択してください。");
      return;
    }

    if (!window.confirm("投稿します。よろしいですか？")) {
      return;
    }

    gaTrackEvent(TRACKEVENT_CATEGORY.POST_MODAL, "Submit");
    showOverlayAction(dispatch, "投稿中…");
    submitAction(
      dispatch,
      postFormState,
      handleOnSubmitSuccess,
      handleOnSubmitError
    );
  };

  const handleOnSubmitSuccess = (response: IPostsMulti) => {
    if (postFormState.mode === PostConst.POST_MODE_CREATE) {
      showMessageBoxAction(dispatch, "投稿が完了しました。");
      updateOverlayAction(
        dispatch,
        `${response.spot.name}ページに移動します。しばらくお待ち下さい。`
      );
      setTimeout(() => {
        location.href = response.redirect_url;
      }, 3000);
    } else {
      closePostModalAction(dispatch);
      setTimeout(() => {
        hideOverlayAction(dispatch);
        showMessageBoxAction(dispatch, "投稿が完了しました。");
      }, 1000);
    }
  };

  const handleOnSubmitError = () => {
    showMessageBoxAction(
      dispatch,
      "申し訳ありません。投稿が失敗しました。時間を空けて再度お試しください。"
    );
    hideOverlayAction(dispatch);
  };

  //-- renderer
  return (
    <CSSTransition
      in={postFormState.isShowModal}
      timeout={400}
      classNames="post-form-modal"
      unmountOnExit
    >
      <Wrapper
        className="post-form-modal"
        theme={{ zindex: postFormState.zindex }}
      >
        <Container>
          <Header>
            <CloseButton onClick={() => handleClickClose()}>
              <FontAwesome className="fa fa-times" />
              閉じる
            </CloseButton>
          </Header>
          <form>
            <KeywordInputWrapper>
              <KeywordInput
                name="post[spot_input]"
                value={postFormState.spotName}
                placeholder="寺社を選択"
                autoComplete="off"
                onClick={() => handleClickSearch()}
                readOnly
              />
              <FontAwesome className="fa fa-search" />
            </KeywordInputWrapper>

            <PostItemList posts={postFormState.posts} />
            {postFormState.mode === PostConst.POST_MODE_CREATE &&
              isShowAddPhotoButton && (
                <AddPhotoButton onClick={() => handleClickAddPhoto()}>
                  <IconText text="画像を追加" iconClass="fa fa-image" />
                  <input
                    type="file"
                    name="post[picture]"
                    id="post_picture"
                    ref={(input: HTMLInputElement) => {
                      fileInput = input;
                    }}
                    multiple
                    onChange={(event) => handleChangeFile(event)}
                  />
                </AddPhotoButton>
              )}
            <VisitedDateInputWrapper>
              <VisitedDateInput
                placeholder="訪問日"
                autoComplete="off"
                onClick={() => handleClickVisitedDate()}
                value={postFormState.visitedDate}
                readOnly
              />
              <FontAwesome className="fa fa-calendar" />
            </VisitedDateInputWrapper>
            <VisitedDateVisibilityWrapper>
              訪問日を
              <input
                type="radio"
                value="1"
                checked={
                  postFormState.showVisitedDate ===
                  PostConst.SHOW_VISITED_DATE_ON
                    ? true
                    : false
                }
                id="visited_date_1"
                name="visited_date_radio"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeRadio(event);
                }}
              />
              <label htmlFor="visited_date_1">表示する</label>
              <input
                type="radio"
                value="0"
                checked={
                  postFormState.showVisitedDate ===
                  PostConst.SHOW_VISITED_DATE_OFF
                    ? true
                    : false
                }
                id="visited_date_0"
                name="visited_date_radio"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeRadio(event);
                }}
              />
              <label htmlFor="visited_date_0">表示しない</label>
            </VisitedDateVisibilityWrapper>
            <PostButton>
              <button
                type="button"
                onClick={handleOnSubmit}
                disabled={!postFormState.isEnablePostButton}
              >
                投稿する
              </button>
            </PostButton>
            <Notice>
              <ul>
                <li>
                  <FontAwesome className="fa fa-asterisk" />
                  一度に投稿できるのは{PostConst.MAX_POST_NUM}つまでです。
                </li>
                <li>
                  <FontAwesome className="fa fa-asterisk" />
                  コメントは{PostConst.DESCRIPTION_MIN}
                  文字以上{PostConst.DESCRIPTION_MAX}文字以内で書いてください。
                </li>
                <li>
                  <FontAwesome className="fa fa-asterisk" />
                  訪問日を表示するを選ぶと訪問日は全体に公開されます。表示しないを選ぶと自分以外には見えません。
                </li>
              </ul>
            </Notice>
          </form>
        </Container>
        <PostFormDatePickerModalContext.Provider
          value={{
            isShowDatePickerModal: isShowDatePickerModal,
            setIsShowDatePickerModal: setIsShowDatePickerModal,
          }}
        >
          <DatePickerModal
            visitedDate={postFormState.visitedDate}
            onSelectVisitedDate={(visitedDate: string) => {
              selectVisitedDateAction(dispatch, visitedDate);
            }}
          />
        </PostFormDatePickerModalContext.Provider>
      </Wrapper>
    </CSSTransition>
  );
};
export default PostForm;

const Wrapper = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zindex};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: auto;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  background: ${Color.BG_COLOR_MODAL};
  color: ${Color.FONT_COLOR_REVERSAL};
  -webkit-overflow-scrolling: touch;
  opacity: 0;

  transition: opacity 400ms linear;
  &.post-form-modal-enter {
    opacity: 0;
  }
  &.post-form-modal-enter-active {
    opacity: 1;
  }
  &.post-form-modal-enter-done {
    opacity: 1;
  }
  &.post-form-modal-exit {
    opacity: 1;
  }
  &.post-form-modal-exit-active {
    opacity: 0;
  }
  &.post-form-modal-exit-done {
    opacity: 0;
  }
`;

const Container = styled.div`
  margin: 60px auto 0;
  width: 600px;
  @media screen and (max-width: 768px) {
    margin: 0 auto;
    width: 100%;
    padding: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled.div`
  cursor: pointer;
  span {
    font-weight: bold;
    margin-right: 4px;
    &:hover {
      color: ${Color.BTN_BG_COLOR_NORMAL_HOVER};
    }
  }
`;

const KeywordInputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 16px 0 0 0;
  span {
    position: absolute;
    top: 10px;
    left: 8px;
    color: ${Color.SUB_COLOR_GOLD};
  }
`;

const KeywordInput = styled.input`
  color: ${Color.FONT_COLOR};
  cursor: pointer;
  width: 100%;
  padding-left: 32px !important;
  ${fontsize(14)}
  ${formControl()}
`;

const AddPhotoButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  border: 2px solid white;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  height: 64px;
  input {
    display: none;
  }
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  span {
    margin-right: 8px;
  }
  &.within-limit {
    display: none;
  }
`;

const VisitedDateInputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 16px 0 0 0;
  span {
    position: absolute;
    top: 10px;
    left: 8px;
    color: ${Color.SUB_COLOR_GOLD};
  }
`;

const VisitedDateInput = styled.input`
  color: ${Color.FONT_COLOR};
  width: 100%;
  padding-left: 32px !important;
  ${fontsize(14)}
  ${formControl()}
  cursor: pointer;
`;

const VisitedDateVisibilityWrapper = styled.div`
  margin-top: 8px;
  input {
    cursor: pointer;
    margin-left: 8px;
  }
  label {
    cursor: pointer;
    margin-left: 4px;
  }
`;

const PostButton = styled.div`
  margin-top: 16px;
  button {
    background: ${Color.SUB_COLOR_RED};
    border: 3px solid ${Color.SUB_COLOR_RED};
    color: white;
    border-radius: 19px;
    padding: 8px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    width: 100%;
    &:disabled {
      background: ${Color.SUB_COLOR_GOLD};
      border: 3px solid ${Color.SUB_COLOR_GOLD};
    }
    &:hover {
      opacity: 0.8;
    }
  }
`;

const Notice = styled.div`
  margin-top: 16px;
  ${fontsize(14)}
  span {
    margin-right: 4px;
  }
  ul {
    --webkit-padding-start: 0px;
    padding: 0px;
    li {
      list-style: none;
    }
  }
`;
