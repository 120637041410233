import { del, get, post } from "../network/api-client";
import { isPostType } from "../../lib/post-type-util";
import { isPrefSlug } from "../../lib/area-util";

/**
 * ログインユーザ情報を取得するAPI
 */
export const currentUser = async (): Promise<IRedsealAPIResponse<IUser>> => {
  try {
    const API_URI = "/api/users/current";
    return await get<IUser>(API_URI);
  } catch (err) {
    throw err;
  }
};

/**
 * ログインユーザのプロフィール情報を取得するAPI
 */
 export const getMyProfile = async (): Promise<IRedsealAPIResponse<IAccount>> => {
  try {
    const API_URI = "/api/users/my/profile";
    return await get<IAccount>(API_URI);
  } catch (err) {
    throw err;
  }
};

/**
 * プロフィールを更新するAPI
 * @param form
 */
 export const postMyProfile = async (
  form: FormData
): Promise<IRedsealAPIResponse<IAccount>> => {
  try {
    const API_URI = "/api/users/my/profile";
    return await post<IAccount>(API_URI, form);
  } catch (err) {
    throw err;
  }
};

/**
 * プロフィールを画像を削除するAPI
 * @param form
 */
 export const removeMyProfilePicture = async (): Promise<IRedsealAPIResponse<IAccount>> => {
  try {
    const API_URI = "/api/users/my/profile/picture";
    return await del<IAccount>(API_URI);
  } catch (err) {
    throw err;
  }
};

/**
 * ユニークURLをチェックするAPI
 * @param form
 */
 export const checkSlug = async (form: FormData): Promise<IRedsealAPIResponse<IAccount>> => {
  try {
    const API_URI = "/api/users/my/profile/slug/check";
    return await post<IAccount>(API_URI, form);
  } catch (err) {
    throw err;
  }
};

/**
 * サインアウトするAPI
 */
export const userSignOut = async (): Promise<IRedsealAPIResponse<IUser>> => {
  try {
    const API_URI = "/api/users/sign_out";
    return await del<IUser>(API_URI);
  } catch (err) {
    throw err;
  }
};

/**
 * フォローするAPI
 * @param form
 */
export const userFollow = async (
  form: FormData
): Promise<IRedsealAPIResponse<IFollow>> => {
  try {
    const API_URI = "/api/users/follow";
    return await post<IFollow>(API_URI, form);
  } catch (err) {
    throw err;
  }
};

/**
 * フォローを解除するAPI
 */
export const userUnfollow = async (
  form: FormData
): Promise<IRedsealAPIResponse<IFollow>> => {
  try {
    const API_URI = "/api/users/unfollow";
    return await post<IFollow>(API_URI, form);
  } catch (err) {
    throw err;
  }
};

/**
 * ユーザをブロックするAPI
 * @param form
 */
export const userBlockApply = async (
  userId: number
): Promise<IRedsealAPIResponse<IUser>> => {
  try {
    const API_URI = "/api/users/my/block/apply/<userId>";
    return await post<IUser>(
      API_URI.replace("<userId>", userId.toString(10)),
      null
    );
  } catch (err) {
    throw err;
  }
};

/**
 * ユーザのブロックを解除するAPI
 * @param form
 */
export const userBlockRelease = async (
  userId: number
): Promise<IRedsealAPIResponse<IUser>> => {
  try {
    const API_URI = "/api/users/my/block/release/<userId>";
    return await del<IUser>(
      API_URI.replace("<userId>", userId.toString(10)),
      null
    );
  } catch (err) {
    throw err;
  }
};

export const userTimeline = async (
  page: number
): Promise<IRedsealAPIResponse<IPostContainer>> => {
  try {
    const API_URI = "/api/users/my?page=<page>";
    return await get<IPostContainer>(
      API_URI.replace("<page>", page.toString(10))
    );
  } catch (err) {
    throw err;
  }
};

export const userProfileShow = async (
  uid: string,
  postType: string,
  postPref: string,
  page: number
): Promise<IRedsealAPIResponse<IPostContainer>> => {
  try {
    const params = [];
    if (isPostType(postType)) {
      params.push(`post_type=${postType}`);
    }
    if (isPrefSlug(postPref)) {
      params.push(`pref_slug=${postPref}`);
    }
    params.push(`page=${page.toString(10)}`);

    const API_URI = "/api/users/<uid>";
    return await get<IPostContainer>(
      `${API_URI.replace("<uid>", uid)}?${params.join("&")}`
    );
  } catch (err) {
    throw err;
  }
};

export const userSpotShow = async (
  uid: string,
  spotId: number,
  page: number
): Promise<IRedsealAPIResponse<IPostContainer>> => {
  try {
    const params = [];
    params.push(`page=${page.toString(10)}`);

    const API_URI = "/api/users/<uid>/spots/<spotId>";
    return await get<IPostContainer>(
      `${API_URI.replace("<uid>", uid).replace(
        "<spotId>",
        spotId.toString(10)
      )}?${params.join("&")}`
    );
  } catch (err) {
    throw err;
  }
};

export const userCalendarVisit = async (
  uid: string,
  year: string,
  month: string,
  day: string,
  page: number
): Promise<IRedsealAPIResponse<IPostContainer>> => {
  try {
    const params = [];
    params.push(`page=${page.toString(10)}`);

    const API_URI = "/api/users/<uid>/calendars/visit/<year>/<month>/<day>";
    return await get<IPostContainer>(
      `${API_URI.replace("<uid>", uid)
        .replace("<year>", year)
        .replace("<month>", month)
        .replace("<day>", day)}?${params.join("&")}`
    );
  } catch (err) {
    throw err;
  }
};
