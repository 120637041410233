import * as React from "react";
import FontAwesome from "../../atoms/FontAwesome/FontAwesome";

interface IProps {
  text: string,
  iconClass: string,
}

const IconText: React.FC<IProps> = (props): JSX.Element => {
  return(
    <React.Fragment>
      <FontAwesome className={props.iconClass} />{props.text}
    </React.Fragment>
  );
};
export default IconText;
