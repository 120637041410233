import * as React from "react";
import styled from "styled-components";
import Color from "../../const/Color";
import {
  DateInfo,
  prevMonthDateInfo,
  nextMonthDateInfo,
  getDateInfo,
} from "src/js/redseal/lib/date-util";
import FontAwesome from "../../atoms/FontAwesome";
import { useState, useEffect, useContext } from "react";
import { zeroPadding, isBlank } from "src/js/redseal/lib/string-util";
import {
  IPostFormDatePickerModalContext,
  PostFormDatePickerModalContext,
} from "../PostForm";
import { CSSTransition } from "react-transition-group";
import YearSelect from "../../atoms/YearSelect";
import MonthSelect from "../../atoms/MonthSelect";
import Loading from "../../atoms/Loading";
import DatePickerCalendar from "./DatePickerCalendar";
import {
  gaTrackEvent,
  TRACKEVENT_CATEGORY,
} from "src/js/redseal/lib/analytics-util";

interface IProps {
  visitedDate: string;
  onSelectVisitedDate: (visitedDate: string) => void;
}

/**
 *
 * @param props
 */
const DatePickerModal: React.FC<IProps> = (props): JSX.Element => {
  // Local state
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(null);

  const postFormDatePickerModalContext = useContext<
    IPostFormDatePickerModalContext
  >(PostFormDatePickerModalContext);

  /**
   * 先月、翌月の切り替え.
   * @param event
   * @param month
   */
  const handleClickOtherMonth = (
    event: React.MouseEvent<HTMLDivElement>,
    month: string
  ) => {
    event.preventDefault();
    if (month === "prev") {
      const prev = prevMonthDateInfo(currentMonth.year, currentMonth.month);
      setCurrentMonth({ year: prev.year, month: prev.month });
    }
    if (month === "next") {
      const next = nextMonthDateInfo(currentMonth.year, currentMonth.month);
      setCurrentMonth({ year: next.year, month: next.month });
    }
    gaTrackEvent(
      TRACKEVENT_CATEGORY.POST_MODAL,
      `CalendarChange${month === "prev" ? "Prev" : "Next"}Month`
    );
  };

  const formatDate = (dayInfo: DateInfo): string => {
    return `${dayInfo.year}/${zeroPadding(dayInfo.month, 2)}/${zeroPadding(
      dayInfo.day,
      2
    )}`;
  };

  const handleSelectYear = (year: number) => {
    setCurrentMonth({ year: year, month: currentMonth.month });
    gaTrackEvent(TRACKEVENT_CATEGORY.POST_MODAL, "CalendarSelectYear");
  };

  const handleSelectMonth = (month: number) => {
    setCurrentMonth({ year: currentMonth.year, month: month });
    gaTrackEvent(TRACKEVENT_CATEGORY.POST_MODAL, "CalendarSelectMonth");
  };

  /**
   * カレンダの日をクリック
   * @param dayInfo
   */
  const handleClickDay = (dayInfo: DateInfo) => {
    props.onSelectVisitedDate(formatDate(dayInfo));
    setIsShowCalendar(false);
    gaTrackEvent(TRACKEVENT_CATEGORY.POST_MODAL, "CalendarSelectDay");
  };

  /**
   * 今日ボタン クリック
   */
  const handleClickToday = () => {
    const dayInfo = getDateInfo(new Date());
    props.onSelectVisitedDate(formatDate(dayInfo));
    setIsShowCalendar(false);
    gaTrackEvent(TRACKEVENT_CATEGORY.POST_MODAL, "CalendarSelectToday");
  };

  /**
   * 消去ボタン クリック
   */
  const handleClickErase = () => {
    props.onSelectVisitedDate("");
    setIsShowCalendar(false);
    gaTrackEvent(TRACKEVENT_CATEGORY.POST_MODAL, "CalendarSelectClear");
  };

  /**
   * 閉じるボタン クリック
   */
  const handleClickClose = () => {
    setIsShowCalendar(false);
    gaTrackEvent(TRACKEVENT_CATEGORY.POST_MODAL, "CalendarSelectClose");
  };

  useEffect(() => {
    // 初回マウント時の処理 & 訪問日更新時
    const dateInfo = getDateInfo(
      isBlank(props.visitedDate)
        ? new Date()
        : new Date(Date.parse(props.visitedDate))
    );
    setCurrentMonth({ year: dateInfo.year, month: dateInfo.month });
  }, [props.visitedDate]);

  //-- renderer
  if (!currentMonth) {
    return <Loading />;
  }
  return (
    <CSSTransition
      in={postFormDatePickerModalContext.isShowDatePickerModal}
      timeout={0}
      classNames="date-picker-modal"
      unmountOnExit
      onEntered={() => setIsShowCalendar(true)}
    >
      <CalendarContainer className="date-picker-modal">
        <CSSTransition
          in={isShowCalendar}
          timeout={200}
          classNames="date-picker-content"
          onExited={() =>
            postFormDatePickerModalContext.setIsShowDatePickerModal(false)
          }
        >
          <Calendar className="date-picker-content">
            <Header>
              <ChangeMonth onClick={(e) => handleClickOtherMonth(e, "prev")}>
                <FontAwesome className="fa fa-chevron-left" />
              </ChangeMonth>
              <CurrentMonth>
                <YearSelect
                  selected={currentMonth.year}
                  fn={handleSelectYear}
                />
                <MonthSelect
                  selected={currentMonth.month}
                  fn={handleSelectMonth}
                />
              </CurrentMonth>
              <ChangeMonth onClick={(e) => handleClickOtherMonth(e, "next")}>
                <FontAwesome className="fa fa-chevron-right" />
              </ChangeMonth>
            </Header>
            <DatePickerCalendar
              selectedDate={props.visitedDate}
              currentYear={currentMonth.year}
              currentMonth={currentMonth.month}
              onSelectVisitedDate={handleClickDay}
            />
            <Footer>
              <div onClick={() => handleClickToday()}>
                <FontAwesome className="fa fa-circle" />
                今日
              </div>
              <div onClick={() => handleClickErase()}>
                <FontAwesome className="fa fa-minus" />
                消去
              </div>
              <div onClick={() => handleClickClose()}>
                <FontAwesome className="fa fa-times" />
                閉じる
              </div>
            </Footer>
          </Calendar>
        </CSSTransition>
      </CalendarContainer>
    </CSSTransition>
  );
};
export default DatePickerModal;

const CalendarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${Color.BG_COLOR_MODAL};
  z-index: 1002;

  transition: opacity 400ms linear;
  &.date-picker-modal-enter {
    opacity: 0;
  }
  &.date-picker-modal-enter-active {
    opacity: 1;
  }
  &.date-picker-modal-enter-done {
    opacity: 1;
  }
  &.date-picker-modal-exit {
    opacity: 1;
  }
  &.date-picker-modal-exit-active {
    opacity: 1;
  }
  &.date-picker-modal-exit-done {
    opacity: 0;
  }
`;

const Calendar = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 0;
  background-color: ${Color.BG_COLOR_BOX};
  border-radius: 8px 8px 0 0 / 8px 8px 0 0;
  color: ${Color.FONT_COLOR};
  padding: 16px;
  width: 600px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }

  transition: height 200ms ease, opacity 200ms linear;
  &.date-picker-content-enter {
    height: 0;
    opacity: 0;
  }
  &.date-picker-content-enter-active {
    height: 62%;
    opacity: 1;
  }
  &.date-picker-content-enter-done {
    height: 62%;
    opacity: 1;
  }
  &.date-picker-content-exit {
    height: 62%;
    opacity: 1;
  }
  &.date-picker-content-exit-active {
    height: 0;
    opacity: 0;
  }
  &.date-picker-content-exit-done {
    height: 0;
    opacity: 0;
  }
`;

const Header = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`;

const CurrentMonth = styled.div`
  display: flex;
  margin: 0 56px;
`;

const ChangeMonth = styled.div`
  cursor: pointer;
  text-align: center;
  width: 24px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
  div {
    cursor: pointer;
    span {
      margin-right: 8px;
      &.fa-circle {
        color: ${Color.SUB_COLOR_BLUE};
      }
      &.fa-minus {
        color: ${Color.SUB_COLOR_RED};
      }
    }
  }
`;
