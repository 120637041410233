import * as React from "react";
import styled from "styled-components";
import { normalBox } from "../../const/Mixin";
import FontAwesome from "../FontAwesome";
import Color from "../../const/Color";

interface IProps {
  isViewMoreLoading: boolean;
  fn(): void;
}

const ViewMoreButton: React.FC<IProps> = (props): JSX.Element => {
  const handleViewMore = () => {
    props.fn();
  };

  return (
    <Wrapper>
      <div onClick={() => handleViewMore()}>
        {props.isViewMoreLoading ? (
          <React.Fragment>
            もっと見る
            <FontAwesome className={"fa fa-spinner fa-pulse"} />
          </React.Fragment>
        ) : (
          <React.Fragment>もっと見る</React.Fragment>
        )}
      </div>
    </Wrapper>
  );
};
export default ViewMoreButton;

const Wrapper = styled.div`
  div {
    ${normalBox}
    text-align: center;
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
      background: ${Color.BG_COLOR_BOX_HOVER};
    }
    @media screen and (max-width: 768px) {
      margin: 0 8px;
    }

    span {
      margin-left: 2px;
    }
  }
`;
