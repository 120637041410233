import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action, Dispatch } from "redux";
import { RedsealState } from "../store/createStore";
import {
  usecaseSpotsSearchByCurrentLocation,
  usecaseSpotsSearchByKeyword,
} from "../../domain/usecases/spots";
import { isBlank } from "../../lib/string-util";
import { OVERALL_PREFECTURE_ID } from "../components/const/Prefectures";
import SearchSpotConst from "../components/const/SearchSpotConst";
import { isNullOrUndefined } from "../../lib/object-util";
import Zindex from "../components/const/Zindex";
import { showOverlayAction, hideOverlayAction } from "./overlayModule";
import RsGeolocation from "../../lib/RsGeolocation";
import RedsealAPIError from "../../errors/RedsealAPIError";
import BodyFixed from "../../lib/BodyFixed";

// state -------
export interface SearchSpotState {
  isLoading: boolean;
  isShowModal: boolean;
  mode: string;
  viewType: string;
  conditions: {
    keyword: string;
    prefectureId?: number;
    position: {
      latitude: number;
      longitude: number;
    };
  };
  spots: ISpotsSearch[] | null;
  zindex: number;
}

const SearchSpotInitialState: SearchSpotState = {
  isLoading: false,
  isShowModal: false,
  mode: SearchSpotConst.MODE_SEARCH,
  viewType: SearchSpotConst.VIEW_TYPE_LIST,
  conditions: {
    keyword: "",
    prefectureId: OVERALL_PREFECTURE_ID,
    position: {
      latitude: null,
      longitude: null,
    },
  },
  spots: null,
  zindex: Zindex.SEARCH_MODAL,
};

// payload -------
export interface OpenSearchModalAction extends Action {
  type: string;
  mode: string;
}

export interface CloseSearchModalAction extends Action {
  type: string;
}

export interface ToggleSearchModalAction extends Action {
  type: string;
  mode: string;
}

export interface SwitchViewTypeAction extends Action {
  type: string;
  viewType: string;
}

export interface SearchByGeoAction extends Action {
  type: string;
  prefctureId: number;
  keyword: string;
  latitude: number;
  longitude: number;
  spots: ISpotsSearch[];
}

export interface SearchByKeywordAction extends Action {
  type: string;
  prefctureId: number;
  keyword: string;
  spots: ISpotsSearch[];
}

export interface SelectPrefectureAction extends Action {
  type: string;
  keyword: string;
  prefctureId: number;
  spots: ISpotsSearch[];
}

// selector -------
export const searchSpotSelector = (state: RedsealState): SearchSpotState => {
  return state.searchSpot;
};

// reducers -------
export const searchSpotModule = createSlice({
  name: "searchSpot",
  initialState: SearchSpotInitialState,
  reducers: {
    openSearchSpotModal: (
      state: SearchSpotState,
      action: PayloadAction<OpenSearchModalAction>
    ) => {
      state.isShowModal = true;
      state.mode = action.payload.mode;
    },
    closeSearchSpotModal: (
      state: SearchSpotState,
      action: PayloadAction<CloseSearchModalAction>
    ) => {
      state.isLoading = SearchSpotInitialState.isLoading;
      state.isShowModal = SearchSpotInitialState.isShowModal;
      state.mode = SearchSpotInitialState.mode;
      state.viewType = SearchSpotInitialState.viewType;
      state.conditions.prefectureId =
        SearchSpotInitialState.conditions.prefectureId;
      state.conditions.keyword = SearchSpotInitialState.conditions.keyword;
      state.conditions.position.latitude =
        SearchSpotInitialState.conditions.position.latitude;
      state.conditions.position.longitude =
        SearchSpotInitialState.conditions.position.longitude;
      state.spots = SearchSpotInitialState.spots;
    },
    switchViewType: (
      state: SearchSpotState,
      action: PayloadAction<SwitchViewTypeAction>
    ) => {
      state.viewType = action.payload.viewType;
    },
    searchByGeo: (
      state: SearchSpotState,
      action: PayloadAction<SearchByGeoAction>
    ) => {
      state.isLoading = false;
      state.conditions.prefectureId = action.payload.prefctureId;
      state.conditions.keyword = action.payload.keyword;
      state.conditions.position.latitude = action.payload.latitude;
      state.conditions.position.longitude = action.payload.longitude;
      state.spots = action.payload.spots;
    },
    searchByKeyword: (
      state: SearchSpotState,
      action: PayloadAction<SearchByKeywordAction>
    ) => {
      state.isLoading = false;
      state.conditions.prefectureId = isNullOrUndefined(
        action.payload.prefctureId
      )
        ? OVERALL_PREFECTURE_ID
        : action.payload.prefctureId;
      state.conditions.keyword = action.payload.keyword;
      state.conditions.position.latitude = null;
      state.conditions.position.longitude = null;
      state.spots = action.payload.spots;
    },
    selectPrefecture: (
      state: SearchSpotState,
      action: PayloadAction<SelectPrefectureAction>
    ) => {
      state.isLoading = false;
      state.conditions.prefectureId = action.payload.prefctureId;
      state.conditions.keyword = action.payload.keyword;
      state.spots = action.payload.spots;
    },
  },
});

// actions -------

/**
 * スポット検索モーダル 表示アクション.
 * @param dispatch
 * @param mode
 */
export const openSearchSpotModalAction = (
  dispatch: Dispatch<Action<any>>,
  mode: string
) => {
  BodyFixed.instance.on();
  dispatch(
    searchSpotModule.actions.openSearchSpotModal({
      type: "openSearchSpotModal",
      mode: mode,
    })
  );
};

/**
 * スポット検索モーダル 閉じるアクション.
 * @param dispatch
 */
export const closeSearchSpotModalAction = (dispatch: Dispatch<Action<any>>) => {
  BodyFixed.instance.off();
  dispatch(
    searchSpotModule.actions.closeSearchSpotModal({
      type: "closeSearchSpotModal",
    })
  );
};

export const switchViewTypeAction = (
  dispatch: Dispatch<Action<any>>,
  viewType: string
) => {
  dispatch(
    searchSpotModule.actions.switchViewType({
      type: "switchViewType",
      viewType: viewType,
    })
  );
};

export const searchByGeoAction = (
  dispatch: Dispatch<Action<any>>,
  onError: (erorrCode: number) => void
) => {
  showOverlayAction(dispatch, "現在地周辺の寺社を検索中…");
  usecaseSpotsSearchByCurrentLocation()
    .then(([position, spots]) => {
      dispatch(
        searchSpotModule.actions.searchByGeo({
          type: "searchByGeo",
          prefctureId: OVERALL_PREFECTURE_ID,
          keyword: "",
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          spots: spots,
        })
      );
    })
    .catch((e) => {
      let errorCode = RsGeolocation.API_UNAVAILABLE;
      if (e instanceof RedsealAPIError) {
        errorCode = -1;
      } else if (RsGeolocation.isPositionError(e)) {
        errorCode = e.code;
      }
      onError(errorCode);
    })
    .finally(() => {
      hideOverlayAction(dispatch);
    });
};

export const searchByKeywordAction = (
  dispatch: Dispatch<Action<any>>,
  keyword: string,
  prefectureId: number,
  onError: () => void
) => {
  _searchByKeyword(dispatch, keyword, prefectureId, onError);
};

export const selectPrefectureAction = (
  dispatch: Dispatch<Action<any>>,
  keyword: string,
  prefectureId: number,
  onError: () => void
) => {
  _searchByKeyword(dispatch, keyword, prefectureId, onError);
};

const _searchByKeyword = (
  dispatch: Dispatch<Action<any>>,
  keyword: string,
  prefectureId: number,
  onError: () => void
) => {
  if (isBlank(keyword)) {
    dispatch(
      searchSpotModule.actions.searchByKeyword({
        type: "searchByKeyword",
        prefctureId: prefectureId,
        keyword: "",
        spots: [],
      })
    );
    return;
  }
  showOverlayAction(dispatch, "検索中…");
  usecaseSpotsSearchByKeyword(
    keyword,
    prefectureId === OVERALL_PREFECTURE_ID ? null : prefectureId
  )
    .then((spots: ISpotsSearch[]) => {
      dispatch(
        searchSpotModule.actions.searchByKeyword({
          type: "searchByKeyword",
          prefctureId: prefectureId,
          keyword: keyword,
          spots: spots,
        })
      );
    })
    .catch((e) => {
      onError();
    })
    .finally(() => {
      hideOverlayAction(dispatch);
    });
};
