import {
  multi,
  destroy,
  show,
  push,
  recent,
  popular,
} from "../../data/datastores/posts";

export const multiplePosts = async (
  formData: FormData
): Promise<IPostsMulti> => {
  const response = await multi(formData);
  return response.data;
};

export const destroyPost = async (postId: number): Promise<IPost> => {
  const response = await destroy(postId);
  return response.data;
};

export const getPost = async (postId: number): Promise<IPostContainer> => {
  const response = await show(postId);
  return response.data;
};

export const pushPost = async (postId: number): Promise<IPostsPush> => {
  const response = await push(postId);
  return response.data;
};

export const usecasePostRecent = async (
  page: number
): Promise<IPostContainer> => {
  const response = await recent(page);
  return response.data;
};

export const usecasePostPopular = async (
  postType: string,
  page: number
): Promise<IPostContainer> => {
  const response = await popular(postType, page);
  return response.data;
};
