import * as React from 'react';

interface IProps {
  className: string,
}

const FontAwesome: React.FC<IProps> = (props): JSX.Element => (
  <span className={props.className}></span>
);
export default FontAwesome;
