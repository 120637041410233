import Color from "../presentation/components/const/Color";

export const enablePostTypes = [
  { value: "point", id: 2, name: "見どころ", color: Color.SUB_COLOR_YELLOW },
  { value: "goshuin", id: 0, name: "御朱印", color: Color.SUB_COLOR_RED },
  {
    value: "goshuincho",
    id: 4,
    name: "御朱印帳",
    color: Color.SUB_COLOR_GREEN,
  },
  { value: "goods", id: 1, name: "授与品", color: Color.SUB_COLOR_BLUE },
  { value: "drop", id: 3, name: "周辺情報", color: Color.SUB_COLOR_PURPLE },
];

export const postTypes = [
  { value: "point", id: 2, name: "見どころ", color: Color.SUB_COLOR_YELLOW },
  { value: "goshuin", id: 0, name: "御朱印", color: Color.SUB_COLOR_RED },
  {
    value: "goshuincho",
    id: 4,
    name: "御朱印帳",
    color: Color.SUB_COLOR_GREEN,
  },
  { value: "goods", id: 1, name: "授与品", color: Color.SUB_COLOR_BLUE },
  { value: "drop", id: 3, name: "周辺情報", color: Color.SUB_COLOR_PURPLE },
];

export const postTypeDescriptions = {
  point:
    "建築物や狛犬などの像、御神木や祭事の風景など寺社の見どころだと思う写真。迷った場合はこちらをお選びください。",
  goshuin: "御朱印、御陵印、御城印などの写真。",
  goshuincho: "寺社で購入できるオリジナルの御朱印帳の写真。",
  goods: "お守り、絵馬、おみくじなどの寺社で頂ける授与品の写真。",
  drop:
    "寺社に縁のある施設や風景（名物の食べ物や観光ランドマークなど）の写真。",
};

export const postTypeColor = (type: string): string => {
  for (let i = 0; i < postTypes.length; i++) {
    if (postTypes[i].value === type) {
      return postTypes[i].color;
    }
  }
  return postTypes[0].color;
};

export const postTypeId = (type: string): number => {
  for (let i = 0; i < postTypes.length; i++) {
    if (postTypes[i].value === type) {
      return postTypes[i].id;
    }
  }
  return postTypes[0].id;
};

export const isPostType = (type: string): boolean => {
  for (let i = 0; i < postTypes.length; i++) {
    if (postTypes[i].value === type) {
      return true;
    }
  }
  return false;
};
