import * as React from "react";
import styled from "styled-components";
import FontAwesome from "../../atoms/FontAwesome/FontAwesome";
import Color from "../../const/Color";
import { fontsize } from "../../const/Mixin";
import { useDispatch } from "react-redux";
import { showMessageBoxAction } from "../../../modules/messageBoxModule";
import { isSignedIn } from "src/js/redseal/lib/auth-util";
import { useState, useEffect } from "react";
import {
  userFollwoAction,
  userUnfollwoAction,
} from "../../../modules/accountModule";
import {
  gaTrackEvent,
  TRACKEVENT_CATEGORY,
} from "src/js/redseal/lib/analytics-util";

interface IProps {
  isFollowing: boolean;
  targetUserId: number;
}

const FollowButton: React.FC<IProps> = (props: IProps): JSX.Element => {
  const dispatch = useDispatch();

  // Local state
  const [isFollowing, setIsFollowing] = useState(props.isFollowing);

  const handleClickFollow = () => {
    if (isSignedIn()) {
      if (isFollowing) {
        if (!window.confirm("フォローを解除します。よろしいですか？")) {
          return;
        }
        userUnfollwoAction(props.targetUserId, handleOnSuccess, handleOnError);
      } else {
        if (!window.confirm("フォローします。よろしいですか？")) {
          return;
        }
        userFollwoAction(props.targetUserId, handleOnSuccess, handleOnError);
      }
    } else {
      gaTrackEvent(
        TRACKEVENT_CATEGORY.FOLLOW,
        "UnsignedFollow",
        props.targetUserId
      );
      showMessageBoxAction(
        dispatch,
        "フォローするにはログインする必要があります。"
      );
    }
  };

  const handleOnSuccess = (response: IFollow) => {
    const message =
      response.follow_status === "follow"
        ? "フォローしました。"
        : "フォローを解除しました。";
    const action = response.follow_status === "follow" ? "Follow" : "UnFollow";
    showMessageBoxAction(dispatch, message);
    gaTrackEvent(TRACKEVENT_CATEGORY.FOLLOW, action, props.targetUserId);
    setIsFollowing(response.follow_status === "follow");
  };

  const handleOnError = (message: string) => {
    showMessageBoxAction(dispatch, message);
  };

  useEffect(() => {}, [isFollowing]);

  return (
    <Wrapper
      onClick={() => handleClickFollow()}
      theme={{ isFollowing: isFollowing }}
    >
      <FontAwesome
        className={`fa ${isFollowing ? "fa-check-circle" : "fa-plus-circle"}`}
      />
      <Text>{isFollowing ? "フォロー中" : "フォローする"}</Text>
    </Wrapper>
  );
};
export default FollowButton;

const Wrapper = styled.div`
  padding: 4px 8px;
  border-radius: 16px;
  font-weight: bold;
  ${fontsize(11)}
  color: ${Color.FONT_COLOR_REVERSAL};
  background-color: ${({ theme }) =>
    theme.isFollowing ? Color.SUB_COLOR_GREEN : Color.SUB_COLOR_BLUE};

  width: 100%;
  height: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    transition: transform 0.2s ease-out;
    transform: scale(1.1);
  }

  span.fa {
    color: ${Color.FONT_COLOR_REVERSAL};
    margin-right: 8px;
  }
`;

const Text = styled.span``;
