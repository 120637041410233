import * as React from "react";
import styled from "styled-components";
import LazyLoadImage from "../LazyLoadImage";
import Color from "../../const/Color";

interface IProps {
  profileImagePath: string;
  userId: number;
  name: string | null;
  slug: string | null;
  width: number;
}

const CircleUser: React.FC<IProps> = (props: IProps): JSX.Element => {
  return (
    <Wrapper theme={{ width: props.width }}>
      <LazyLoadImage
        src={props.profileImagePath}
        alt={props.name}
        width={String(props.width)}
        height={String(props.width)}
        objectFit={"cover"}
        noImage={null}
      />
    </Wrapper>
  );
};
export default CircleUser;

const Wrapper = styled.div`
  overflow: hidden;
  width: ${({ theme }) => theme.width}px;
  min-width: ${({ theme }) => theme.width}px;
  height: ${({ theme }) => theme.width}px;
  min-height: ${({ theme }) => theme.width}px;
  border-radius: ${({ theme }) => theme.width / 2}px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
  background: ${Color.LOADING_PLACE_HOLDER_COLOR};
`;
