import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action, Dispatch } from "redux";
import { RedsealState } from "../store/createStore";
import { load, ROTATE_ANGLE, rotate, toBlob } from "../../lib/image-util";
import { multiplePosts, destroyPost } from "../../domain/usecases/posts";
import PostConst from "../components/const/PostConst";
import { isNullOrUndefined } from "../../lib/object-util";
import Zindex from "../components/const/Zindex";
import BodyFixed from "../../lib/BodyFixed";
import { csrfToken } from "../../lib/dom-helper";
import { postsModule } from "./postsModule";

// state -------
export interface PostFormState {
  isShowModal: boolean;
  isEnablePostButton: boolean;
  mode: string;
  spotId: number;
  spotName: string;
  visitedDate: string;
  showVisitedDate: number;
  posts: PostData[];
  zindex: number;
}

const PostFormInitialState: PostFormState = {
  isShowModal: false,
  isEnablePostButton: false,
  mode: PostConst.POST_MODE_CREATE,
  spotId: null,
  spotName: "",
  visitedDate: "",
  showVisitedDate: PostConst.SHOW_VISITED_DATE_OFF,
  posts: [],
  zindex: Zindex.POST_MODAL,
};

export interface PostData {
  postId: number;
  spotId: number;
  postType: string;
  description: string;
  visitedDate: string;
  showVisitedDate: number;
  imageSrc: string;
}

// payload -------

export interface OpenPostModalAction extends Action {
  type: string;
  mode: string;
  posts: PostData[];
}

export interface ClosePostModalAction extends Action {
  type: string;
}

export interface AddAction extends Action {
  type: string;
  posts: PostData[];
}

export interface RotateAction extends Action {
  type: string;
  posts: PostData[];
}

export interface RemoveAction extends Action {
  type: string;
  posts: PostData[];
}

export interface EditAction extends Action {
  type: string;
  posts: PostData[];
}

export interface EditSingleAction extends Action {
  type: string;
  posts: PostData[];
  visitedDate: string;
  showVisitedDate: number;
}

export interface SelectSpotAction extends Action {
  type: string;
  spotId: number;
  spotName: string;
}

export interface SelectVisitedDateAction extends Action {
  type: string;
  visitedDate: string;
}

export interface SelectShowVisitedDateAction extends Action {
  type: string;
  showVisitedDate: number;
}

export interface SubmitAction extends Action {
  type: string;
  posts: PostData[];
}

// selector -------
export const postFormSelector = (state: RedsealState): PostFormState => {
  return state.postForm;
};

// reducers -------
export const postFormModule = createSlice({
  name: "postForm",
  initialState: PostFormInitialState,
  reducers: {
    openPostModal: (
      state: PostFormState,
      action: PayloadAction<OpenPostModalAction>
    ) => {
      state.isShowModal = true;
      state.mode = action.payload.mode;
      state.posts = action.payload.posts;
    },
    closePostModal: (
      state: PostFormState,
      action: PayloadAction<ClosePostModalAction>
    ) => {
      state.isShowModal = PostFormInitialState.isShowModal;
      state.isEnablePostButton = PostFormInitialState.isEnablePostButton;
      state.mode = PostFormInitialState.mode;
      state.spotId = PostFormInitialState.spotId;
      state.spotName = PostFormInitialState.spotName;
      state.visitedDate = PostFormInitialState.visitedDate;
      state.showVisitedDate = PostFormInitialState.showVisitedDate;
      state.posts = PostFormInitialState.posts;
    },
    add: (state: PostFormState, action: PayloadAction<AddAction>) => {
      state.posts = action.payload.posts;
      state.isEnablePostButton = validateForm(state);
    },
    rotate: (state: PostFormState, action: PayloadAction<RotateAction>) => {
      state.posts = action.payload.posts;
    },
    remove: (state: PostFormState, action: PayloadAction<RemoveAction>) => {
      state.posts = action.payload.posts;
      state.isEnablePostButton = validateForm(state);
    },
    edit: (state: PostFormState, action: PayloadAction<EditAction>) => {
      state.posts = action.payload.posts;
      state.isEnablePostButton = validateForm(state);
    },
    editSingle: (
      state: PostFormState,
      action: PayloadAction<EditSingleAction>
    ) => {
      state.posts = action.payload.posts;
      state.visitedDate = action.payload.visitedDate;
      state.showVisitedDate = action.payload.showVisitedDate;
      state.isEnablePostButton = validateForm(state);
    },
    selectSpot: (
      state: PostFormState,
      action: PayloadAction<SelectSpotAction>
    ) => {
      state.spotId = action.payload.spotId;
      state.spotName = action.payload.spotName;
      state.isEnablePostButton = validateForm(state);
    },
    selectVisitedDate: (
      state: PostFormState,
      action: PayloadAction<SelectVisitedDateAction>
    ) => {
      state.visitedDate = action.payload.visitedDate;
      state.isEnablePostButton = validateForm(state);
    },
    selectShowVisitedDate: (
      state: PostFormState,
      action: PayloadAction<SelectShowVisitedDateAction>
    ) => {
      state.showVisitedDate = action.payload.showVisitedDate;
      state.isEnablePostButton = validateForm(state);
    },
    submit: (state: PostFormState, action: PayloadAction<SubmitAction>) => {
      state.posts = action.payload.posts;
    },
  },
});

// actions -------

/**
 * 投稿フォーム 表示アクション.
 * @param dispatch
 * @param mode
 */
export const openPostModalAction = (
  dispatch: Dispatch<Action<any>>,
  mode: string
) => {
  BodyFixed.instance.on();
  dispatch(
    postFormModule.actions.openPostModal({
      type: "openPostModal",
      mode: mode,
      posts: [],
    })
  );
};

/**
 * 投稿フォーム 閉じるアクション.
 * @param dispatch
 */
export const closePostModalAction = (dispatch: Dispatch<Action<any>>) => {
  BodyFixed.instance.off();
  dispatch(
    postFormModule.actions.closePostModal({
      type: "closePostModal",
    })
  );
};

/**
 * 寺社選択アクション.
 * @param dispatch
 * @param spotId
 * @param spotName
 */
export const selectSpotAction = (
  dispatch: Dispatch<Action<any>>,
  spotId: number,
  spotName: string
) => {
  dispatch(
    postFormModule.actions.selectSpot({
      type: "selectSpot",
      spotId: spotId,
      spotName: isNullOrUndefined(spotName) ? "" : spotName,
    })
  );
};

/**
 * 訪問日選択アクション.
 * @param dispatch
 * @param visitedDate
 */
export const selectVisitedDateAction = (
  dispatch: Dispatch<Action<any>>,
  visitedDate: string
) => {
  dispatch(
    postFormModule.actions.selectVisitedDate({
      type: "selectVisitedDate",
      visitedDate: visitedDate,
    })
  );
};

/**
 * 訪問日表示選択アクション.
 * @param dispatch
 * @param visitedDate
 */
export const selectShowVisitedDateAction = (
  dispatch: Dispatch<Action<any>>,
  showVisitedDate: number
) => {
  dispatch(
    postFormModule.actions.selectShowVisitedDate({
      type: "selectShowVisitedDate",
      showVisitedDate: showVisitedDate,
    })
  );
};

/**
 * 画像追加アクション.
 * @param dispatch
 * @param posts
 * @param files
 */
export const addAction = (
  dispatch: Dispatch<Action<any>>,
  currentPosts: PostData[],
  files: FileList
) => {
  const loadFiles = async (
    posts: PostData[],
    fileList: FileList
  ): Promise<PostData[]> => {
    if (fileList.length <= 0) {
      return posts;
    }
    const addPosts = [];
    let initIndex = posts.length;
    for (var i: number = 0; fileList.length > i; i++) {
      const post: PostData = {
        postId: initIndex + i,
        spotId: 0,
        description: "",
        postType: "point",
        visitedDate: "",
        showVisitedDate: PostConst.SHOW_VISITED_DATE_ON,
        imageSrc: await load(fileList[i]),
      };
      addPosts.push(post);
    }
    return [...posts, ...addPosts];
  };

  loadFiles(currentPosts, files).then((posts: PostData[]) => {
    dispatch(
      postFormModule.actions.add({
        type: "add",
        posts: posts,
      })
    );
  });
};

/**
 * 投稿削除アクション.
 * @param dispatch
 * @param post
 * @param currentPosts
 */
export const removeAction = (
  dispatch: Dispatch<Action<any>>,
  post: PostData,
  currentPosts: PostData[]
) => {
  const removePost = (removePost: PostData, posts: PostData[]): PostData[] => {
    if (
      window.confirm(
        "この写真を削除しますか？\n削除するとコメント等もあわせて削除されます。"
      )
    ) {
      const newPosts = posts.filter(
        (post) => post.postId !== removePost.postId
      );
      return [...newPosts];
    }
    return null;
  };

  const posts = removePost(post, currentPosts);
  if (!posts) {
    return;
  }
  dispatch(
    postFormModule.actions.remove({
      type: "remove",
      posts: posts,
    })
  );
};

/**
 * 画像回転アクション.
 * @param dispatch
 * @param angle
 * @param src
 * @param post
 * @param currentPosts
 */
export const rotateAction = (
  dispatch: Dispatch<Action<any>>,
  angle: string,
  src: string,
  post: PostData,
  currentPosts: PostData[]
) => {
  const rotateFile = async (
    angle: string,
    src: string,
    rotatePost: PostData,
    posts: PostData[]
  ): Promise<PostData[]> => {
    const rotateAngle: ROTATE_ANGLE = angle === "left" ? 270 : 90;
    const image = new Image();
    image.src = src;
    const dataUrl = await rotate(rotateAngle, image);
    const newPosts = posts.map((post) => {
      if (post.postId === rotatePost.postId) {
        return {
          postId: post.postId,
          spotId: post.spotId,
          postType: post.postType,
          description: post.description,
          visitedDate: post.visitedDate,
          showVisitedDate: post.showVisitedDate,
          imageSrc: dataUrl,
        };
      } else {
        return post;
      }
    });
    return [...newPosts];
  };

  rotateFile(angle, src, post, currentPosts).then((posts: PostData[]) => {
    dispatch(
      postFormModule.actions.rotate({
        type: "rotate",
        posts: posts,
      })
    );
  });
};

/**
 * 投稿単一編集アクション.
 * @param dispatch
 * @param editPost
 */
export const editSingleAction = (
  dispatch: Dispatch<Action<any>>,
  editPost: IPost
) => {
  const post = {
    postId: editPost.post_id,
    spotId: editPost.spot_id,
    postType: editPost.post_type_key,
    description: editPost.description,
    visitedDate: editPost.visited_date_in_time_zone,
    showVisitedDate: editPost.show_visited_date,
    imageSrc: editPost.post_picture_800,
  };
  dispatch(
    postFormModule.actions.editSingle({
      type: "editSingle",
      posts: [post],
      visitedDate: post.visitedDate,
      showVisitedDate: post.showVisitedDate,
    })
  );
};

/**
 * 編集アクション.
 * @param dispatch
 * @param editPost
 * @param currentPosts
 * @param fn
 */
export const editAction = (
  dispatch: Dispatch<Action<any>>,
  editPost: PostData,
  currentPosts: PostData[],
  editItem: string,
  editValue: string
) => {
  const newPosts = currentPosts.map((post) => {
    if (editPost.postId === post.postId) {
      switch (editItem) {
        case "description":
          return {
            postId: post.postId,
            spotId: post.spotId,
            postType: post.postType,
            description: editValue,
            visitedDate: post.visitedDate,
            showVisitedDate: post.showVisitedDate,
            imageSrc: post.imageSrc,
          };
        case "postType":
          return {
            postId: post.postId,
            spotId: post.spotId,
            postType: editValue,
            description: post.description,
            visitedDate: post.visitedDate,
            showVisitedDate: post.showVisitedDate,
            imageSrc: post.imageSrc,
          };
      }
    }
    return post;
  });

  dispatch(
    postFormModule.actions.edit({
      type: "edit",
      posts: newPosts,
    })
  );
};

/**
 * 投稿アクション
 * @param dispatch
 * @param state
 */
export const submitAction = (
  dispatch: Dispatch<Action<any>>,
  state: PostFormState,
  onSuccess: (response: IPostsMulti) => void,
  onError: () => void
) => {
  const formData = new FormData();
  formData.append("authenticity_token", csrfToken());
  formData.append("spot_id", `${state.spotId}`);
  for (let i = 0; i < state.posts.length; i++) {
    if (state.mode === PostConst.POST_MODE_EDIT) {
      formData.append("posts[][post_id]", `${state.posts[i].postId}`);
    }
    formData.append("posts[][post_type]", state.posts[i].postType);
    formData.append("posts[][description]", state.posts[i].description);
    formData.append("posts[][spot_id]", `${state.spotId}`);
    formData.append("posts[][visited_date]", state.visitedDate);
    formData.append("posts[][show_visited_date]", `${state.showVisitedDate}`);
    if (!/^https:\/\//.test(state.posts[i].imageSrc)) {
      const blob = toBlob(state.posts[i].imageSrc, "image/jpeg");
      formData.append("posts[][picture]", blob);
    }
  }

  multiplePosts(formData)
    .then((response) => {
      onSuccess(response);
      dispatch(
        postsModule.actions.setPostsAction({
          type: "setPostsAction",
          posts: response.posts,
        })
      );
    })
    .catch((e) => {
      console.log(e);
      onError();
    });
};

/**
 * 投稿削除のアクション.
 * @param postId
 * @param onSuccess
 * @param onError
 */
export const destroyAction = (
  dispatch: Dispatch<Action<any>>,
  postId: number,
  onSuccess: (response: IPost) => void,
  onError: () => void
) => {
  destroyPost(postId)
    .then((response) => {
      onSuccess(response);
      dispatch(
        postsModule.actions.removePostsAction({
          type: "removePostsAction",
          posts: [response],
        })
      );
    })
    .catch((e) => {
      console.log(e);
      onError();
    });
};

const validateForm = (state: PostFormState): boolean => {
  if (isNaN(state.spotId)) {
    return false;
  }
  if (state.posts.length === 0) {
    return false;
  }

  for (let i = 0; i < state.posts.length; i++) {
    const len = getPostDescriptionLength(state.posts[i].description);
    if (len < PostConst.DESCRIPTION_MIN || len > PostConst.DESCRIPTION_MAX) {
      return false;
    }
  }

  return true;
};

/**
 * 投稿文の長さを返す（空白文字は無視します）
 * @param description 
 */
export const getPostDescriptionLength = (description: string): number => {
  return description.replace(/\s+/g, "").length;
};
