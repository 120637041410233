import { RedsealState } from "../store/createStore";
import { PayloadAction, Action, createSlice, Dispatch } from "@reduxjs/toolkit";
import {
  usecaseNotificationCount,
  usecaseNotificationDelete,
  usecaseUserSignOut,
  usecaseCurrentUser,
  usecaseUserFollow,
  usecaseUserUnfollow,
  usecaseUserTimeline,
  usecaseUserBlockApply,
  usecaseUserBlockRelease,
  usecaseUserMyProfileEdit,
  usecaseUserMyProfileEditPost,
  usecaseUserMyProfileRemovePicture,
  usecaseUserMyProfileSlugCheck,
} from "../../domain/usecases/users";
import { isBlank, isEmpty } from "../../lib/string-util";
import Message from "../components/const/Message";
import { postContainerDispatcher } from "../../lib/module-util";
import { load, rotate, ROTATE_ANGLE, toBlob } from "../../lib/image-util";
import { csrfToken } from "../../lib/dom-helper";

// state -------
export interface AccountState {
  userId: number | null;
  name: string | null;
  slug: string | null;
  uid: string | null;
  profileImage100: string | null;
  profileImage200: string | null;
  notificationCount: number;
  myPosts: number[];
  myComments: number[];
  evaluatePosts: number[];
  evaluateComments: number[];
  isStaff: boolean;
}

const AccountInitialState: AccountState = {
  userId: null,
  name: null,
  slug: null,
  uid: null,
  profileImage100: null,
  profileImage200: null,
  notificationCount: 0,
  myPosts: [],
  myComments: [],
  evaluatePosts: [],
  evaluateComments: [],
  isStaff: false,
};

// payload -------
export interface NotificationCountAction extends Action {
  type: string;
  notificationCount: number;
}

export interface NotificationDeleteAction extends Action {
  type: string;
  notificationCount: number;
}

export interface FollowAction extends Action {
  type: string;
  userId: number;
}

export interface UnfollowAction extends Action {
  type: string;
  userId: number;
}

export interface CurrentUserAction extends Action {
  type: string;
  userId: number;
  name: string;
  slug: string;
  uid: string;
  profileImage100: string;
  profileImage200: string;
  isStaff: boolean | null;
}

export interface SetAccountAction extends Action {
  type: string;
  account: IAccount;
}

// selector -------
export const accountSelector = (state: RedsealState): AccountState => {
  return state.account;
};

// reducers -------
export const accountModule = createSlice({
  name: "user",
  initialState: AccountInitialState,
  reducers: {
    notificationCount: (
      state: AccountState,
      action: PayloadAction<NotificationCountAction>
    ) => {
      state.notificationCount = action.payload.notificationCount;
    },
    notificationDelete: (
      state: AccountState,
      action: PayloadAction<NotificationDeleteAction>
    ) => {
      state.notificationCount = action.payload.notificationCount;
    },
    currentUser: (
      state: AccountState,
      action: PayloadAction<CurrentUserAction>
    ) => {
      state.userId = action.payload.userId;
      state.name = action.payload.name;
      state.slug = action.payload.slug;
      state.uid = action.payload.uid;
      state.profileImage100 = action.payload.profileImage100;
      state.profileImage200 = action.payload.profileImage200;
      state.isStaff = action.payload.isStaff;
    },
    signOut: (state: AccountState) => {
      state = AccountInitialState;
    },
    setAccountAction: (
      state: AccountState,
      action: PayloadAction<SetAccountAction>
    ) => {
      if (action.payload.account.my_posts) {
        state.myPosts = mergeMyPostsState(
          action.payload.account.my_posts,
          state
        );
      }
      if (action.payload.account.my_comments) {
        state.myComments = mergeMyCommentsState(
          action.payload.account.my_comments,
          state
        );
      }
      if (action.payload.account.evaluate_posts) {
        state.evaluatePosts = mergeEvaluatePostsState(
          action.payload.account.evaluate_posts,
          state
        );
      }
      if (action.payload.account.evaluate_comments) {
        state.evaluateComments = mergeEvaluateCommentsState(
          action.payload.account.evaluate_comments,
          state
        );
      }
    },
  },
});

// actions -------
export const notificationCountAction = (dispatch: Dispatch<Action<any>>) => {
  usecaseNotificationCount().then(
    (notificationCount: IUserNotificationCountersCount) => {
      dispatch(
        accountModule.actions.notificationCount({
          type: "notificationCount",
          notificationCount: notificationCount.count,
        })
      );
    }
  );
};

export const notificationDeleteAction = (dispatch: Dispatch<Action<any>>) => {
  usecaseNotificationDelete().then(
    (notificationCount: IUserNotificationCountersDelete) => {
      dispatch(
        accountModule.actions.notificationDelete({
          type: "notificationDelete",
          notificationCount: notificationCount.count,
        })
      );
    }
  );
};

export const currentUserAction = (dispatch: Dispatch<Action<any>>) => {
  usecaseCurrentUser()
    .then((user: IUser) => {
      dispatch(
        accountModule.actions.currentUser({
          type: "currentUser",
          userId: user.user_id,
          name: user.name,
          slug: user.slug,
          uid: user.uid,
          profileImage100: user.profile_image_100,
          profileImage200: user.profile_image_200,
          isStaff: user.is_staff,
        })
      );
    })
    .catch((e) => {
      console.log(e);
    });
};

/**
 * ログアウト
 * @param dispatch
 * @param onSuccess
 * @param onError
 */
export const userSignOutAction = (
  dispatch: Dispatch<Action<any>>,
  onSuccess: (response: IUser) => void,
  onError: () => void
) => {
  usecaseUserSignOut()
    .then((user: IUser) => {
      dispatch(accountModule.actions.signOut());
      onSuccess(user);
    })
    .catch((e) => {
      console.log(e);
      onError();
    });
};

export const userFollwoAction = (
  userId: number,
  onSuccess: (response: IFollow) => void,
  onError: (message: string) => void
) => {
  const formData = new FormData();
  formData.append("follow[followed_user_id]", `${userId}`);

  usecaseUserFollow(formData)
    .then((user: IFollow) => {
      onSuccess(user);
    })
    .catch((e) => {
      console.log(e);
      onError(isEmpty(e.message) ? Message.UNEXPECTED_ERROR : e.message);
    });
};

export const userUnfollwoAction = (
  userId: number,
  onSuccess: (response: IFollow) => void,
  onError: (message: string) => void
) => {
  const formData = new FormData();
  formData.append("follow[followed_user_id]", `${userId}`);

  usecaseUserUnfollow(formData)
    .then((user: IFollow) => {
      onSuccess(user);
    })
    .catch((e) => {
      console.log(e);
      onError(isEmpty(e.message) ? Message.UNEXPECTED_ERROR : e.message);
    });
};

export const userBlockApplyAction = (
  targetUserId: number,
  onSuccess: (response: IUser) => void,
  onError: () => void
) => {
  usecaseUserBlockApply(targetUserId)
    .then((user: IUser) => {
      onSuccess(user);
    })
    .catch((e) => {
      console.log(e);
      onError();
    });
};

export const userBlockReleaseAction = (
  targetUserId: number,
  onSuccess: (response: IUser) => void,
  onError: () => void
) => {
  usecaseUserBlockRelease(targetUserId)
    .then((user: IUser) => {
      onSuccess(user);
    })
    .catch((e) => {
      console.log(e);
      onError();
    });
};

export const myTimelineAction = (
  dispatch: Dispatch<Action<any>>,
  page: number,
  onSuccess: (postIdList: number[], hasNext: boolean) => void,
  onError: () => void
) => {
  usecaseUserTimeline(page)
    .then((response) => {
      postContainerDispatcher(dispatch, response);
      onSuccess(
        response.posts.map((post: IPost) => {
          return post.post_id;
        }),
        response.paginator.has_next
      );
    })
    .catch((e) => {
      console.log(e);
      onError();
    });
};

/**
 * プロフィール情報取得アクション.
 * @param onSuccess
 * @param onError
 */
export const getProfileAction = (
  onSuccess: (user: IAccount) => void,
  onError: () => void
) => {
  usecaseUserMyProfileEdit()
    .then((user: IAccount) => {
      onSuccess(user);
    })
    .catch((e) => {
      console.log(e);
      onError();
    });
};

/**
 * 画像選択アクション.
 * @param files
 * @param onSuccess
 */
export const addImageAction = (
  files: FileList,
  onSuccess: (dataUrl: string) => void
) => {
  const loadFiles = async (fileList: FileList): Promise<string> => {
    if (fileList.length !== 1) {
      return null;
    }
    return await load(fileList[0]);
  };

  loadFiles(files).then((dataUrl: string) => {
    onSuccess(dataUrl);
  });
};

/**
 * 画像回転アクション.
 * @param dispatch
 * @param angle
 * @param src
 * @param post
 * @param currentPosts
 */
export const rotateImageAction = (
  angle: string,
  src: string,
  onSuccess: (dataUrl: string) => void
) => {
  const rotateFile = async (angle: string, src: string): Promise<string> => {
    const rotateAngle: ROTATE_ANGLE = angle === "left" ? 270 : 90;
    const image = new Image();
    image.src = src;
    return await rotate(rotateAngle, image);
  };

  rotateFile(angle, src).then((dataUrl: string) => {
    onSuccess(dataUrl);
  });
};

export const postProfileAction = (
  dispatch: Dispatch<Action<any>>,
  profile: IAccount,
  onSuccess: (user: IAccount) => void,
  onError: () => void
) => {
  const formData = new FormData();
  formData.append("authenticity_token", csrfToken());
  formData.append("profile[name]", profile.name);
  if (!isBlank(profile.slug)) {
    formData.append("profile[slug]", profile.slug);
  }
  if (!isBlank(profile.description)) {
    formData.append("profile[description]", profile.description);
  }
  formData.append("profile[gender]", `${profile.gender}`);
  if (!isBlank(profile.zip_code)) {
    formData.append("profile[zip_code]", profile.zip_code);
  }
  formData.append("profile[birthday][year]", profile.birthday.split("-")[0]);
  formData.append("profile[birthday][month]", profile.birthday.split("-")[1]);
  formData.append("profile[birthday][day]", profile.birthday.split("-")[2]);
  if (
    !/^https:\/\//.test(profile.profile_image_original) &&
    !/assets\/user\_icon/.test(profile.profile_image_original)
  ) {
    const blob = toBlob(profile.profile_image_original, "image/jpeg");
    formData.append("profile[picture]", blob);
  }

  usecaseUserMyProfileEditPost(formData)
    .then((user: IAccount) => {
      dispatch(
        accountModule.actions.currentUser({
          type: "currentUser",
          userId: user.user_id,
          name: user.name,
          slug: user.slug,
          uid: user.uid,
          profileImage100: user.profile_image_100,
          profileImage200: user.profile_image_200,
          isStaff: user.is_staff,
        })
      );

      onSuccess(user);
    })
    .catch((e) => {
      console.log(e);
      onError();
    });
};

export const removePictureAction = (
  dispatch: Dispatch<Action<any>>,
  profile: IAccount,
  onSuccess: (user: IAccount) => void,
  onError: () => void
) => {
  usecaseUserMyProfileRemovePicture()
    .then((user: IAccount) => {
      dispatch(
        accountModule.actions.currentUser({
          type: "currentUser",
          userId: user.user_id,
          name: user.name,
          slug: user.slug,
          uid: user.uid,
          profileImage100: user.profile_image_100,
          profileImage200: user.profile_image_200,
          isStaff: user.is_staff,
        })
      );
      onSuccess(user);
    })
    .catch((e) => {
      console.log(e);
      onError();
    });
};

export const slugCheckAction = (
  slug: string,
  onSuccess: (user: IAccount) => void,
  onError: (message: string) => void
) => {
  const formData = new FormData();
  formData.append("authenticity_token", csrfToken());
  formData.append("profile[slug]", slug);

  usecaseUserMyProfileSlugCheck(formData)
    .then((user: IAccount) => {
      onSuccess(user);
    })
    .catch((e) => {
      console.log(e);
      onError(isEmpty(e.message) ? Message.UNEXPECTED_ERROR : e.message);
    });
};

// utils -------

const mergeEvaluatePostsState = (
  targets: number[],
  state: AccountState
): number[] => {
  const newState: number[] = [...state.evaluatePosts];
  for (let i = 0; i < targets.length; i++) {
    newState.push(targets[i]);
  }
  return Array.from(new Set(newState));
};

const mergeEvaluateCommentsState = (
  targets: number[],
  state: AccountState
): number[] => {
  const newState: number[] = [...state.evaluateComments];
  for (let i = 0; i < targets.length; i++) {
    newState.push(targets[i]);
  }
  return Array.from(new Set(newState));
};

const mergeMyPostsState = (
  targets: number[],
  state: AccountState
): number[] => {
  const newState: number[] = [...state.myPosts];
  for (let i = 0; i < targets.length; i++) {
    newState.push(targets[i]);
  }
  return Array.from(new Set(newState));
};

const mergeMyCommentsState = (
  targets: number[],
  state: AccountState
): number[] => {
  const newState: number[] = [...state.myComments];
  for (let i = 0; i < targets.length; i++) {
    newState.push(targets[i]);
  }
  return Array.from(new Set(newState));
};
