import { tagShow } from "../../data/datastores/tags";

export const usecaseTagShow = async (
  tagName: string,
  spotId: number | null,
  breadcrumbId: number | null,
  page: number
): Promise<IPostContainer> => {
  const response = await tagShow(tagName, spotId, breadcrumbId, page);
  return response.data;
};
