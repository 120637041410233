import * as React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import Color from "../../const/Color";
import { normalBox } from "../../const/Mixin";

interface IProps {}

const AdsCard: React.FC<IProps> = (props): JSX.Element => {
  useEffect(() => {
    if (window.adsbygoogle && process.env.NODE_ENV !== "development") {
      window.adsbygoogle.push({});
    }
    // window.adsbygoogle.push({});
  }, []);

  return (
    <Wrapper>
      {process.env.NODE_ENV !== "development" ? (
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-1409005474529053"
          data-ad-slot="6293841265"
          data-ad-format="fluid"
          data-ad-layout-key="+4g+p4+y-v+hm"
        />
      ) : (
        <Dummy>
          <DummyHeader />
          <DummyPicture>Ads</DummyPicture>
          <DummyFooter />
        </Dummy>
      )}
    </Wrapper>
  );
};
export default AdsCard;

const Wrapper = styled.div`
  margin-bottom: 24px;
  width: 100%;
`;

const Dummy = styled.div`
  ${normalBox()};
  width: 100%;
`;

const DummyHeader = styled.div`
  height: 64px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const DummyFooter = styled.div`
  height: 64px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const DummyPicture = styled.div`
  background: ${Color.LOADING_PLACE_HOLDER_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${Math.round((638 * 5) / 8)}px;
  @media screen and (max-width: 768px) {
    height: ${Math.round((window.innerWidth * 5) / 8)}px;
  }
`;
