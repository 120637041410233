import * as React from "react";
import styled from "styled-components";
import FontAwesome from "../../atoms/FontAwesome/FontAwesome";
import Color from "../../const/Color";
import { squreBox, toolTipTop } from "../../const/Mixin";
import {
  gaTrackEvent,
  TRACKEVENT_CATEGORY,
} from "src/js/redseal/lib/analytics-util";
import UserControlModal from "../../organisms/UserControlModal";
import ModalPortal from "../../organisms/ModalPortal";
import BodyFixed from "src/js/redseal/lib/BodyFixed";

interface IProps {
  targetUserId: number;
  targetUserName: string;
  blockState: boolean;
}

/**
 * ユーザコントロールモーダル 表示ボタン
 */
const UserControlButton: React.FC<IProps> = (props): JSX.Element => {

  // Local state
  const [isShowModal, setIsShowModal] = React.useState(false);

  /**
   * モーダル表示イベント
   */
  const handleOpenModal = () => {
    handleModalState(true);
    gaTrackEvent(TRACKEVENT_CATEGORY.HEADER, "OpenUserControlModal");
  };

  /**
   * モーダルの状態管理
   * @param isShow 
   */
  const handleModalState = (isShow: boolean) => {
    if (isShow) {
      BodyFixed.instance.on();
    } else {
      BodyFixed.instance.off();
    }
    setIsShowModal(isShow);
  };

  return (
    <React.Fragment>
      <Wrapper data-tooltip="ブロック設定" onClick={() => handleOpenModal()}>
        <Inner>
          <FontAwesome className="fa fa-cog" />
        </Inner>
      </Wrapper>
      <ModalPortal targetId={"user_control_modal"}>
        <UserControlModal
          targetUserId={props.targetUserId}
          targetUserName={props.targetUserName}
          blockState={props.blockState}
          isShowModal={isShowModal}
          setIsShowModal={handleModalState}
        />
      </ModalPortal>
    </React.Fragment>
  );
};
export default UserControlButton;

const Wrapper = styled.div`
  margin-left: 8px;
  ${toolTipTop()}
`;

const Inner = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  ${squreBox(32)}
  .fa-cog {
    color: ${Color.SUB_COLOR_GOLD};
  }
`;
