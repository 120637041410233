import * as React from "react";
import { delimited } from "src/js/redseal/lib/string-util";
import styled from "styled-components";
import CircleUser from "../../atoms/CircleUser";
import FollowButton from "../../atoms/FollowButton";
import FontAwesome from "../../atoms/FontAwesome";
import Link from "../../atoms/Link";
import Color from "../../const/Color";
import { fontsize, normalBox } from "../../const/Mixin";

interface IProps {
  user: IUser;
  isFollowee: boolean;
}

const AuthorBox: React.FC<IProps> = (props): JSX.Element => {
  return (
    <Wrapper>
      <Column>
        <CircleUser
          userId={props.user.user_id}
          profileImagePath={props.user.profile_image_200}
          name={props.user.name}
          slug={props.user.slug}
          width={100}
        />
        <FollowAction>
        <FollowButton
          isFollowing={props.isFollowee}
          targetUserId={props.user.user_id}
        />
        </FollowAction>
      </Column>
      <Column>
        <Name>
          <Link url={props.user.users_profile_path}>{props.user.name}</Link>
          <FontAwesome className="fa fa-check-circle" />
        </Name>
        <Desc>
          {props.user.description}
        </Desc>
        <Stats>
          <Stat>
            <FontAwesome className="fa fa-camera" />
            投稿数
            <StatNum>{delimited(props.user.post_num)}</StatNum>
          </Stat>
          <Stat>
            フォロワー
            <StatNum>{delimited(props.user.followers_num)}</StatNum>
          </Stat>
        </Stats>
      </Column>
    </Wrapper>
  );
};
export default AuthorBox;

const Wrapper = styled.div`
  ${normalBox()}; 
  margin-bottom: 24px;
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const Column = styled.div`
  padding: 16px;
  flex-basis: 132px;
  &:last-child {
    padding: 16px 16px 16px 0;
    flex-grow: 1;
  }
`;

const FollowAction = styled.div`
  margin-top: 16px;
`;

const Name = styled.div`
  word-break: break-all;
  font-weight: bold;
  color: ${Color.SUB_COLOR_BLUE};
  a { color: ${Color.SUB_COLOR_BLUE}; text-decoration: none; }
  a:link { color: ${Color.SUB_COLOR_BLUE}; text-decoration: none; }
  a:hover { color: ${Color.SUB_COLOR_BLUE}; text-decoration: underline; }
  a:visited { color: ${Color.SUB_COLOR_BLUE}; text-decoration: none; }
  span {
    margin-left: 4px;
  }
`;

const Desc = styled.div`
  padding: 8px 0;
  word-break: break-all;
  ${fontsize(14)}
`;

const Stats = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const Stat = styled.div`
  ${fontsize(12)}
  &:first-child { margin-right: 16px; }
  span {
    margin-right: 4px;
  }
`;

const StatNum = styled.span`
  ${fontsize(14)}
  font-weight: bold;
  margin-left: 8px;
`;
