import RedsealError from "./RedsealError";

export default class RedsealAPIError<T> extends RedsealError {
  public response: IRedsealAPIResponse<T> = null;

  constructor(message: string) {
    super(message);
  }

  setResponse(response: IRedsealAPIResponse<T>): void {
    this.response = response;
  }
}