import { RedsealState } from "../store/createStore";
import { createSlice, PayloadAction, Action, Dispatch } from "@reduxjs/toolkit";
import {
  usecaseUserCalendarVisit,
  usecaseUserProfileShow,
  usecaseUserSpotShow,
} from "../../domain/usecases/users";
import { postContainerDispatcher } from "../../lib/module-util";

// state -------
export interface UsersState {
  users: IUser[];
}

const UsersStateInitialState: UsersState = {
  users: [],
};

// payload -------
export interface SetUsersAction extends Action {
  type: string;
  users: IUser[];
}

// selector -------
export const usersSelector = (state: RedsealState): UsersState => {
  return state.users;
};

// reducers -------
export const usersModule = createSlice({
  name: "users",
  initialState: UsersStateInitialState,
  reducers: {
    setUsersAction: (
      state: UsersState,
      action: PayloadAction<SetUsersAction>
    ) => {
      state.users = mergeState(action.payload.users, state);
    },
  },
});

// actions -------

export const setUsersAction = (
  dispatch: Dispatch<Action<any>>,
  users: IUser[]
) => {
  dispatch(
    usersModule.actions.setUsersAction({
      type: "setUsersAction",
      users: users,
    })
  );
};

export const usersProfilesShowAction = (
  dispatch: Dispatch<Action<any>>,
  uid: string,
  postType: string,
  postPref: string,
  page: number,
  onSuccess: (postIdList: number[], hasNext: boolean) => void,
  onError: () => void
) => {
  usecaseUserProfileShow(uid, postType, postPref, page)
    .then((response) => {
      postContainerDispatcher(dispatch, response);
      onSuccess(
        response.posts.map((post: IPost) => {
          return post.post_id;
        }),
        response.paginator.has_next
      );
    })
    .catch((e) => {
      console.log(e);
      onError();
    });
};

export const usersSpotsShowAction = (
  dispatch: Dispatch<Action<any>>,
  uid: string,
  spotId: number,
  page: number,
  onSuccess: (postIdList: number[], hasNext: boolean) => void,
  onError: () => void
) => {
  usecaseUserSpotShow(uid, spotId, page)
    .then((response) => {
      postContainerDispatcher(dispatch, response);
      onSuccess(
        response.posts.map((post: IPost) => {
          return post.post_id;
        }),
        response.paginator.has_next
      );
    })
    .catch((e) => {
      console.log(e);
      onError();
    });
};

export const usersCalendarsVisitAction = (
  dispatch: Dispatch<Action<any>>,
  uid: string,
  year: string,
  month: string,
  day: string,
  page: number,
  onSuccess: (postIdList: number[], hasNext: boolean) => void,
  onError: () => void
) => {
  usecaseUserCalendarVisit(uid, year, month, day, page)
    .then((response) => {
      postContainerDispatcher(dispatch, response);
      onSuccess(
        response.posts.map((post: IPost) => {
          return post.post_id;
        }),
        response.paginator.has_next
      );
    })
    .catch((e) => {
      console.log(e);
      onError();
    });
};

// utils -------

const mergeState = (targets: IUser[], state: UsersState): IUser[] => {
  const newState: IUser[] = [...state.users];
  for (let i = 0; i < targets.length; i++) {
    if (!updateState(targets[i], newState)) {
      newState.push(targets[i]);
    }
  }
  return newState;
};

const updateState = (target: IUser, source: IUser[]): boolean => {
  for (let i = 0; i < source.length; i++) {
    if (
      target.user_id === source[i].user_id &&
      target.updated_at > source[i].updated_at
    ) {
      source[i] = target;
      return true;
    }
  }
  return false;
};

export const findUser = (id: number, state: UsersState): IUser | null => {
  for (let i = 0; i < state.users.length; i++) {
    if (state.users[i].user_id === id) {
      return state.users[i];
    }
  }
  return null;
};
