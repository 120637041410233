import { items } from "../../data/datastores/affiliates";
import { isNullOrUndefined } from "../../lib/object-util";

/**
 * 指定のIDのアフィリエイト情報を取得する
 * @param affiliateId
 */
export const getItems = async (
  affiliateId: string
): Promise<IAffiliatesItems[]> => {
  if (isNullOrUndefined(affiliateId)) {
    affiliateId = "2,3";
  }
  const response = await items(affiliateId);
  return response.data;
};
