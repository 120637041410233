import * as React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { spotsShowAction } from "../../../modules/spotsModule";
import AuthorBox from "../../molecules/AuthorBox";
import { findUser, usersSelector, UsersState } from "../../../modules/usersModule";
import Loading from "../../atoms/Loading";
import Color from "../../const/Color";
import FontAwesome from "../../atoms/FontAwesome";

interface IProps {
  spotId: number;
  userId: number;
}

const SpotsShow: React.FC<IProps> = (props: IProps): JSX.Element => {

  // Local state
  const [isLoading, setIsLoading] = useState(true);
  const [certifiedUser, setCertifiedUser] = useState(null);

  useEffect(() => {
    didMount();
  }, []);

  const didMount = () => {
    const certifiedUser = findUser(props.userId, usersState);
    if (certifiedUser === null) {
      spotsShowAction(props.spotId, handleOnSuccess, handleOnError);
    } else {
      setCertifiedUser(certifiedUser);
      setIsLoading(false);        
    }
  };

  const handleOnSuccess = (user: ICertifiedUser) => {
    setCertifiedUser(user);
    setIsLoading(false);
  };

  const handleOnError = () => {
    setCertifiedUser(null);
    setIsLoading(false);
  };

  const usersState: UsersState = useSelector(usersSelector);
  if (isLoading) {
    return <Loading />;
  } else {
    if (certifiedUser === null) {
      return null;
    }
    return (
      <React.Fragment>
        <Header>
          <FontAwesome className="fa fa-check-circle" />
          公式ユーザ
        </Header>
        <AuthorBox user={certifiedUser} isFollowee={certifiedUser.is_followee} />
      </React.Fragment>
    );  
  }
};
export default SpotsShow;

const Header = styled.h2`
  border-bottom: 2px solid ${Color.SUB_COLOR_PURPLE};
`;

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 0 16px 0;
  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;
