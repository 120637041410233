import { put, post, del, get } from "../network/api-client";

/**
 * 指定の投稿を取得するAPI
 * @param postId
 */
export const show = async (
  postId: number
): Promise<IRedsealAPIResponse<IPostContainer>> => {
  try {
    const API_URI = "/api/posts/<postId>";
    return await get<IPostContainer>(
      API_URI.replace("<postId>", postId.toString(10))
    );
  } catch (err) {
    throw err;
  }
};

/**
 * 新着の投稿を取得するAPI
 * @param page
 */
export const recent = async (
  page: number
): Promise<IRedsealAPIResponse<IPostContainer>> => {
  try {
    const API_URI = "/api/posts/recent?page=<page>";
    return await get<IPostContainer>(
      API_URI.replace("<page>", page.toString(10))
    );
  } catch (err) {
    throw err;
  }
};

/**
 * 人気の投稿を取得するAPI
 * @param postType
 * @param page
 */
export const popular = async (
  postType: string,
  page: number
): Promise<IRedsealAPIResponse<IPostContainer>> => {
  try {
    const API_URI = "/api/posts/popular?post_type=<postType>&page=<page>";
    return await get<IPostContainer>(
      API_URI.replace("<postType>", postType).replace(
        "<page>",
        page.toString(10)
      )
    );
  } catch (err) {
    throw err;
  }
};

/**
 * 指定の投稿のPVをインクリメントするAPI
 * @param postId
 */
export const push = async (
  postId: number
): Promise<IRedsealAPIResponse<IPostsPush>> => {
  try {
    const API_URI = "/posts/push";
    const data = {
      post: {
        post_id: postId,
      },
    };
    return await put<IPostsPush>(API_URI, data);
  } catch (err) {
    throw err;
  }
};

/**
 * 複数枚投稿
 * @param form
 */
export const multi = async (
  form: FormData
): Promise<IRedsealAPIResponse<IPostsMulti>> => {
  try {
    const API_URI = "/api/posts/multiple";
    return await post<IPostsMulti>(API_URI, form);
  } catch (err) {
    throw err;
  }
};

/**
 * 投稿削除
 * @param postId
 */
export const destroy = async (
  postId: number
): Promise<IRedsealAPIResponse<IPost>> => {
  try {
    const API_URI = "/api/posts/destroy";
    const data = {
      post: {
        post_id: postId,
      },
    };
    return await del<IPost>(API_URI, data);
  } catch (err) {
    throw err;
  }
};
