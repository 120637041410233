import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "src/js/redseal/presentation/store/createStore";
import AmazonAffiliateList from "src/js/redseal/presentation/components/organisms/AmazonAffiliateList";
import NearSpotList from "src/js/redseal/presentation/components/organisms/NearSpotList";
import PostForm from "src/js/redseal/presentation/components/organisms/PostForm";
import SearchSpotButton from "src/js/redseal/presentation/components/atoms/SearchSpotButton";
import SearchSpotModal from "src/js/redseal/presentation/components/organisms/SearchSpotModal";
import MessageBox from "src/js/redseal/presentation/components/molecules/MessageBox";
import Overlay from "src/js/redseal/presentation/components/molecules/Overlay";
import BottomNavigation from "src/js/redseal/presentation/components/molecules/BottomNavigation";
import NotificationButton from "src/js/redseal/presentation/components/atoms/NotificationButton";
import SignedInUser from "src/js/redseal/presentation/components/molecules/SignedInUser";
import FollowButton from "src/js/redseal/presentation/components/atoms/FollowButton";
import PostsShow from "src/js/redseal/presentation/components/pages/PostsShow";
import UsersMy from "src/js/redseal/presentation/components/pages/UsersMy";
import UsersProfilesShow from "src/js/redseal/presentation/components/pages/UsersProfilesShow";
import PostsIndex from "src/js/redseal/presentation/components/pages/PostsIndex";
import SpotsShowType from "src/js/redseal/presentation/components/pages/SpotsShowType";
import UsersSpotsShow from "src/js/redseal/presentation/components/pages/UsersSpotsShow";
import { isNullOrUndefined } from "src/js/redseal/lib/object-util";
import { isBlank } from "src/js/redseal/lib/string-util";
import UsersCalendarsVisit from "src/js/redseal/presentation/components/pages/UsersCalendarsVisit";
import TagsShow from "src/js/redseal/presentation/components/pages/TagsShow";
import SpotsShow from "src/js/redseal/presentation/components/pages/SpotsShow";
import UserControlButton from "src/js/redseal/presentation/components/atoms/UserControlButton";
import UsersMyProfilesEdit from "src/js/redseal/presentation/components/pages/UsersMyProfilesEdit";

// 検索ボタン
if (document.getElementById("search_spot_button")) {
  ReactDOM.render(
    <Provider store={store}>
      <SearchSpotButton />
    </Provider>,
    document.getElementById("search_spot_button")
  );
}

// 検索モーダル
if (document.getElementById("search_spot_modal")) {
  ReactDOM.render(
    <Provider store={store}>
      <SearchSpotModal />
    </Provider>,
    document.getElementById("search_spot_modal")
  );
}

// 通知ボタン
if (document.getElementById("notification_button")) {
  ReactDOM.render(
    <Provider store={store}>
      <NotificationButton />
    </Provider>,
    document.getElementById("notification_button")
  );
}

// ログインユーザ
if (document.getElementById("signed_in_user")) {
  ReactDOM.render(
    <Provider store={store}>
      <SignedInUser />
    </Provider>,
    document.getElementById("signed_in_user")
  );
}

// ボトムナビゲーション
// - 投稿ボタン
// - 行きたいボタン
if (document.getElementById("bottom_navigation")) {
  const element: HTMLElement = document.getElementById("bottom_navigation");
  const showPostButton =
    element.getAttribute("data-show_post_button") === "true" ? true : false;
  const showWishButton =
    element.getAttribute("data-show_wish_button") === "true" ? true : false;
  const spotId = isNullOrUndefined(element.getAttribute("data-spot_id"))
    ? null
    : parseInt(element.getAttribute("data-spot_id"));
  const spotName = element.getAttribute("data-spot_name");
  const wishChecked =
    element.getAttribute("data-wish_checked") === "true" ? true : false;

  ReactDOM.render(
    <Provider store={store}>
      <BottomNavigation
        showPostButton={showPostButton}
        showWishButton={showWishButton}
        spotId={spotId}
        spotName={spotName}
        wishChecked={wishChecked}
      />
    </Provider>,
    document.getElementById("bottom_navigation")
  );
}

// 複数枚投稿モーダル
if (document.getElementById("posts_new")) {
  ReactDOM.render(
    <Provider store={store}>
      <PostForm />
    </Provider>,
    document.getElementById("posts_new")
  );
}

// メッセージボックス
if (document.getElementById("message_box")) {
  ReactDOM.render(
    <Provider store={store}>
      <MessageBox />
    </Provider>,
    document.getElementById("message_box")
  );
}

// オーバーレイ
if (document.getElementById("overlay")) {
  ReactDOM.render(
    <Provider store={store}>
      <Overlay />
    </Provider>,
    document.getElementById("overlay")
  );
}

if (document.getElementById("affiliates")) {
  const element: HTMLElement = document.getElementById("affiliates");
  const direction: string = element.getAttribute("data-direction");
  const affiliateId: string = element.getAttribute("data-id");
  ReactDOM.render(
    <Provider store={store}>
      <AmazonAffiliateList affiliateId={affiliateId} direction={direction} />
    </Provider>,
    document.getElementById("affiliates")
  );
}

if (document.getElementById("near_spots")) {
  const element: HTMLElement = document.getElementById("near_spots");
  const spotId: string = element.getAttribute("data-spot_id");
  const spotOfficialName: string = element.getAttribute(
    "data-spot_official_name"
  );
  ReactDOM.render(
    <Provider store={store}>
      <NearSpotList spotId={parseInt(spotId)} name={spotOfficialName} />
    </Provider>,
    document.getElementById("near_spots")
  );
}

// フォローボタン
const followButtons = document.getElementsByClassName("follow_button");
for (let i = 0; i < followButtons.length; ++i) {
  const followButton = followButtons[i];
  const isFollowing: string = followButton.getAttribute("data-is_following");
  const userId: string = followButton.getAttribute("data-target_user_id");
  ReactDOM.render(
    <Provider store={store}>
      <FollowButton
        isFollowing={isFollowing === "true" ? true : false}
        targetUserId={parseInt(userId)}
      />
    </Provider>,
    followButton
  );
}

// ユーザコントロールボタン
if (document.getElementById("user_control_button")) {
  const element: HTMLElement = document.getElementById("user_control_button");
  const targetUserId = element.getAttribute("data-target_user_id");
  const targetUserName = element.getAttribute("data-target_user_name");
  const blockState = element.getAttribute("data-block_state");
  ReactDOM.render(
    <Provider store={store}>
      <UserControlButton
        targetUserId={parseInt(targetUserId)}
        targetUserName={targetUserName}
        blockState={blockState === "true" ? true : false}
      />
    </Provider>,
    element
  );
}

// Page posts_show
if (document.getElementById("posts_show")) {
  const postId = document
    .getElementById("posts_show")
    .getAttribute("data-post_id");

  ReactDOM.render(
    <Provider store={store}>
      <PostsShow postId={parseInt(postId)} />
    </Provider>,
    document.getElementById("posts_show")
  );
}

// Page posts_recent, popular, popular_goshuin
if (document.getElementById("posts_index")) {
  const element = document.getElementById("posts_index");
  const rankingType = element.getAttribute("data-posts_ranking_type");
  ReactDOM.render(
    <Provider store={store}>
      <PostsIndex rankingType={rankingType} />
    </Provider>,
    element
  );
}

// Page users_my
if (document.getElementById("users_my")) {
  ReactDOM.render(
    <Provider store={store}>
      <UsersMy />
    </Provider>,
    document.getElementById("users_my")
  );
}

// Page users_my_profiles_edit
if (document.getElementById("users_my_profiles_edit")) {
  const element = document.getElementById("users_my_profiles_edit");
  const welcome = element.getAttribute("data-show_welcome");
  ReactDOM.render(
    <Provider store={store}>
      <UsersMyProfilesEdit showWelcome={welcome === "true" ? true : false} />
    </Provider>,
    element
  );
}

// Page users_profile
if (document.getElementById("users_profiles_show")) {
  const element = document.getElementById("users_profiles_show");
  const uid = element.getAttribute("data-uid");
  const postType = element.getAttribute("data-post_type");
  const postPref = element.getAttribute("data-post_pref");
  const viewType = element.getAttribute("data-view_type");
  const isExistPosts = element.getAttribute("data-exist_posts");

  ReactDOM.render(
    <Provider store={store}>
      <UsersProfilesShow
        uid={uid}
        postType={postType}
        postPref={postPref}
        viewType={viewType}
        isExistPosts={isExistPosts === "true" ? true : false}
      />
    </Provider>,
    element
  );
}

// Page users_spots
if (document.getElementById("users_spots_show")) {
  const element = document.getElementById("users_spots_show");
  const uid = element.getAttribute("data-uid");
  const spotId = element.getAttribute("data-spot_id");
  const viewType = element.getAttribute("data-view_type");
  const isExistPosts = element.getAttribute("data-exist_posts");

  ReactDOM.render(
    <Provider store={store}>
      <UsersSpotsShow
        uid={uid}
        spotId={parseInt(spotId)}
        viewType={viewType}
        isExistPosts={isExistPosts === "true" ? true : false}
      />
    </Provider>,
    element
  );
}

// Page users_calendars_visit
if (document.getElementById("users_calendars_visit")) {
  const element = document.getElementById("users_calendars_visit");
  const uid = element.getAttribute("data-uid");
  const year = element.getAttribute("data-year");
  const month = element.getAttribute("data-month");
  const day = element.getAttribute("data-day");
  const isExistPosts = element.getAttribute("data-exist_posts");

  ReactDOM.render(
    <Provider store={store}>
      <UsersCalendarsVisit
        uid={uid}
        year={year}
        month={month}
        day={day}
        isExistPosts={isExistPosts === "true" ? true : false}
      />
    </Provider>,
    element
  );
}

// Page spots_show
if (document.getElementById("spot_certified_user")) {
  const element = document.getElementById("spot_certified_user");
  const spotId = element.getAttribute("data-spot_id");
  const userId = element.getAttribute("data-user_id");

  ReactDOM.render(
    <Provider store={store}>
      <SpotsShow
        spotId={parseInt(spotId)}
        userId={parseInt(userId)}
      />
    </Provider>,
    element
  );
}

// Page spots_show_type
if (document.getElementById("spots_show_type")) {
  const element = document.getElementById("spots_show_type");
  const spotId = element.getAttribute("data-spot_id");
  const postType = element.getAttribute("data-post_type");
  const viewType = element.getAttribute("data-view_type");
  const isExistPosts = element.getAttribute("data-exist_posts");
  const postId = element.getAttribute("data-post_id");

  ReactDOM.render(
    <Provider store={store}>
      <SpotsShowType
        spotId={parseInt(spotId)}
        postType={postType}
        viewType={viewType}
        isExistPosts={isExistPosts === "true" ? true : false}
        postId={isBlank(postId) ? null : parseInt(postId)}
      />
    </Provider>,
    element
  );
}

// Page tags_show
if (document.getElementById("tags_show")) {
  const element = document.getElementById("tags_show");
  const tagName = element.getAttribute("data-tag_name");
  const spotId = element.getAttribute("data-spot_id");
  const breadcrumbId = element.getAttribute("data-breadcrumb_id");
  const viewType = element.getAttribute("data-view_type");
  const isExistPosts = element.getAttribute("data-exist_posts");

  ReactDOM.render(
    <Provider store={store}>
      <TagsShow
        tagName={tagName}
        spotId={isBlank(spotId) ? null : parseInt(spotId)}
        breadcrumbId={isBlank(breadcrumbId) ? null : parseInt(breadcrumbId)}
        viewType={viewType}
        isExistPosts={isExistPosts === "true" ? true : false}
      />
    </Provider>,
    element
  );
}
