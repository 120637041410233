import * as React from "react";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { showMessageBoxAction } from "../../../modules/messageBoxModule";
import PostPlaceholder from "../../molecules/PostContainer/PostPlaceholder";
import { CSSTransition } from "react-transition-group";
import PostContainerList from "../../molecules/PostContainerList";
import {
  TRACKEVENT_CATEGORY,
  gaTrackEvent,
} from "src/js/redseal/lib/analytics-util";
import {
  postPopularAction,
  postRecentAction,
} from "../../../modules/postsModule";
import ViewMoreButton from "../../atoms/ViewMoreButton";
import PostContainerTransitionWrapper from "../../atoms/PostContainerTransitionWrapper";
import { isBot } from "src/js/redseal/lib/ua-util";

interface IProps {
  rankingType: string;
}

const PostsIndex: React.FC<IProps> = (props): JSX.Element => {
  const dispatch = useDispatch();

  // Local state
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [postIdList, setPostIdList] = useState([]);
  const [page, setPage] = useState(1);
  const [isViewMoreLoading, setIsViewMoreLoading] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);

  useEffect(() => {
    didMount();
  }, []);

  const didMount = () => {
    handlePostAction(props.rankingType, handleOnSuccess, handleOnError);
  };

  const handlePostAction = (
    rankingType: string,
    onSuccess: (postIdList: number[], hasNext: boolean) => void,
    onError: () => void
  ) => {
    switch (rankingType) {
      case "popular":
        postPopularAction(dispatch, "all", page, onSuccess, onError);
        break;
      case "goshuin":
        postPopularAction(dispatch, "goshuin", page, onSuccess, onError);
        break;
      default:
        postRecentAction(dispatch, page, onSuccess, onError);
    }
  };

  const handleTrackeventCategory = (rankingType: string): string => {
    switch (rankingType) {
      case "popular":
        return TRACKEVENT_CATEGORY.POSTS_POPULAR;
      case "goshuin":
        return TRACKEVENT_CATEGORY.POSTS_POPULAR_GOSHUIN;
      case "recent":
        return TRACKEVENT_CATEGORY.POSTS_RECENT;
      default:
        return "Unkown";
    }
  };

  const handleOnSuccess = (addedPostIdList: number[], hasNext: boolean) => {
    setIsLoading(false);
    setPostIdList(postIdList.concat(addedPostIdList));
    setPage(page + 1);
    setIsLastPage(!hasNext);
  };

  const handleOnError = () => {
    showMessageBoxAction(dispatch, "予期せぬエラーが発生しました。");
  };

  const handleViewMore = () => {
    if (isViewMoreLoading) {
      return;
    }
    setIsViewMoreLoading(true);
    handlePostAction(props.rankingType, handleOnViewMoreSuccess, handleOnError);
    gaTrackEvent(handleTrackeventCategory(props.rankingType), "ViewMore", page);
  };

  const handleOnViewMoreSuccess = (
    addedPostIdList: number[],
    hasNext: boolean
  ) => {
    setIsViewMoreLoading(false);
    setPostIdList(postIdList.concat(addedPostIdList));
    setPage(page + 1);
    setIsLastPage(!hasNext);
  };

  return (
    <React.Fragment>
      <CSSTransition
        in={isLoading}
        timeout={400}
        classNames="posts-show"
        onExited={() => {
          setIsLoaded(true);
        }}
        unmountOnExit
      >
        <PostContainerTransitionWrapper transitionClassName={"posts-show"} isAdjustTopMargin={false}>
          <PostPlaceholder />
        </PostContainerTransitionWrapper>
      </CSSTransition>
      <CSSTransition
        in={isLoaded}
        timeout={400}
        classNames="posts-show"
        unmountOnExit
      >
        <PostContainerTransitionWrapper transitionClassName={"posts-show"} isAdjustTopMargin={false}>
          <PostContainerList postIdList={postIdList} usePush={!isBot()} />
          {!isLastPage && (
            <ViewMoreButton
              isViewMoreLoading={isViewMoreLoading}
              fn={handleViewMore}
            />
          )}
        </PostContainerTransitionWrapper>
      </CSSTransition>
    </React.Fragment>
  );
};
export default PostsIndex;
