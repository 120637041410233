import RedsealAPIError from "src/js/redseal/errors/RedsealAPIError";
import { csrfToken } from "../../lib/dom-helper";
import { isNullOrUndefined } from "../../lib/object-util";
import { isBlank } from "../../lib/string-util";

const enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export const get = async <T>(url: string): Promise<IRedsealAPIResponse<T>> => {
  return request(url, HttpMethod.GET);
};

export const post = async <T>(
  url: string,
  form: FormData
): Promise<IRedsealAPIResponse<T>> => {
  return requestPost(url, HttpMethod.POST, form);
};

export const put = async <T>(
  url: string,
  dataHash?: { [key: string]: any }
): Promise<IRedsealAPIResponse<T>> => {
  return request(url, HttpMethod.PUT, dataHash);
};

export const del = async <T>(
  url: string,
  dataHash?: { [key: string]: any }
): Promise<IRedsealAPIResponse<T>> => {
  return request(url, HttpMethod.DELETE, dataHash);
};

const request = async <T>(
  url: string,
  method: HttpMethod,
  dataHash?: { [key: string]: any }
): Promise<IRedsealAPIResponse<T>> => {
  try {
    const defaultCredentials: RequestCredentials = "same-origin";
    const requestInit = {
      method: method.valueOf(),
      credentials: defaultCredentials,
      headers: defaultHeaders(method),
    };
    if (!isNullOrUndefined(dataHash)) {
      requestInit["body"] = JSON.stringify(dataHash);
    }
    const response = await fetch(createAbsoluteURL(url), requestInit);
    if (response.ok) {
      const res: IRedsealAPIResponse<T> = await response.json();
      if (isSuccess<T>(res)) {
        return res;
      }
      return toError<T>(res);
    }
    return toError<T>();
  } catch (err) {
    throw err;
  }
};

const requestPost = async <T>(
  url: string,
  method: HttpMethod,
  form: FormData
): Promise<IRedsealAPIResponse<T>> => {
  try {
    const defaultCredentials: RequestCredentials = "same-origin";
    const requestInit = {
      method: method.valueOf(),
      credentials: defaultCredentials,
      headers: defaultHeaders(method),
    };
    if (!isNullOrUndefined(form)) {
      requestInit["body"] = form;
    }
    const response = await fetch(createAbsoluteURL(url), requestInit);
    if (response.ok) {
      const res: IRedsealAPIResponse<T> = await response.json();
      if (isSuccess<T>(res)) {
        return res;
      }
      return toError<T>(res);
    }
    return toError<T>();
  } catch (err) {
    throw err;
  }
};

const defaultHeaders = (method: string): { [key: string]: string } => {
  const headers: { [key: string]: string } = {
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
  };
  if (method != "GET") {
    headers["X-CSRF-Token"] = csrfToken();
  }
  if (method != "POST") {
    headers["Content-Type"] = "application/json; charset=utf-8";
  }
  return headers;
};

const createAbsoluteURL = (url: string): string => {
  return `${getBaseURL()}${url}`;
};

const getBaseURL = (): string => {
  var baseURL = `${location.protocol}//${location.hostname}`;
  if (parseInt(location.port) > 0) {
    baseURL = `${baseURL}:${location.port}`;
  }
  return baseURL;
};

const isSuccess = <T>(res: IRedsealAPIResponse<T>): boolean => {
  return res.type === "success" && res.status === 200;
};

const toError = <T>(
  res?: IRedsealAPIResponse<T>
): Promise<IRedsealAPIResponse<T>> => {
  const messages: string[] = [];
  if (isNullOrUndefined(res)) {
    return Promise.reject(new RedsealAPIError<T>(messages.join("::")));
  }
  if (res.hasOwnProperty("title") && !isBlank(res.title)) {
    messages.push(res.title);
  }
  if (res.hasOwnProperty("message") && !isBlank(res.message)) {
    messages.push(res.message);
  }
  const err = new RedsealAPIError<T>(messages.join("::"));
  err.setResponse(res);
  return Promise.reject(err);
};
