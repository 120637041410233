import * as React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { showMessageBoxAction } from "../../../modules/messageBoxModule";
import PostPlaceholder from "../../molecules/PostContainer/PostPlaceholder";
import { CSSTransition } from "react-transition-group";
import PostContainerList from "../../molecules/PostContainerList";
import {
  TRACKEVENT_CATEGORY,
  gaTrackEvent,
} from "src/js/redseal/lib/analytics-util";
import PostItemTileList from "../../molecules/PostTileList";
import PostTileListPlaceholder from "../../molecules/PostTileList/PostTileListPlaceholder";
import PostViewTypeSwitch from "../../atoms/PostViewTypeSwitch";
import ViewMoreButton from "../../atoms/ViewMoreButton";
import { spotsShowTypeAction } from "../../../modules/spotsModule";
import NoContent from "../../atoms/NoContent";
import PostContainerTransitionWrapper from "../../atoms/PostContainerTransitionWrapper";
import { isBot } from "src/js/redseal/lib/ua-util";

interface IProps {
  spotId: number;
  postType?: string;
  viewType: string;
  isExistPosts: boolean;
  postId: number | null;
}

const SpotsShowType: React.FC<IProps> = (props: IProps): JSX.Element => {
  const dispatch = useDispatch();

  // Local state
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [postIdList, setPostIdList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isViewMoreLoading, setIsViewMoreLoading] = useState(false);
  const [currentViewType, setCurrentViewType] = useState(props.viewType);
  const [isLastPage, setIsLastPage] = useState(false);

  useEffect(() => {
    didMount();
  }, []);

  const didMount = () => {
    if (props.isExistPosts) {
      spotsShowTypeAction(
        dispatch,
        props.spotId,
        props.postType,
        props.postId,
        currentPage,
        handleOnSuccess,
        handleOnError
      );
    }
  };

  const handleOnSuccess = (addedPostIdList: number[], hasNext: boolean) => {
    setIsLoading(false);
    setPostIdList(postIdList.concat(addedPostIdList));
    setCurrentPage(currentPage + 1);
    setIsLastPage(!hasNext);
  };

  const handleOnError = () => {
    showMessageBoxAction(dispatch, "予期せぬエラーが発生しました。");
  };

  const handleViewMore = () => {
    if (isViewMoreLoading) {
      return;
    }
    setIsViewMoreLoading(true);
    spotsShowTypeAction(
      dispatch,
      props.spotId,
      props.postType,
      null,
      currentPage,
      handleOnViewMoreSuccess,
      handleOnError
    );
    gaTrackEvent(
      TRACKEVENT_CATEGORY.SPOTS_SHOW_TYPE,
      "ViewMore",
      currentPage
    );
  };

  const handleOnViewMoreSuccess = (
    addedPostIdList: number[],
    hasNext: boolean
  ) => {
    setIsViewMoreLoading(false);
    setPostIdList(postIdList.concat(addedPostIdList));
    setCurrentPage(currentPage + 1);
    setIsLastPage(!hasNext);
  };

  const handleSwitchViewType = (viewType: string) => {
    setCurrentViewType(viewType);
  };

  return (
    <React.Fragment>
      <NavBar>
        <PostViewTypeSwitch
          selected={currentViewType}
          fn={(viewType) => handleSwitchViewType(viewType)}
        />
      </NavBar>
      {(() => {
        if (props.isExistPosts) {
          return (
            <React.Fragment>
              <CSSTransition
                in={isLoading}
                timeout={400}
                classNames="posts-show"
                onExited={() => {
                  setIsLoaded(true);
                }}
                unmountOnExit
              >
                <PostContainerTransitionWrapper transitionClassName={"posts-show"} isAdjustTopMargin={true}>
                  {currentViewType === "list" ? (
                    <PostPlaceholder />
                  ) : (
                    <PostTileListPlaceholder />
                  )}
                </PostContainerTransitionWrapper>
              </CSSTransition>
              <CSSTransition
                in={isLoaded}
                timeout={400}
                classNames="posts-show"
                unmountOnExit
              >
                <PostContainerTransitionWrapper transitionClassName={"posts-show"} isAdjustTopMargin={true}>
                  {currentViewType === "list" ? (
                    <PostContainerList
                      postIdList={postIdList}
                      usePush={!isBot()}
                    />
                  ) : (
                    <PostItemTileList postIdList={postIdList} />
                  )}
                  {!isLastPage && (
                    <ViewMoreButton
                      isViewMoreLoading={isViewMoreLoading}
                      fn={handleViewMore}
                    />
                  )}
                </PostContainerTransitionWrapper>
              </CSSTransition>
            </React.Fragment>
          );
        } else {
          return <NoContent />;
        }
      })()}
    </React.Fragment>
  );
};
export default SpotsShowType;

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 0 16px 0;
  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;
