import * as React from "react";
import styled from "styled-components";
import { fontsize } from "../../const/Mixin";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  AccountState,
  accountSelector,
  userSignOutAction,
  currentUserAction,
} from "../../../modules/accountModule";
import CircleUser from "../../atoms/CircleUser";
import Color from "../../const/Color";
import FontAwesome from "../../atoms/FontAwesome";
import Link from "../../atoms/Link";
import { isSignedIn } from "src/js/redseal/lib/auth-util";
import { openPostModalAction } from "../../../modules/postFormModule";
import { showMessageBoxAction } from "../../../modules/messageBoxModule";
import PostConst from "../../const/PostConst";
import { CSSTransition } from "react-transition-group";
import {
  hideOverlayAction,
  updateOverlayAction,
  showOverlayAction,
} from "../../../modules/overlayModule";
import BodyFixed from "src/js/redseal/lib/BodyFixed";
import { getBadgeText } from "src/js/redseal/lib/user-util";
import UserConst from "../../const/UserConst";
import Message from "../../const/Message";
import {
  gaTrackEvent,
  TRACKEVENT_CATEGORY,
} from "src/js/redseal/lib/analytics-util";

const SignedInUser: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const accountState: AccountState = useSelector(accountSelector);

  // Local state
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [isDropMenu, setIsDropMenu] = useState(false);

  const didMount = async () => {
    currentUserAction(dispatch);
  };

  const handleOnToggleMenu = () => {
    setIsShowMenu(!isShowMenu);
    if (isShowMenu) {
      BodyFixed.instance.off();
      gaTrackEvent(TRACKEVENT_CATEGORY.HEADER, "HideMenu");
    } else {
      BodyFixed.instance.on();
      gaTrackEvent(TRACKEVENT_CATEGORY.HEADER, "ShowMenu");
    }
  };

  const handleOnCloseMenu = () => {
    setIsShowMenu(false);
    BodyFixed.instance.off();
    gaTrackEvent(TRACKEVENT_CATEGORY.HEADER, "CloseMenu");
  };

  const handleOnClickMenuList = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  /**
   * 新規投稿
   */
  const handleOnPost = () => {
    if (isSignedIn()) {
      openPostModalAction(dispatch, PostConst.POST_MODE_CREATE);
      gaTrackEvent(TRACKEVENT_CATEGORY.HEADER, "OpenPostModal");
    } else {
      showMessageBoxAction(
        dispatch,
        "投稿するにはログインする必要があります。"
      );
      gaTrackEvent(TRACKEVENT_CATEGORY.HEADER, "UnsignedOpenPostModal");
    }
  };

  /**
   * ログアウト
   */
  const handleOnSignOut = () => {
    if (!window.confirm("ログアウトします。よろしいですか？")) {
      return;
    }

    showOverlayAction(dispatch, "ログアウト中…");
    userSignOutAction(dispatch, handleOnSuccess, handleOnError);
    gaTrackEvent(TRACKEVENT_CATEGORY.HEADER, "Logout");
  };

  const handleOnSuccess = (response: IUser) => {
    showMessageBoxAction(dispatch, "ログアウトしました。");
    updateOverlayAction(
      dispatch,
      "TOPページに移動します。しばらくお待ち下さい。"
    );
    setTimeout(() => {
      location.href = "/";
    }, 3000);
  };

  const handleOnError = () => {
    showMessageBoxAction(dispatch, Message.UNEXPECTED_ERROR);
    hideOverlayAction(dispatch);
  };

  useEffect(() => {
    didMount();
  }, []);

  return (
    <Wrapper>
      <UserInfo onClick={() => handleOnToggleMenu()}>
        <CircleUser
          profileImagePath={accountState.profileImage100}
          userId={accountState.userId}
          name={accountState.name}
          slug={accountState.slug}
          width={40}
        />
        <UserName>
          {accountState.name}
          <span className="glyphicon glyphicon-triangle-bottom" />
        </UserName>
      </UserInfo>
      <CSSTransition
        in={isShowMenu}
        timeout={500}
        classNames="drop-menu-modal"
        onEnter={() => {
          setIsDropMenu(true);
        }}
        onExited={() => {
          setIsDropMenu(false);
        }}
      >
        <DropMenuWrapper className={isDropMenu ? "showMenu" : "hideMenu"}>
          <DropMenu onClick={() => handleOnCloseMenu()}>
            <CSSTransition
              in={isShowMenu}
              timeout={200}
              classNames="drop-menu-list-modal"
            >
              <UserMenuList onClick={(event) => handleOnClickMenuList(event)}>
                <UserMenuItemWrapper onClick={() => handleOnPost()}>
                  <Link url={"#"} rel={"nofollow"}>
                    <UserMenuItem>
                      <FontAwesome className="fa fa-camera" />
                      新規投稿
                    </UserMenuItem>
                  </Link>
                </UserMenuItemWrapper>
                <UserMenuItemWrapper>
                  <Link url={`/users/${accountState.uid}`}>
                    <UserMenuItem>
                      <FontAwesome className="fa fa-user" />
                      プロフィール
                    </UserMenuItem>
                  </Link>
                </UserMenuItemWrapper>
                <UserMenuItemWrapper>
                  <Link url={"/users/my"} rel={"nofollow"}>
                    <UserMenuItem>
                      <FontAwesome className="fa fa-home" />
                      マイページ
                    </UserMenuItem>
                  </Link>
                </UserMenuItemWrapper>
                <UserMenuItemWrapper>
                  <Link url={`/users/${accountState.uid}/calendars`}>
                    <UserMenuItem>
                      <FontAwesome className="fa fa-calendar" />
                      カレンダー
                    </UserMenuItem>
                  </Link>
                </UserMenuItemWrapper>
                <UserMenuItemWrapper>
                  <Link url={"/users/my/notifications"} rel={"nofollow"}>
                    <UserMenuItem className="notification">
                      <FontAwesome className="fa fa-bell" />
                      <div>お知らせ</div>
                      {accountState.notificationCount >
                        UserConst.DISP_NOTIFICATION_NUM_MIN && (
                        <div className="notification-badge">
                          {getBadgeText(accountState.notificationCount)}
                        </div>
                      )}
                    </UserMenuItem>
                  </Link>
                </UserMenuItemWrapper>
                <Separator>設定</Separator>
                <UserMenuItemWrapper>
                  <Link url={"/users/my/profiles/edit"} rel={"nofollow"}>
                    <UserMenuItem>
                      <FontAwesome className="fa fa-cog" />
                      プロフィール編集
                    </UserMenuItem>
                  </Link>
                </UserMenuItemWrapper>
                <UserMenuItemWrapper>
                  <Link url={"/users/my/setting/notification"} rel={"nofollow"}>
                    <UserMenuItem>
                      <FontAwesome className="fa fa-cog" />
                      通知・コメント設定
                    </UserMenuItem>
                  </Link>
                </UserMenuItemWrapper>
                <Separator>サポート</Separator>
                <UserMenuItemWrapper>
                  <Link url={"/articles/faq"} rel={"nofollow"}>
                    <UserMenuItem>
                      <FontAwesome className="fa fa-question-circle" />
                      FAQ
                    </UserMenuItem>
                  </Link>
                </UserMenuItemWrapper>
                <UserMenuItemWrapper>
                  <Link url={"/info/report"} rel={"nofollow"}>
                    <UserMenuItem>
                      <FontAwesome className="fa fa-inbox" />
                      お問い合わせ
                    </UserMenuItem>
                  </Link>
                </UserMenuItemWrapper>
                {accountState.isStaff && (
                  <UserMenuItemWrapper>
                    <Link url={"/admins"} rel={"nofollow"}>
                      <UserMenuItem>
                        <FontAwesome className="fa fa-institution" />
                        管理ページ
                      </UserMenuItem>
                    </Link>
                  </UserMenuItemWrapper>
                )}
                <UserMenuItemWrapper onClick={() => handleOnSignOut()}>
                  <Link url={"#"} rel={"nofollow"}>
                    <UserMenuItem>
                      <FontAwesome className="fa fa-sign-out" />
                      ログアウト
                    </UserMenuItem>
                  </Link>
                </UserMenuItemWrapper>
              </UserMenuList>
            </CSSTransition>
          </DropMenu>
        </DropMenuWrapper>
      </CSSTransition>
    </Wrapper>
  );
};
export default SignedInUser;

const Wrapper = styled.div``;

const UserInfo = styled.div`
  height: 40px;
  margin-left: 8px;
  cursor: pointer;

  display: flex;
  align-items: center;
`;

const UserName = styled.div`
  padding-left: 4px;
  span {
    padding-left: 8px;
    ${fontsize(10)};
  }
  /* for smartphone */
  @media screen and (max-width: 540px) {
    display: none;
  }
`;

const DropMenuWrapper = styled.div`
  &.showMenu {
    display: block;
  }
  &.hideMenu {
    display: none;
  }

  &.drop-menu-modal-enter {
    opacity: 0;
  }
  &.drop-menu-modal-enter-active {
    transition: opacity 500ms linear;
    opacity: 1;
  }
  &.drop-menu-modal-enter-done {
    opacity: 1;
  }
  &.drop-menu-modal-exit {
    opacity: 1;
  }
  &.drop-menu-modal-exit-active {
    transition: opacity 500ms linear;
    opacity: 0;
  }
  &.drop-menu-modal-exit-done {
    opacity: 0;
  }
`;

const DropMenu = styled.div`
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 60px;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${Color.BG_COLOR_MODAL};
  cursor: pointer;
  z-index: 1002;
`;

const UserMenuList = styled.div`
  width: 320px;
  /* for smartphone */
  @media screen and (max-width: 540px) {
    width: 60%;
  }
  height: 100%;
  background-color: ${Color.BG_COLOR};
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${fontsize(14)};
  font-weight: bold;

  transition-duration: 500ms;

  &.drop-menu-list-modal-enter {
    transform: translateX(320px);
  }
  &.drop-menu-list-modal-enter-active {
    transform: translateX(0px);
  }
  &.drop-menu-list-modal-enter-done {
    transform: translateX(0px);
  }
  &.drop-menu-list-modal-exit {
    transform: translateX(0px);
  }
  &.drop-menu-list-modal-exit-active {
    transform: translateX(320px);
  }
  &.drop-menu-list-modal-exit-done {
    transform: translateX(320px);
  }
  @media screen and (max-width: 540px) {
    &.drop-menu-list-modal-enter {
      transform: translateX(100%);
    }
    &.drop-menu-list-modal-enter-active {
      transform: translateX(0%);
    }
    &.drop-menu-list-modal-enter-done {
      transform: translateX(0%);
    }
    &.drop-menu-list-modal-exit {
      transform: translateX(0%);
    }
    &.drop-menu-list-modal-exit-active {
      transform: translateX(100%);
    }
    &.drop-menu-list-modal-exit-done {
      transform: translateX(100%);
    }
  }
`;

const UserMenuItemWrapper = styled.div`
  width: 100%;
  cursor: pointer;
  text-align: left;
  a {
    padding: 0;
    font-weight: bold;
  }
  a {
    color: ${Color.FONT_COLOR};
    text-decoration: none;
  }
  a:link {
    color: ${Color.FONT_COLOR};
    text-decoration: none;
  }
  a:hover {
    color: ${Color.FONT_COLOR};
    text-decoration: none;
  }
  a:visited {
    color: ${Color.FONT_COLOR};
    text-decoration: none;
  }

  &:last-child {
    margin-top: auto;
    margin-bottom: 60px;
  }
`;

const UserMenuItem = styled.div`
  padding: 8px 16px;
  width: 100%;
  span {
    margin-right: 8px;
  }
  span.fa-camera {
    color: ${Color.FONT_COLOR};
  }

  &:hover {
    background: #f5f5f5;
  }

  &.notification {
    display: flex;
    align-items: center;

    .notification-badge {
      margin-left: auto;
      display: inline-block;
      min-width: 10px;
      padding: 3px 7px;
      font-size: 12px;
      ${fontsize(12)};
      font-weight: bold;
      color: ${Color.FONT_COLOR_REVERSAL};
      line-height: 1;
      vertical-align: middle;
      white-space: nowrap;
      text-align: center;
      background-color: ${Color.FONT_COLOR};
      border-radius: 10px;
    }
  }
`;

const Separator = styled.div`
  color: ${Color.SUB_COLOR_GOLD};
  padding: 8px 16px;
  text-align: left;
  width: 100%;
`;
