import { get } from "../network/api-client";

/**
 * 指定の投稿を取得するAPI
 * @param postId
 */
export const tagShow = async (
  tagName: string,
  spotId: number | null,
  breadcrumbId: number | null,
  page: number
): Promise<IRedsealAPIResponse<IPostContainer>> => {
  try {
    const params = [];
    if (spotId !== null) {
      params.push(`spot_id=${spotId.toString(10)}`);
    }
    if (breadcrumbId !== null) {
      params.push(`breadcrumb_id=${breadcrumbId.toString(10)}`);
    }
    params.push(`page=${page.toString(10)}`);

    const API_URI = "/api/tags/<tagName>";
    return await get<IPostContainer>(
      `${API_URI.replace("<tagName>", tagName)}?${params.join("&")}`
    );
    
  } catch (err) {
    throw err;
  }
};
