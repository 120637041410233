import * as React from "react";
import styled from "styled-components";
import LazyLoadImage from "../../atoms/LazyLoadImage";
import Link from "../../atoms/Link";
import FontAwesome from "../../atoms/FontAwesome";
import Color from "../../const/Color";
import { fontsize, normalBox, squreBox } from "../../const/Mixin";
import { getSpotNoImagePath } from "src/js/redseal/lib/image-util";

interface IProps {
  spot: ISpotsSearch;
}

const NearSpot: React.FC<IProps> = (props): JSX.Element => {
  const spotUrl = `/spots/${props.spot.spot_id}`;
  const spotIconClass = `fa fa-circle ${props.spot.spot_type_name}`;
  const spotImage = props.spot.pict_100 ? (
    <SpotPost>
      <LazyLoadImage
        src={props.spot.pict_100}
        alt={props.spot.name}
        width={"40"}
        height={"40"}
        objectFit={"cover"}
        noImage={getSpotNoImagePath(props.spot.spot_type_name)}
      />
    </SpotPost>
  ) : null;
  return (
    <Link url={spotUrl}>
      <Wrapper>
        <SpotInfo>
          <div>
            <FontAwesome className={spotIconClass} />
            {props.spot.name}
          </div>
          <Address>{props.spot.address}</Address>
        </SpotInfo>
        {spotImage}
      </Wrapper>
    </Link>
  );
};
export default NearSpot;

// style
const Wrapper = styled.div`
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;

  ${normalBox};
  padding: 8px 16px;
  margin-bottom: 8px;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    background: ${Color.BG_COLOR_BOX_HOVER};
  }

  span {
    margin-right: 8px;

    &.l_shrine {
      color: ${Color.SUB_COLOR_RED};
    }
    &.l_temple {
      color: ${Color.SUB_COLOR_PURPLE};
    }
    &.l_other {
      color: ${Color.SUB_COLOR_YELLOW};
    }
  }
`;

const SpotPost = styled.div`
  margin-left: 8px;
  .post_picture {
    ${squreBox(40)}
    background-color: ${Color.LOADING_PLACE_HOLDER_COLOR};
  }
`;

const SpotInfo = styled.div`
  flex: 1;
`;

const Address = styled.div`
  ${fontsize(12)}
  color: ${Color.SUB_COLOR_GOLD};
`;
