import * as React from "react";
import PostContainer from "../PostContainer";
import AdsCard from "../AdsCard";

interface IProps {
  postIdList: number[];
  usePush: boolean;
}

const PostContainerList: React.FC<IProps> = (props): JSX.Element => {
  const renderPosts = (): JSX.Element[] => {
    const posts = [];
    for (let i = 0; i < props.postIdList.length; i++) {
      if (i === 3 || i === 8) {
        posts.push(<AdsCard key={`ad${i}`} />);
      }
      posts.push(
        <PostContainer
          postId={props.postIdList[i]}
          usePush={props.usePush}
          key={props.postIdList[i]}
        />
      );
    }
    return posts;
  };

  return <React.Fragment>{renderPosts()}</React.Fragment>;
};
export default PostContainerList;
