import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action, Dispatch } from "redux";
import { RedsealState } from "../store/createStore";
import { getItems } from "../../domain/usecases/affiliates";

// state -------
export interface AffiliateState {
  isLoading: boolean;
  affiliateId: string;
  affiliates: IAffiliatesItems[];
}

const AffiliateInitialState: AffiliateState = {
  isLoading: true,
  affiliateId: null,
  affiliates: []
};

// payload -------
export interface ItemsAction extends Action {
  type: string;
  affiliates: IAffiliatesItems[];
}

// selector -------
export const affiliateSelector = (state: RedsealState): AffiliateState => {
  return state.affiliate;
};

// actions & reducers -------
export const affiliateModule = createSlice({
  name: "affiliate",
  initialState: AffiliateInitialState,
  reducers: {
    items: (state: AffiliateState, action: PayloadAction<ItemsAction>) => {
      state.isLoading = false;
      state.affiliates = action.payload.affiliates;
    }
  }
});

// actions -------
export const itemsAction = (
  dispatch: Dispatch<Action<any>>,
  affiliateId: string
) => {
  getItems(affiliateId).then((affiliates: IAffiliatesItems[]) => {
    dispatch(
      affiliateModule.actions.items({
        type: "items",
        affiliates: affiliates
      })
    );
  });
};
