import * as React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { showMessageBoxAction } from "../../../modules/messageBoxModule";
import PostPlaceholder from "../../molecules/PostContainer/PostPlaceholder";
import { CSSTransition } from "react-transition-group";
import PostContainerList from "../../molecules/PostContainerList";
import {
  TRACKEVENT_CATEGORY,
  gaTrackEvent,
} from "src/js/redseal/lib/analytics-util";
import { usersProfilesShowAction } from "../../../modules/usersModule";
import PostItemTileList from "../../molecules/PostTileList";
import PostTileListPlaceholder from "../../molecules/PostTileList/PostTileListPlaceholder";
import PostViewTypeSwitch from "../../atoms/PostViewTypeSwitch";
import PostTypeSelect from "../../atoms/PostTypeSelect";
import { isBlank } from "src/js/redseal/lib/string-util";
import PrefectureSelect from "../../atoms/PrefectureSelect";
import { isPostType } from "src/js/redseal/lib/post-type-util";
import { getPrefIdBySlug, getPrefSlugById } from "src/js/redseal/lib/area-util";
import ViewMoreButton from "../../atoms/ViewMoreButton";
import NoContent from "../../atoms/NoContent";
import PostContainerTransitionWrapper from "../../atoms/PostContainerTransitionWrapper";
import { isBot } from "src/js/redseal/lib/ua-util";

interface IProps {
  uid: string;
  postType?: string;
  postPref?: string;
  viewType: string;
  isExistPosts: boolean;
}

const UsersProfilesShow: React.FC<IProps> = (props: IProps): JSX.Element => {
  const dispatch = useDispatch();

  // Local state
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [postIdList, setPostIdList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isViewMoreLoading, setIsViewMoreLoading] = useState(false);
  const [selectedPostType, setSelectedPostType] = useState(
    isPostType(props.postType) ? props.postType : "all"
  );
  const [selectedPref, setSelectedPref] = useState(props.postPref);
  const [currentViewType, setCurrentViewType] = useState(props.viewType);
  const [isLastPage, setIsLastPage] = useState(false);

  useEffect(() => {
    didMount();
  }, []);

  const didMount = () => {
    if (props.isExistPosts) {
      usersProfilesShowAction(
        dispatch,
        props.uid,
        props.postType,
        props.postPref,
        currentPage,
        handleOnSuccess,
        handleOnError
      );
    }
  };

  const handleOnSuccess = (addedPostIdList: number[], hasNext: boolean) => {
    setIsLoading(false);
    setPostIdList(postIdList.concat(addedPostIdList));
    setCurrentPage(currentPage + 1);
    setIsLastPage(!hasNext);
  };

  const handleOnError = () => {
    showMessageBoxAction(dispatch, "予期せぬエラーが発生しました。");
  };

  const handleViewMore = () => {
    if (isViewMoreLoading) {
      return;
    }
    setIsViewMoreLoading(true);
    usersProfilesShowAction(
      dispatch,
      props.uid,
      props.postType,
      props.postPref,
      currentPage,
      handleOnViewMoreSuccess,
      handleOnError
    );
    gaTrackEvent(
      TRACKEVENT_CATEGORY.USERS_PROFILES_SHOW,
      "ViewMore",
      currentPage
    );
  };

  const handleOnViewMoreSuccess = (
    addedPostIdList: number[],
    hasNext: boolean
  ) => {
    setIsViewMoreLoading(false);
    setPostIdList(postIdList.concat(addedPostIdList));
    setCurrentPage(currentPage + 1);
    setIsLastPage(!hasNext);
  };

  const handleChangeSelectPostType = (postType: string) => {
    setSelectedPostType(postType);
    if (postType === "all") {
      location.href = `/users/${props.uid}${addViewTypeParameter()}`;
    } else {
      location.href = `/users/${
        props.uid
      }/${postType}${addViewTypeParameter()}`;
    }
  };

  const handleChangeSelectPref = (pref: number) => {
    const prefSlug = getPrefSlugById(pref);
    setSelectedPref(prefSlug);
    location.href = `/users/${
      props.uid
    }/pref/${prefSlug}${addViewTypeParameter()}`;
  };

  const handleSwitchViewType = (viewType: string) => {
    setCurrentViewType(viewType);
  };

  const addViewTypeParameter = (): string => {
    if (currentViewType === "tile") {
      return "?view=tile";
    }
    return "";
  };

  return (
    <React.Fragment>
      <NavBar>
        {(() => {
          if (!isBlank(props.postPref)) {
            return (
              <PrefectureSelect
                showAll={false}
                selected={getPrefIdBySlug(selectedPref)}
                fn={(pref) => handleChangeSelectPref(pref)}
                className={"select-post-pref"}
              />
            );
          } else {
            return (
              <PostTypeSelect
                selected={selectedPostType}
                fn={(postType) => handleChangeSelectPostType(postType)}
                className={"select-post-type"}
              />
            );
          }
        })()}
        <Separator />
        <PostViewTypeSwitch
          selected={currentViewType}
          fn={(viewType) => handleSwitchViewType(viewType)}
        />
      </NavBar>
      {(() => {
        if (props.isExistPosts) {
          return (
            <React.Fragment>
              <CSSTransition
                in={isLoading}
                timeout={400}
                classNames="posts-show"
                onExited={() => {
                  setIsLoaded(true);
                }}
                unmountOnExit
              >
                <PostContainerTransitionWrapper transitionClassName={"posts-show"} isAdjustTopMargin={true}>
                  {currentViewType === "list" ? (
                    <PostPlaceholder />
                  ) : (
                    <PostTileListPlaceholder />
                  )}
                </PostContainerTransitionWrapper>
              </CSSTransition>
              <CSSTransition
                in={isLoaded}
                timeout={400}
                classNames="posts-show"
                unmountOnExit
              >
                <PostContainerTransitionWrapper transitionClassName={"posts-show"} isAdjustTopMargin={true}>
                  {currentViewType === "list" ? (
                    <PostContainerList postIdList={postIdList} usePush={!isBot()} />
                  ) : (
                    <PostItemTileList postIdList={postIdList} />
                  )}
                  {!isLastPage && (
                    <ViewMoreButton
                      isViewMoreLoading={isViewMoreLoading}
                      fn={handleViewMore}
                    />
                  )}
                </PostContainerTransitionWrapper>
              </CSSTransition>
            </React.Fragment>
          );
        } else {
          return <NoContent />;
        }
      })()}
    </React.Fragment>
  );
};
export default UsersProfilesShow;

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 0 16px 0;
  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
  .select-post-type,
  .select-post-pref {
    width: 50%;
  }
`;

const Separator = styled.div`
  width: 16px;
`;
