import { createPortal } from "react-dom";

interface IProps {
  targetId: string;
}

const ModalPortal: React.FC<IProps> = ({ children, targetId }) => {
  return createPortal(children, document.getElementById(targetId));
};
export default ModalPortal;
