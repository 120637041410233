import * as React from "react";
import styled from "styled-components";
import FontAwesome from "../../atoms/FontAwesome";
import { numberShorten } from "src/js/redseal/lib/string-util";
import Color from "../../const/Color";
import { fontsize } from "../../const/Mixin";
import { postTypeColor } from "src/js/redseal/lib/post-type-util";

interface IProps {
  post_type_key: string;
  post_type_name: string;
  post_eval_good: number;
  post_eval_comment: number;
  post_eval_pageview: number;
  post_created: string;
}

interface IPostTypeProps {
  postType: string;
}

const PostTypeScores: React.FC<IProps> = (props): JSX.Element => {
  return (
    <PostScore>
      <PostType postType={props.post_type_key}>{props.post_type_name}</PostType>
      <PostScoreItem>
        <FontAwesome className={"fa fa-heart"} />
        <Score>{numberShorten(props.post_eval_good)}</Score>
      </PostScoreItem>
      <PostScoreItem>
        <FontAwesome className={"fa fa-comment"} />
        <Score>{numberShorten(props.post_eval_comment)}</Score>
      </PostScoreItem>
      <PostScoreItem>
        <FontAwesome className={"fa fa-signal"} />
        <Score>{numberShorten(props.post_eval_pageview)}</Score>
      </PostScoreItem>
      <PostDate>{props.post_created}</PostDate>
    </PostScore>
  );
};
export default PostTypeScores;

const PostScore = styled.div`
  display: flex;
  margin-top: 8px;
  padding: 0 16px;
  ${fontsize(14)}
`;

const PostScoreItem = styled.div``;

const Score = styled.span`
  color: ${Color.SUB_COLOR_GOLD};
  margin-left: 4px;
  margin-right: 8px;
`;

const PostDate = styled.div`
  color: ${Color.SUB_COLOR_GOLD};
  margin-left: auto;
`;

const PostType = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props: IPostTypeProps) => postTypeColor(props.postType)};
  border-radius: 10px;
  color: ${Color.FONT_COLOR_REVERSAL};
  ${fontsize(10)}
  font-weight: bold;
  margin-right: 16px;
  padding: 2px;
  width: 56px;
`;
