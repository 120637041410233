import { get } from "../network/api-client";

/**
 * 指定のIDのアフィリエイト情報を取得するAPI
 * @param affiliateId
 */
export const items = async (
  affiliateId: string
): Promise<IRedsealAPIResponse<IAffiliatesItems[]>> => {
  try {
    const API_URI = "/api/affiliates/items?id=<id>";
    return await get<IAffiliatesItems[]>(API_URI.replace("<id>", affiliateId));
  } catch (err) {
    throw err;
  }
};
