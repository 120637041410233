import * as React from "react";

interface IProps {
  url: string;
  target?: string;
  rel?: string;
}

const Link: React.FC<IProps> = (props): JSX.Element => {
  return (
    <a href={props.url} target={props.target} rel={props.rel}>
      {props.children}
    </a>
  );
};
export default Link;
