import { createStore, applyMiddleware, combineReducers } from "redux";
import { affiliateModule, AffiliateState } from "../modules/affiliateModule";
import { SpotState, spotModule } from "../modules/spotModule";
import { PostFormState, postFormModule } from "../modules/postFormModule";
import { SearchSpotState, searchSpotModule } from "../modules/searchSpotModule";
import { MessageBoxState, messageBoxModule } from "../modules/messageBoxModule";
import { overlayModule, OverlayState } from "../modules/overlayModule";
import { AccountState, accountModule } from "../modules/accountModule";
import { PostsState, postsModule } from "../modules/postsModule";
import { UsersState, usersModule } from "../modules/usersModule";
import { SpotsState, spotsModule } from "../modules/spotsModule";
import { CommentsState, commentsModule } from "../modules/commentsModule";

const middlewares = [];
if (process.env.NODE_ENV !== "production") {
  const { logger } = require("redux-logger");
  middlewares.push(logger);
}

export default createStore(
  combineReducers({
    affiliate: affiliateModule.reducer,
    spot: spotModule.reducer,
    searchSpot: searchSpotModule.reducer,
    postForm: postFormModule.reducer,
    overlay: overlayModule.reducer,
    messageBox: messageBoxModule.reducer,
    account: accountModule.reducer,
    users: usersModule.reducer,
    spots: spotsModule.reducer,
    posts: postsModule.reducer,
    comments: commentsModule.reducer,
  }),
  applyMiddleware(...middlewares)
);

export type RedsealState = {
  affiliate: AffiliateState;
  spot: SpotState;
  searchSpot: SearchSpotState;
  postForm: PostFormState;
  overlay: OverlayState;
  messageBox: MessageBoxState;
  account: AccountState;
  users: UsersState;
  spots: SpotsState;
  posts: PostsState;
  comments: CommentsState;
};
