import * as React from "react";
import styled from "styled-components";
import SearchSpot from "../SearchSpot";
import Loading from "../../atoms/Loading";
import Color from "../../const/Color";
import { fontsize, formControl } from "../../const/Mixin";
import FontAwesome from "../../atoms/FontAwesome";
import PrefectureSelect from "../../atoms/PrefectureSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  searchSpotSelector,
  SearchSpotState,
  selectPrefectureAction,
  searchByKeywordAction,
  searchByGeoAction,
} from "../../../modules/searchSpotModule";
import useDebouncedCallback from "use-debounce/lib/useDebouncedCallback";
import { createRef } from "react";
import { showMessageBoxAction } from "../../../modules/messageBoxModule";
import RsGeolocation from "src/js/redseal/lib/RsGeolocation";
import {
  TRACKEVENT_CATEGORY,
  gaTrackEvent,
} from "src/js/redseal/lib/analytics-util";

interface IProps {
  isLoading: boolean;
  mode: string;
  spots: ISpotsSearch[] | null;
}

const SearchSpotList: React.FC<IProps> = (props, ref): JSX.Element => {
  const dispatch = useDispatch();
  const searchSpotState: SearchSpotState = useSelector(searchSpotSelector);

  const spotInputRef = createRef<HTMLInputElement>();
  React.useImperativeHandle(ref, () => {
    return {
      focus: () => spotInputRef.current.focus(),
    };
  });

  // リスト height
  const wh = window.innerHeight - 94;

  const handleSelectPrefecture = (prefectureId: number) => {
    gaTrackEvent(
      TRACKEVENT_CATEGORY.SEARCH_MODAL,
      "SelectPrefecture",
      prefectureId
    );
    selectPrefectureAction(
      dispatch,
      searchSpotState.conditions.keyword,
      prefectureId,
      () => {
        showMessageBoxAction(dispatch, "予期せぬエラーが発生しました。");
      }
    );
  };

  /**
   * 検索フォーム.
   * @param keyword
   */
  const [handleChangeText] = useDebouncedCallback((keyword: string) => {
    searchByKeywordAction(
      dispatch,
      keyword,
      searchSpotState.conditions.prefectureId,
      () => {
        showMessageBoxAction(dispatch, "予期せぬエラーが発生しました。");
      }
    );
  }, 400);

  /**
   * 現在地から検索.
   */
  const handleClickLocation = () => {
    gaTrackEvent(TRACKEVENT_CATEGORY.SEARCH_MODAL, "SearchByGeo");
    searchByGeoAction(dispatch, (errorCode: number) => {
      showMessageBoxAction(
        dispatch,
        RsGeolocation.handlingGeoErrorMessage(errorCode)
      );
    });
  };

  const renderSpotDOM = (spots: ISpotsSearch[]): JSX.Element[] => {
    if (spots === null) {
      return null;
    }

    const searchedSpots = spots.map((spot: ISpotsSearch) => {
      return <SearchSpot key={spot.spot_id} mode={props.mode} spot={spot} />;
    });
    searchedSpots.push(
      <li key={"spots_new"}>
        <AddSpotItem>
          お探しの神社・お寺は見つかりましたか？
          <br />
          見つからない場合は都道府県で絞り込むか、お名前をもう一度確認してみてください。それでも見つからない場合、よろしければ寺社の追加をお願いします。
          <a href="/spots/new" rel="nofollow">
            <div>神社・お寺を追加する</div>
          </a>
        </AddSpotItem>
      </li>
    );
    return searchedSpots;
  };

  if (props.isLoading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <SearchForm>
        <PrefectureSelect
          showAll={true}
          selected={searchSpotState.conditions.prefectureId}
          fn={handleSelectPrefecture}
        />
        <KeywordInputWrapper>
          <KeywordInput
            placeholder="神社・お寺の名前から検索"
            autoComplete="off"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeText(event.target.value);
            }}
            ref={spotInputRef}
          />
          <FontAwesome className="fa fa-search" />
        </KeywordInputWrapper>
        <CurrentLocation
          onClick={() => {
            handleClickLocation();
          }}
        >
          <FontAwesome className="fa fa-location-arrow" />
        </CurrentLocation>
      </SearchForm>

      <Wrapper>
        <List theme={{ height: wh }}>{renderSpotDOM(props.spots)}</List>
      </Wrapper>
    </React.Fragment>
  );
};
export default React.forwardRef(SearchSpotList);

const Wrapper = styled.div`
  height: 100%;
`;

const SearchForm = styled.div`
  display: flex;
  margin: 8px;
  @media screen and (max-width: 768px) {
    margin: 4px 8px 8px 8px;
  }
`;

const KeywordInputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-right: 4px;
  span {
    position: absolute;
    top: 10px;
    left: 8px;
    color: ${Color.SUB_COLOR_GOLD};
  }
`;

const KeywordInput = styled.input`
  color: ${Color.FONT_COLOR};
  width: 100%;
  padding-left: 32px !important;
  ${fontsize(14)}
  ${formControl()}
`;

const CurrentLocation = styled.div`
  ${fontsize(16)}
  min-width: 36px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  border-radius: 50%;
  border: 1px solid ${Color.SUB_COLOR_GRAY};
  background: ${Color.SUB_COLOR_BLUE};
  color: ${Color.FONT_COLOR_REVERSAL};
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

const List = styled.ul`
  height: ${({ theme }) => theme.height}px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  --webkit-padding-start: 0px;
  padding: 0px;
`;

const AddSpotItem = styled.div`
  padding: 8px;
  cursor: pointer;
  ${fontsize(14)}
  &:hover {
    opacity: 0.8;
  }
  div {
    margin-top: 16px;
    background: #1e88a8;
    border: 3px solid #1e88a8;
    color: #fff;
    border-radius: 19px;
    padding: 8px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
  }
`;
