import { createSlice, PayloadAction, Action, Dispatch } from "@reduxjs/toolkit";
import { usecaseTagShow } from "../../domain/usecases/tags";
import { postContainerDispatcher } from "../../lib/module-util";

// actions -------

export const tagsShowAction = (
  dispatch: Dispatch<Action<any>>,
  tagName: string,
  spotId: number,
  breadcrumbId: number,
  page: number,
  onSuccess: (postIdList: number[], hasNext: boolean) => void,
  onError: () => void
) => {
  usecaseTagShow(tagName, spotId, breadcrumbId, page)
    .then((response) => {
      postContainerDispatcher(dispatch, response);
      onSuccess(
        response.posts.map((post: IPost) => {
          return post.post_id;
        }),
        response.paginator.has_next
      );
    })
    .catch((e) => {
      console.log(e);
      onError();
    });
};
