import * as React from "react";
import styled from "styled-components";
import Color from "../../const/Color";
import { useDispatch } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { useState, createRef } from "react";
import FontAwesome from "../../atoms/FontAwesome";
import CommentConst from "../../const/CommentConst";
import { showMessageBoxAction } from "../../../modules/messageBoxModule";
import {
  hideOverlayAction,
  showOverlayAction,
} from "../../../modules/overlayModule";
import { createAction } from "../../../modules/commentsModule";
import {
  gaTrackEvent,
  TRACKEVENT_CATEGORY,
} from "src/js/redseal/lib/analytics-util";
import Zindex from "../../const/Zindex";

interface IProps {
  postId: number;
  isShowModal: boolean;
  setIsShowModal: (isShowModal: boolean) => void;
}

const PostCommentModal: React.FC<IProps> = (props): JSX.Element => {
  const dispatch = useDispatch();

  // Local state
  const [isShowModalLocal, setIsShowModalLocal] = useState(false);
  const [comment, setComment] = useState("");

  const commentInputRef = createRef<HTMLTextAreaElement>();

  const handleClickClose = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (
      comment.length > 0 &&
      !window.confirm(
        "編集中のコメントがあります。\n編集内容を破棄してこのまま閉じますか？"
      )
    ) {
      return false;
    }
    setComment("");
    setIsShowModalLocal(false);
    gaTrackEvent(
      TRACKEVENT_CATEGORY.COMMENT_MODAL,
      "Close",
      props.postId
    );
  };

  /**
   * 本文.
   * @param event
   */
  const handleChangeTextArea = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    setComment(event.target.value);
  };

  const isValidForm = (): boolean => {
    if (isNaN(props.postId)) {
      return false;
    }

    if (
      comment.length < CommentConst.DESCRIPTION_MIN ||
      comment.length > CommentConst.DESCRIPTION_MAX
    ) {
      return false;
    }

    return true;
  };  

  /**
   * 投稿ボタン
   */
  const handleOnSubmit = () => {
    if (props.postId === null) {
      window.alert(
        "予期せぬエラーが発生しました。画面を再読み込みしてください。"
      );
      return;
    }

    if (!window.confirm("コメントを投稿します。よろしいですか？")) {
      return;
    }

    showOverlayAction(dispatch, "投稿中…");
    createAction(
      dispatch,
      props.postId,
      comment,
      handleOnSubmitSuccess,
      handleOnSubmitError
    );
    gaTrackEvent(
      TRACKEVENT_CATEGORY.COMMENT_MODAL,
      "Submit",
      props.postId
    );
  };

  const handleOnSubmitSuccess = (response: IComment) => {
    setComment("");
    setIsShowModalLocal(false);
    setTimeout(() => {
      hideOverlayAction(dispatch);
      showMessageBoxAction(dispatch, "投稿が完了しました。");
    }, 1000);
  };

  const handleOnSubmitError = () => {
    showMessageBoxAction(
      dispatch,
      "申し訳ありません。投稿が失敗しました。時間を空けて再度お試しください。"
    );
    hideOverlayAction(dispatch);
  };

  return (
    <CSSTransition
      in={props.isShowModal}
      timeout={0}
      classNames="post-comment-modal"
      onEntered={() => {
        setIsShowModalLocal(true);
        commentInputRef.current && commentInputRef.current.focus();
      }}
      unmountOnExit
    >
      <Wrapper theme={{ zindex: Zindex.POST_COMMENT_MODAL }}>
        <CSSTransition
          in={isShowModalLocal}
          timeout={200}
          classNames="post-comment-content"
          onExited={() => {
            props.setIsShowModal(false);
          }}
        >
          <Content>
            <Header>
              <HeaderTitle>コメント投稿</HeaderTitle>
              <Hide onClick={(event) => handleClickClose(event)}>
                <FontAwesome className="fa fa-times" />
              </Hide>
            </Header>
            <CommentForm>
              <CommentWrapper>
                <textarea
                  value={comment}
                  maxLength={CommentConst.DESCRIPTION_MAX}
                  placeholder={`コメントを入れてください (${
                    CommentConst.DESCRIPTION_MAX
                  }文字以内)`}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    handleChangeTextArea(event);
                  }}
                  ref={commentInputRef}
                />
                <CharCount>
                  {CommentConst.DESCRIPTION_MAX -
                    comment.length}
                </CharCount>
              </CommentWrapper>
              <PostButton>
                <button
                  type="button"
                  onClick={handleOnSubmit}
                  disabled={!isValidForm()}
                >
                  投稿する
                </button>
              </PostButton>
            </CommentForm>
          </Content>
        </CSSTransition>
      </Wrapper>
    </CSSTransition>
  );
};
export default PostCommentModal;

const Wrapper = styled.div`
  z-index: ${({ theme }) => theme.zindex};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: ${Color.BG_COLOR_MODAL};

  transition: opacity 400ms linear;
  &.post-comment-modal-enter {
    opacity: 0;
  }
  &.post-comment-modal-enter-active {
    opacity: 1;
  }
  &.post-comment-modal-enter-done {
    opacity: 1;
  }
  &.post-comment-modal-exit {
    opacity: 1;
  }
  &.post-comment-modal-exit-active {
    opacity: 0;
  }
  &.post-comment-modal-exit-done {
    opacity: 0;
  }
`;

const Content = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 0;
  background-color: ${Color.BG_COLOR_BOX};
  border-radius: 8px 8px 0 0 / 8px 8px 0 0;
  color: ${Color.FONT_COLOR};
  height: 0;
  width: 600px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }

  transition: height 400ms ease;
  &.post-comment-content-enter {
    height: 0;
  }
  &.post-comment-content-enter-active {
    height: 62%;
  }
  &.post-comment-content-enter-done {
    height: 62%;
  }
  &.post-comment-content-exit {
    height: 62%;
  }
  &.post-comment-content-exit-active {
    height: 0;
  }
  &.post-comment-content-exit-done {
    height: 0;
  }
`;

const Header = styled.div`
  width: 100%;
  padding: 8px 16px;
  position: relative;
`;

const HeaderTitle = styled.div`
  width: 100%;
  text-align: center;
  font-weight: bold;
`;

const Hide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40px;
  span {
    cursor: pointer;
  }
`;

const CommentForm = styled.div`
  margin-top: 8px;
  padding: 0 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CommentWrapper = styled.div`
  position: relative;
  textarea {
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    height: 120px;
    color: ${Color.FONT_COLOR};
    padding: 4px;

    &::placeholder {
      font-size: 14px;
    }
  }
`;

const CharCount = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  color: ${Color.SUB_COLOR_GOLD};
`;

const PostButton = styled.div`
  margin-top: 16px;
  button {
    background: ${Color.SUB_COLOR_RED};
    border: 3px solid ${Color.SUB_COLOR_RED};
    color: white;
    border-radius: 19px;
    padding: 8px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    width: 100%;
    &:disabled {
      background: ${Color.SUB_COLOR_GOLD};
      border: 3px solid ${Color.SUB_COLOR_GOLD};
    }
    &:hover {
      opacity: 0.8;
    }
  }
`;
