import * as React from "react";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { showMessageBoxAction } from "../../../modules/messageBoxModule";
import PostPlaceholder from "../../molecules/PostContainer/PostPlaceholder";
import { CSSTransition } from "react-transition-group";
import PostContainerList from "../../molecules/PostContainerList";
import { myTimelineAction } from "../../../modules/accountModule";
import {
  TRACKEVENT_CATEGORY,
  gaTrackEvent,
} from "src/js/redseal/lib/analytics-util";
import ViewMoreButton from "../../atoms/ViewMoreButton";
import PostContainerTransitionWrapper from "../../atoms/PostContainerTransitionWrapper";
import { isBot } from "src/js/redseal/lib/ua-util";

const UsersMy: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();

  // Local state
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [postIdList, setPostIdList] = useState([]);
  const [page, setPage] = useState(1);
  const [isViewMoreLoading, setIsViewMoreLoading] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);

  useEffect(() => {
    didMount();
  }, []);

  const didMount = () => {
    myTimelineAction(dispatch, page, handleOnSuccess, handleOnError);
  };

  const handleOnSuccess = (addedPostIdList: number[], hasNext: boolean) => {
    setIsLoading(false);
    setPostIdList(postIdList.concat(addedPostIdList));
    setPage(page + 1);
    setIsLastPage(!hasNext);
  };

  const handleOnError = () => {
    showMessageBoxAction(dispatch, "予期せぬエラーが発生しました。");
  };

  const handleViewMore = () => {
    if (isViewMoreLoading) {
      return;
    }
    setIsViewMoreLoading(true);
    myTimelineAction(dispatch, page, handleOnViewMoreSuccess, handleOnError);
    gaTrackEvent(TRACKEVENT_CATEGORY.USERS_MY, "ViewMore", page);
  };

  const handleOnViewMoreSuccess = (
    addedPostIdList: number[],
    hasNext: boolean
  ) => {
    setIsViewMoreLoading(false);
    setPostIdList(postIdList.concat(addedPostIdList));
    setPage(page + 1);
    setIsLastPage(!hasNext);
  };

  return (
    <React.Fragment>
      <CSSTransition
        in={isLoading}
        timeout={400}
        classNames="posts-show"
        onExited={() => {
          setIsLoaded(true);
        }}
        unmountOnExit
      >
        <PostContainerTransitionWrapper transitionClassName={"posts-show"} isAdjustTopMargin={true}>
          <PostPlaceholder />
        </PostContainerTransitionWrapper>
      </CSSTransition>
      <CSSTransition
        in={isLoaded}
        timeout={400}
        classNames="posts-show"
        unmountOnExit
      >
        <PostContainerTransitionWrapper transitionClassName={"posts-show"} isAdjustTopMargin={true}>
          <PostContainerList postIdList={postIdList} usePush={!isBot()} />
          {!isLastPage && (
            <ViewMoreButton
              isViewMoreLoading={isViewMoreLoading}
              fn={handleViewMore}
            />
          )}
        </PostContainerTransitionWrapper>
      </CSSTransition>
    </React.Fragment>
  );
};
export default UsersMy;
