import * as React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Color from "../../const/Color";
import { fontsize } from "../../const/Mixin";
import LazyLoadImage from "../../atoms/LazyLoadImage";
import { selectSpotAction } from "../../../modules/postFormModule";
import SearchSpotConst from "../../const/SearchSpotConst";
import { closeSearchSpotModalAction } from "../../../modules/searchSpotModule";
import { getSpotNoImagePath } from "src/js/redseal/lib/image-util";
import {
  TRACKEVENT_CATEGORY,
  gaTrackEvent,
} from "src/js/redseal/lib/analytics-util";

interface IProps {
  mode: string;
  spot: ISpotsSearch;
}

const SearchSpot: React.FC<IProps> = (props): JSX.Element => {
  const dispatch = useDispatch();

  const spotImage = props.spot.pict_100 ? (
    <LazyLoadImage
      src={props.spot.pict_100}
      alt={props.spot.name}
      width={"40"}
      height={"40"}
      objectFit={"cover"}
      noImage={getSpotNoImagePath(props.spot.spot_type_name)}
    />
  ) : null;

  const handleClickSpot = (spotId: number, spotName: string, mode: string) => {
    if (mode === SearchSpotConst.MODE_POST) {
      gaTrackEvent(TRACKEVENT_CATEGORY.SEARCH_MODAL, "SelectListItemToPost", spotId);
      selectSpotAction(dispatch, spotId, spotName);
      closeSearchSpotModalAction(dispatch);
    } else {
      gaTrackEvent(TRACKEVENT_CATEGORY.SEARCH_MODAL, "SelectListItemToSpot", spotId);
      window.open(`/spots/${spotId}`, "_blank");
    }
  };

  return (
    <Item
      onClick={() =>
        handleClickSpot(props.spot.spot_id, props.spot.name, props.mode)
      }
    >
      <Wrapper>
        <Info>
          <Name>{props.spot.name}</Name>
          <Address>{props.spot.address}</Address>
        </Info>
        <Pict>{spotImage}</Pict>
      </Wrapper>
    </Item>
  );
};
export default SearchSpot;

const Item = styled.li`
  list-style: none;
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid ${Color.LIST_BORDER_COLOR};
  &:hover {
    background: ${Color.BG_COLOR_BOX_HOVER};
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.div`
  font-weight: bold;
`;

const Address = styled.div`
  ${fontsize(14)}
  color: ${Color.FONT_COLOR_GRAY};
`;

const Pict = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 3px;
  overflow: hidden;
  margin-left: auto;
`;
