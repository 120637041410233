import { get, del } from "../network/api-client";

/**
 * ログインユーザの未読通知件数を返すAPI。
 * ログイン必須。
 */
export const countNotification = async (): Promise<
  IRedsealAPIResponse<IUserNotificationCountersCount>
> => {
  try {
    const API_URI = "/api/users/my/notifications/count";
    return await get<IUserNotificationCountersCount>(API_URI);
  } catch (err) {
    throw err;
  }
};

/**
 * ログインユーザの未読通知件数を初期化するAPI。
 * ログイン必須。
 */
export const deleteNotification = async (): Promise<
  IRedsealAPIResponse<IUserNotificationCountersDelete>
> => {
  try {
    const API_URI = "/api/users/my/notifications/delete";
    return await del<IUserNotificationCountersDelete>(API_URI);
  } catch (err) {
    throw err;
  }
};
