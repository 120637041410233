import * as React from "react";
import styled from "styled-components";
import { PostData } from "../../../modules/postFormModule";
import PostItem from "../PostItem";
import { TransitionGroup, CSSTransition } from "react-transition-group";

interface IProps {
  posts: PostData[];
}

const renderPhotosDOM = (posts: PostData[]): JSX.Element[] => {
  if (posts === null || posts.length <= 0) {
    return null;
  }

  return posts.map((post: PostData, index: number) => {
    return (
      <CSSTransition key={index} timeout={500} classNames="fade-item">
        <PostItem key={index} post={post} />
      </CSSTransition>
    );
  });
};

const PostItemList: React.FC<IProps> = (props): JSX.Element => {
  return (
    <PostItemListContainer>
      <TransitionGroup component={null}>
        {renderPhotosDOM(props.posts)}
      </TransitionGroup>
    </PostItemListContainer>
  );
};
export default PostItemList;

const PostItemListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
