import * as React from "react";
import styled from "styled-components";
import Color from "../../const/Color";
import { useDispatch } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { useState } from "react";
import FontAwesome from "../../atoms/FontAwesome";
import { showMessageBoxAction } from "../../../modules/messageBoxModule";
import {
  hideOverlayAction,
  showOverlayAction,
} from "../../../modules/overlayModule";
import {
  TRACKEVENT_CATEGORY,
  gaTrackEvent,
} from "src/js/redseal/lib/analytics-util";
import { fontsize } from "../../const/Mixin";
import { userBlockApplyAction, userBlockReleaseAction } from "../../../modules/accountModule";
import Zindex from "../../const/Zindex";

interface IProps {
  targetUserId: number;
  targetUserName: string;
  blockState: boolean;
  isShowModal: boolean;
  setIsShowModal: (isShowModal: boolean) => void;
}

const UserControlModal: React.FC<IProps> = (props): JSX.Element => {
  const dispatch = useDispatch();

  // Local state
  const [isShowModalLocal, setIsShowModalLocal] = useState(false);
  const [blockState, setblockState] = useState(props.blockState);  

  /**
   * 閉じるボタン
   * @param event
   */
  const handleClickClose = (event: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setIsShowModalLocal(false);
  };

  const handleIgnoreEvent = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  /**
   * ブロックボタン
   * @param event 
   */
  const handleClickBlock = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    gaTrackEvent(
      TRACKEVENT_CATEGORY.USER_CONTROL_MODAL,
      blockState ? "ReleaseBlockUser" : "ApplyBlockUser",
      props.targetUserId
    );

    if (!window.confirm(`${blockState ? "ブロックを解除" : "このユーザをブロック"}しますか？`)) {
      setIsShowModalLocal(false);
      return;
    }

    showOverlayAction(dispatch, `ブロック${blockState ? "解除" : "処理"}中…`);

    if (blockState) {
      userBlockReleaseAction(
        props.targetUserId,
        handleBlockOnSuccess,
        handleBlockOnError
      );

    } else {
      userBlockApplyAction(
        props.targetUserId,
        handleBlockOnSuccess,
        handleBlockOnError
      );
    }
  };

  const handleBlockOnSuccess = (response: IUser) => {
    const beforeBlockState = blockState;
    setIsShowModalLocal(false);
    setblockState(!blockState);
    setTimeout(() => {
      hideOverlayAction(dispatch);
      showMessageBoxAction(
        dispatch,
        `${beforeBlockState ? "ブロックの解除" : "ブロック"}が完了しました。`
      );
    }, 1000);
  };

  const handleBlockOnError = () => {
    showMessageBoxAction(
      dispatch,
      `申し訳ありません。${blockState ? "ブロックの解除" : "ブロック"}に失敗しました。時間を空けて再度お試しください。`
    );
    hideOverlayAction(dispatch);
  };

  const getBlockText = (blockState: boolean): string => {
    if (blockState) {
      return `${props.targetUserName}のブロックを解除する`;
    } else {
      return `${props.targetUserName}をブロックする`;
    }
  };

  const handleClickReport = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    gaTrackEvent(
      TRACKEVENT_CATEGORY.USER_CONTROL_MODAL,
      "ReportUser",
      props.targetUserId
    );

    if (!window.confirm("このユーザを報告しますか？報告する場合、問い合わせページに遷移します。")) {
      setIsShowModalLocal(false);
      return;
    }

    location.href = "/info/report?kind=2";
  };
  

  return (
    <CSSTransition
      in={props.isShowModal}
      timeout={0}
      classNames="user-control-modal"
      onEntered={() => {
        setIsShowModalLocal(true);
      }}
      unmountOnExit
    >
      <Wrapper theme={{ zindex: Zindex.USER_CONTROL_MODAL }} onClick={(event) => {
        handleClickClose(event);
      }}>
        <CSSTransition
          in={isShowModalLocal}
          timeout={200}
          classNames="user-control-content"
          onExited={() => {
            props.setIsShowModal(false);
          }}
          unmountOnExit
          >
          <Content onClick={(event) => {
            handleIgnoreEvent(event);
          }}>
            <Header>
              <HeaderTitle></HeaderTitle>
              <Hide onClick={(event) => {
                handleClickClose(event);
              }}>
                <FontAwesome className="fa fa-times"  data-tooltip="閉じる" />
              </Hide>
            </Header>
            <OptionGroup>
              <OptionButton onClick={(event) => {
                handleClickBlock(event);
              }}>
                {getBlockText(blockState)}
                <div className="control-desc">
                  ブロックすると、このユーザはあなたの投稿にコメントができなくなります。
                </div>
              </OptionButton>
            </OptionGroup>
            <OptionGroup>
              <OptionButton onClick={(event) => {
                handleClickReport(event);
              }}>
                報告する
                <div className="control-desc">
                  不適切だと思われる投稿やコメントが見られる場合は報告してください。
                </div>
              </OptionButton>
            </OptionGroup>
            <OptionGroup>
              <OptionButton onClick={(event) => {
                handleClickClose(event);
              }}>
                キャンセル
              </OptionButton>
            </OptionGroup>
          </Content>
        </CSSTransition>
      </Wrapper>
    </CSSTransition>
  );
};
export default UserControlModal;

const Wrapper = styled.div`
  cursor: pointer;
  z-index: ${({ theme }) => theme.zindex};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: ${Color.BG_COLOR_MODAL};

  transition: opacity 400ms linear;
  &.user-control-modal-enter {
    opacity: 0;
  }
  &.user-control-modal-enter-active {
    opacity: 1;
  }
  &.user-control-modal-enter-done {
    opacity: 1;
  }
  &.user-control-modal-exit {
    opacity: 1;
  }
  &.user-control-modal-exit-active {
    opacity: 0;
  }
  &.user-control-modal-exit-done {
    opacity: 0;
  }
`;

const Content = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 0;
  background-color: ${Color.BG_COLOR_BOX};
  border-radius: 8px 8px 0 0 / 8px 8px 0 0;
  color: ${Color.FONT_COLOR};
  height: 0;
  width: 600px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }

  transition: height 400ms ease;
  &.user-control-content-enter {
    height: 0;
  }
  &.user-control-content-enter-active {
    height: 62%;
  }
  &.user-control-content-enter-done {
    height: 62%;
  }
  &.user-control-content-exit {
    height: 62%;
  }
  &.user-control-content-exit-active {
    height: 0;
  }
  &.user-control-content-exit-done {
    height: 0;
  }
`;

const Header = styled.div`
  height: 40px;
  width: 100%;
  padding: 8px 16px;
  position: relative;
`;

const HeaderTitle = styled.div`
  width: 100%;
  text-align: center;
  font-weight: bold;
`;

const Hide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40px;
  span {
    cursor: pointer;
  }
`;

const OptionGroup = styled.div`
  border-bottom: solid 1px ${Color.SUB_COLOR_GRAY};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const OptionButton = styled.button`
  background: ${Color.BG_COLOR_BOX};
  border: none;
  padding: 24px;
  text-align: left;
  &:hover {
    background: ${Color.SUB_COLOR_GRAY};
  }
  .control-desc {
    color: ${Color.SUB_COLOR_GOLD};
    ${fontsize(12)}
  }
`;
