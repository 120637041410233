import {
  countNotification,
  deleteNotification,
} from "../../data/datastores/user-notification-counters";
import {
  userSignOut,
  currentUser,
  userFollow,
  userUnfollow,
  userTimeline,
  userProfileShow,
  userSpotShow,
  userCalendarVisit,
  userBlockApply,
  userBlockRelease,
  getMyProfile,
  postMyProfile,
  removeMyProfilePicture,
  checkSlug,
} from "../../data/datastores/users";

export const usecaseNotificationCount = async (): Promise<
  IUserNotificationCountersCount
> => {
  const response = await countNotification();
  return response.data;
};

export const usecaseNotificationDelete = async (): Promise<
  IUserNotificationCountersDelete
> => {
  const response = await deleteNotification();
  return response.data;
};

export const usecaseUserFollow = async (form: FormData): Promise<IFollow> => {
  const response = await userFollow(form);
  return response.data;
};

export const usecaseUserUnfollow = async (form: FormData): Promise<IFollow> => {
  const response = await userUnfollow(form);
  return response.data;
};

export const usecaseCurrentUser = async (): Promise<IUser> => {
  const response = await currentUser();
  return response.data;
};

export const usecaseUserSignOut = async (): Promise<IUser> => {
  const response = await userSignOut();
  return response.data;
};

export const usecaseUserTimeline = async (
  page: number
): Promise<IPostContainer> => {
  const response = await userTimeline(page);
  return response.data;
};

export const usecaseUserProfileShow = async (
  uid: string,
  postType: string,
  postPref: string,
  page: number
): Promise<IPostContainer> => {
  const response = await userProfileShow(uid, postType, postPref, page);
  return response.data;
};

export const usecaseUserSpotShow = async (
  uid: string,
  spotId: number,
  page: number
): Promise<IPostContainer> => {
  const response = await userSpotShow(uid, spotId, page);
  return response.data;
};

export const usecaseUserCalendarVisit = async (
  uid: string,
  year: string,
  month: string,
  day: string,
  page: number
): Promise<IPostContainer> => {
  const response = await userCalendarVisit(uid, year, month, day, page);
  return response.data;
};

export const usecaseUserBlockApply = async(
  userId: number
): Promise<IUser> => {
  const response = await userBlockApply(userId);
  return response.data;  
};

export const usecaseUserBlockRelease = async(
  userId: number
): Promise<IUser> => {
  const response = await userBlockRelease(userId);
  return response.data;  
};

export const usecaseUserMyProfileEdit = async (): Promise<IAccount> => {
  const response = await getMyProfile();
  return response.data;
};

export const usecaseUserMyProfileEditPost = async (formData: FormData): Promise<IAccount> => {
  const response = await postMyProfile(formData);
  return response.data;
};

export const usecaseUserMyProfileRemovePicture = async (): Promise<IAccount> => {
  const response = await removeMyProfilePicture();
  return response.data;
};

export const usecaseUserMyProfileSlugCheck = async (formData: FormData): Promise<IAccount> => {
  const response = await checkSlug(formData);
  return response.data;
};