import * as React from "react";
import styled, { css } from "styled-components";
import AmazonAffiliate from "../../molecules/AmazonAffiliate";
import Loading from "../../atoms/Loading";
import { normalBox } from "../../const/Mixin";
import { useDispatch, useSelector } from "react-redux";
import {
  AffiliateState,
  affiliateSelector,
  itemsAction
} from "../../../modules/affiliateModule";
import { useEffect } from "react";
import { Dispatch } from "redux";

interface IProps {
  affiliateId: string;
  direction: string;
}

const didMount = async (props: IProps, dispatch: Dispatch) => {
  itemsAction(dispatch, props.affiliateId);
};

const AmazonAffiliateList: React.FC<IProps> = (props): JSX.Element => {
  const dispatch = useDispatch();

  useEffect(() => {
    didMount(props, dispatch);
  }, []);

  const affiliateState: AffiliateState = useSelector(affiliateSelector);

  if (affiliateState.isLoading) {
    return <Loading />;
  }
  return (
    <Wrapper>
      {affiliateState.affiliates.map(
        (item: IAffiliatesItems, index: number) => {
          return (
            <AmazonAffiliate
              affiliate={item}
              direction={props.direction}
              key={index}
            />
          );
        }
      )}
    </Wrapper>
  );
};
export default AmazonAffiliateList;

// style
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 16px 8px;
  @media screen and (max-width: 768px) {
    margin: 0 8px 16px;
  }
  ${normalBox}
`;
