import * as React from "react";
import styled from "styled-components";

interface IProps {
  content: string;
}

const Paragraph: React.FC<IProps> = (props: IProps): JSX.Element => {
  return (
    <Wrapper>
      {props.content.split("\n").map((str, index) => {
        return (
          <React.Fragment key={index}>
            {str}
            <br />
          </React.Fragment>
        );
      })}
    </Wrapper>
  );
};
export default Paragraph;

const Wrapper = styled.p`
  margin: 0 0 4px 0;
`;
