import * as React from "react";
import styled, { css } from "styled-components";
import Color from "../../const/Color";
import Link from "../../atoms/Link";
import BackgroundImage from "../../atoms/BackgroundImage";

interface IProps {
  affiliate: IAffiliatesItems;
  direction: string;
}

const truncate = (str: string, len: number): string => {
  if (str.length <= len) {
    return str;
  } else {
    return str.substr(0, len - 1) + "…";
  }
};

const AmazonAffiliate: React.FC<IProps> = (props): JSX.Element => {
  const flexOutDirection =
    props.direction == "row" ? "flex_direction_row" : "flex_direction_column";
  const flexInDirection =
    props.direction == "row" ? "flex_direction_column" : "flex_direction_row";

  return (
    <Wrapper className={flexOutDirection}>
      <Link url={props.affiliate.affiliate_link} target={"_blank"}>
        <Inner>
          <BackgroundImage
            url={props.affiliate.image_url}
            size={"contain"}
            className={`affiliate_img ${flexInDirection}`}
            height={160}
          />
          <Info className={flexInDirection}>
            <Title>
              <div className="full">{props.affiliate.title}</div>
              <div className="trunc">{truncate(props.affiliate.title, 24)}</div>
            </Title>
            <Button>Amazonで見る</Button>
          </Info>
        </Inner>
      </Link>
    </Wrapper>
  );
};
export default AmazonAffiliate;

// style
const Wrapper = styled.div`
  width: calc(100% / 2);
  align-self: stretch;
  &.flex_direction_column {
    width: 100%;
  }
  padding: 2px;

  a {
    color: ${Color.FONT_COLOR};
    text-decoration: none;
  }
  a:link {
    color: ${Color.FONT_COLOR};
    text-decoration: none;
  }
  a:hover {
    color: ${Color.FONT_COLOR};
    text-decoration: none;
  }
  a:visited {
    color: ${Color.FONT_COLOR};
    text-decoration: none;
  }
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .affiliate_img {
    width: 100%;
    height: 160px;
    margin-bottom: 8px;
    &.flex_direction_row {
      width: calc(100% / 2);
    }
  }
`;

const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  &.flex_direction_row {
    width: calc(100% / 2);
  }
`;

const Title = styled.div`
  margin: 0 8px 8px 8px;
  font-size: 14px;
  font-size: 1.4rem;
  text-align: center;
  @media screen and (max-width: 768px) {
    .full {
      display: none;
    }
    .trunc {
      display: block;
    }
  }
  @media screen and (min-width: 768px) {
    .full {
      display: block;
    }
    .trunc {
      display: none;
    }
  }
`;

const Button = styled.div`
  background: ${Color.SUB_COLOR_PINK};
  border-radius: 3px;
  color: ${Color.FONT_COLOR_REVERSAL};
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0 8px;
  padding: 8px;
  text-align: center;
  &:hover {
    opacity: 0.8;
  }
`;
