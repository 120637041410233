import * as React from "react";
import styled from "styled-components";
import NearSpot from "../../molecules/NearSpot";
import FontAwesome from "../../atoms/FontAwesome";
import Loading from "../../atoms/Loading";
import Color from "../../const/Color";
import { fontsize } from "../../const/Mixin";
import { Dispatch } from "redux";
import {
  SpotState,
  spotSelector,
  aroundAction
} from "../../../modules/spotModule";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

interface IProps {
  spotId: number;
  name: string;
}

const didMount = async (props: IProps, dispatch: Dispatch) => {
  aroundAction(dispatch, props.spotId);
};

const NearSpotList: React.FC<IProps> = (props): JSX.Element => {
  const dispatch = useDispatch();

  useEffect(() => {
    didMount(props, dispatch);
  }, []);

  const spotState: SpotState = useSelector(spotSelector);

  if (spotState.isLoading) {
    return (
      <Wrapper>
        <h3>
          <FontAwesome className={"fa fa-map-marker"} />
          {props.name} 近くの寺社
        </h3>
        <Loading />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h3>
        <FontAwesome className={"fa fa-map-marker"} />
        {props.name} 近くの寺社
      </h3>
      <ul>
        {spotState.spots.map((spot: ISpotsSearch, index: number) => {
          return (
            <li key={index.toString()}>
              <NearSpot spot={spot} />
            </li>
          );
        })}
      </ul>
    </Wrapper>
  );
};
export default NearSpotList;

const Wrapper = styled.div`
  margin-bottom: 32px;
  h3 {
    margin: 16px 0;
    @media screen and (max-width: 768px) {
      margin: 8px 0 14px 0;
    }
    padding: 0 8px 8px 8px;
    border-bottom: 2px solid ${Color.SUB_COLOR_PURPLE};
    ${fontsize(16)}
    span {
      margin-right: 8px;
      color: ${Color.SUB_COLOR_PURPLE};
    }
  }
  a {
    color: ${Color.FONT_COLOR};
    text-decoration: none;
  }
  a:link {
    color: ${Color.FONT_COLOR};
    text-decoration: none;
  }
  a:hover {
    color: ${Color.FONT_COLOR};
    text-decoration: none;
  }
  a:visited {
    color: ${Color.FONT_COLOR};
    text-decoration: none;
  }

  ul {
    -webkit-padding-start: 0px;
    padding: 0px;
    li {
      list-style: none;
    }
  }
`;
