import * as React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { showMessageBoxAction } from "../../../modules/messageBoxModule";
import PostPlaceholder from "../../molecules/PostContainer/PostPlaceholder";
import { CSSTransition } from "react-transition-group";
import PostContainerList from "../../molecules/PostContainerList";
import {
  TRACKEVENT_CATEGORY,
  gaTrackEvent,
} from "src/js/redseal/lib/analytics-util";
import ViewMoreButton from "../../atoms/ViewMoreButton";
import { usersCalendarsVisitAction } from "../../../modules/usersModule";
import NoContent from "../../atoms/NoContent";
import PostContainerTransitionWrapper from "../../atoms/PostContainerTransitionWrapper";

interface IProps {
  uid: string;
  year: string;
  month: string;
  day: string;
  isExistPosts: boolean;
}

const UsersCalendarsVisit: React.FC<IProps> = (props): JSX.Element => {
  const dispatch = useDispatch();

  // Local state
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [postIdList, setPostIdList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isViewMoreLoading, setIsViewMoreLoading] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);

  useEffect(() => {
    didMount();
  }, []);

  const didMount = () => {
    if (props.isExistPosts) {
      usersCalendarsVisitAction(
        dispatch,
        props.uid,
        props.year,
        props.month,
        props.day,
        currentPage,
        handleOnSuccess,
        handleOnError
      );
    }
  };

  const handleOnSuccess = (addedPostIdList: number[], hasNext: boolean) => {
    setIsLoading(false);
    setPostIdList(postIdList.concat(addedPostIdList));
    setCurrentPage(currentPage + 1);
    setIsLastPage(!hasNext);
  };

  const handleOnError = () => {
    showMessageBoxAction(dispatch, "予期せぬエラーが発生しました。");
  };

  const handleViewMore = () => {
    if (isViewMoreLoading) {
      return;
    }
    setIsViewMoreLoading(true);
    usersCalendarsVisitAction(
      dispatch,
      props.uid,
      props.year,
      props.month,
      props.day,
      currentPage,
      handleOnViewMoreSuccess,
      handleOnError
    );
    gaTrackEvent(TRACKEVENT_CATEGORY.USERS_CALENDARS_VISIT, "ViewMore", currentPage);
  };

  const handleOnViewMoreSuccess = (
    addedPostIdList: number[],
    hasNext: boolean
  ) => {
    setIsViewMoreLoading(false);
    setPostIdList(postIdList.concat(addedPostIdList));
    setCurrentPage(currentPage + 1);
    setIsLastPage(!hasNext);
  };

  return (
    <React.Fragment>
      {(() => {
        if (props.isExistPosts) {
          return (
            <React.Fragment>
              <CSSTransition
                in={isLoading}
                timeout={400}
                classNames="posts-show"
                onExited={() => {
                  setIsLoaded(true);
                }}
                unmountOnExit
              >
                <PostContainerTransitionWrapper transitionClassName={"posts-show"} isAdjustTopMargin={false}>
                  <PostPlaceholder />
                </PostContainerTransitionWrapper>
              </CSSTransition>
              <CSSTransition
                in={isLoaded}
                timeout={400}
                classNames="posts-show"
                unmountOnExit
              >
                <PostContainerTransitionWrapper transitionClassName={"posts-show"} isAdjustTopMargin={false}>
                  <PostContainerList postIdList={postIdList} usePush={false} />
                    {!isLastPage && (
                      <ViewMoreButton
                        isViewMoreLoading={isViewMoreLoading}
                        fn={handleViewMore}
                      />
                    )}
                </PostContainerTransitionWrapper>
              </CSSTransition>
            </React.Fragment>
          );
        } else {
          return <NoContent />;
        }
      })()}

    </React.Fragment>
  );
};
export default UsersCalendarsVisit;
