import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action, Dispatch } from "redux";
import { RedsealState } from "../store/createStore";
import Zindex from "../components/const/Zindex";

// state -------
export interface OverlayState {
  isShow: boolean;
  message: string;
  zindex: number;
}

const OverlayInitialState: OverlayState = {
  isShow: false,
  message: "",
  zindex: Zindex.OVERLAY
};

// payload -------
export interface ShowAction extends Action {
  type: string;
  message: string;
}

export interface HideAction extends Action {
  type: string;
}

export interface UpdateAction extends Action {
  type: string;
  message: string;
}

// selector -------
export const overlaySelector = (state: RedsealState): OverlayState => {
  return state.overlay;
};

// actions & reducers -------
export const overlayModule = createSlice({
  name: "overlay",
  initialState: OverlayInitialState,
  reducers: {
    show: (state: OverlayState, action: PayloadAction<ShowAction>) => {
      state.isShow = true;
      state.message = action.payload.message;
    },
    hide: (state: OverlayState, action: PayloadAction<HideAction>) => {
      state.isShow = false;
    },
    update: (state: OverlayState, action: PayloadAction<UpdateAction>) => {
      state.message = action.payload.message;
    }
  }
});

// actions -------
export const showOverlayAction = (
  dispatch: Dispatch<Action<any>>,
  message: string
) => {
  dispatch(
    overlayModule.actions.show({
      type: "show",
      message: message
    })
  );
};

export const hideOverlayAction = (dispatch: Dispatch<Action<any>>) => {
  dispatch(
    overlayModule.actions.hide({
      type: "hide"
    })
  );
};

export const updateOverlayAction = (
  dispatch: Dispatch<Action<any>>,
  message: string
) => {
  dispatch(
    overlayModule.actions.update({
      type: "update",
      message: message
    })
  );
};
