import * as React from "react";
import styled, { css } from "styled-components";
import Color from "../../const/Color";
import {
  DateInfo,
  prevMonthDateInfo,
  nextMonthDateInfo,
  getDateInfo,
  daysInMonthInfo
} from "src/js/redseal/lib/date-util";
import { isBlank } from "src/js/redseal/lib/string-util";

interface IProps {
  selectedDate: string;
  currentYear: number;
  currentMonth: number;
  onSelectVisitedDate: (dayInfo: DateInfo) => void;
}

const DatePickerCalendar: React.FC<IProps> = (props): JSX.Element => {
  const toCalendarKey = (year: number, month: number): string =>
    `${year}${month}`;

  /**
   * カレンダの日をクリック
   * @param dayInfo
   */
  const handleClickDay = (dayInfo: DateInfo) => {
    props.onSelectVisitedDate(dayInfo);
  };

  const updateCalendar = (year: number, month: number): any => {
    const today = getDateInfo(new Date());
    const prev: DateInfo = prevMonthDateInfo(year, month);
    const next: DateInfo = nextMonthDateInfo(year, month);
    const cal = Object.create({});
    if (!cal[toCalendarKey(year, month)]) {
      cal[toCalendarKey(year, month)] = daysInMonthInfo(year, month, today);
    }
    if (!cal[toCalendarKey(prev.year, prev.month)]) {
      const prevDaysInfo = daysInMonthInfo(prev.year, prev.month, today);
      cal[toCalendarKey(prev.year, prev.month)] = prevDaysInfo;
    }
    if (!cal[toCalendarKey(next.year, next.month)]) {
      const nextDaysInfo = daysInMonthInfo(next.year, next.month, today);
      cal[toCalendarKey(next.year, next.month)] = nextDaysInfo;
    }
    return cal;
  };

  const calendar = updateCalendar(props.currentYear, props.currentMonth);

  const currentDaysInfo =
    calendar[toCalendarKey(props.currentYear, props.currentMonth)];
  const prev = prevMonthDateInfo(props.currentYear, props.currentMonth);
  const next = nextMonthDateInfo(props.currentYear, props.currentMonth);
  const prevDaysInfo = calendar[toCalendarKey(prev.year, prev.month)];
  const nextDaysInfo = calendar[toCalendarKey(next.year, next.month)];
  const beginningWday = currentDaysInfo[0].wday;
  const endWday = currentDaysInfo[currentDaysInfo.length - 1].wday;
  const days: DateInfo[] = [];

  const selectedDateInfo = isBlank(props.selectedDate)
    ? null
    : getDateInfo(new Date(Date.parse(props.selectedDate)));

  // 先月
  for (let i = 0; i < beginningWday; i++) {
    days.push(prevDaysInfo[prevDaysInfo.length - beginningWday + i]);
  }

  // 今月
  for (let i = 0; i < currentDaysInfo.length; i++) {
    days.push(currentDaysInfo[i]);
  }

  // 次月
  for (let i = 0; i < 6 - endWday; i++) {
    days.push(nextDaysInfo[i]);
  }

  const daysByWeek = [];
  for (let i = 0; i < days.length / 7; i++) {
    const oneWeek = [];
    for (let j = 0; j < 7; j++) {
      oneWeek.push(days[j + i * 7]);
    }
    daysByWeek.push(
      <tr key={i}>
        {oneWeek.map((day: DateInfo) =>
          day.isFeature ? (
            <FeatureDay key={`${day.year}${day.month}${day.day}`}>
              {day.day}
            </FeatureDay>
          ) : (
            <Day
              key={`${day.year}${day.month}${day.day}`}
              onClick={() => handleClickDay(day)}
              isToday={day.isToday}
              isSelected={
                selectedDateInfo !== null &&
                day.year === selectedDateInfo.year &&
                day.month === selectedDateInfo.month &&
                day.day === selectedDateInfo.day
              }
            >
              {day.day}
            </Day>
          )
        )}
      </tr>
    );
  }

  return (
    <CalendarTable>
      <CalendarThead>
        <tr>
          <Wday theme={{ color: Color.SUB_COLOR_RED }}>日</Wday>
          <Wday theme={{ color: Color.FONT_COLOR }}>月</Wday>
          <Wday theme={{ color: Color.FONT_COLOR }}>火</Wday>
          <Wday theme={{ color: Color.FONT_COLOR }}>水</Wday>
          <Wday theme={{ color: Color.FONT_COLOR }}>木</Wday>
          <Wday theme={{ color: Color.FONT_COLOR }}>金</Wday>
          <Wday theme={{ color: Color.SUB_COLOR_BLUE }}>土</Wday>
        </tr>
      </CalendarThead>
      <CalendarTbody>{daysByWeek}</CalendarTbody>
    </CalendarTable>
  );
};
export default DatePickerCalendar;

const CalendarTable = styled.table`
  margin-top: 8px;
  width: 100%;
`;

const CalendarThead = styled.thead``;

const Wday = styled.th`
  color: ${({ theme }) => theme.color};
  padding: 8px;
  text-align: center;
`;

const CalendarTbody = styled.tbody``;

const FeatureDay = styled.td`
  padding: 8px;
  color: ${Color.SUB_COLOR_GOLD};
  text-align: center;
`;

const Day = styled.td<{ isToday: boolean; isSelected: boolean }>`
  padding: 8px;
  ${props =>
    props.isSelected
      ? css`
          background-color: ${Color.SUB_COLOR_PINK};
          color: ${Color.FONT_COLOR_REVERSAL};
        `
      : css`
          color: ${Color.FONT_COLOR};
        `};
  cursor: pointer;
  text-align: center;
  &:hover {
    background-color: ${Color.BG_COLOR_BOX_HOVER};
  }
`;
