import * as React from "react";
import styled, { keyframes } from "styled-components";
import Color from "../../const/Color";
import { CSSTransition } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import {
  messageBoxSelector,
  MessageBoxState,
  hideMessageBoxAction,
} from "../../../modules/messageBoxModule";

interface IProps {}

const MessageBox: React.FC<IProps> = (props): JSX.Element => {
  const dispatch = useDispatch();
  const messageBoxState: MessageBoxState = useSelector(messageBoxSelector);

  const onEnteredCSSTransition = () => {
    setTimeout(() => {
      hideMessageBoxAction(dispatch);
    }, 5000);
  };

  return (
    <CSSTransition
      in={messageBoxState.isShow}
      timeout={200}
      classNames="message-box"
      unmountOnExit
      onEntered={() => onEnteredCSSTransition()}
    >
      <Wrapper theme={{ zindex: messageBoxState.zindex }}>
        <span>{messageBoxState.message}</span>
      </Wrapper>
    </CSSTransition>
  );
};
export default MessageBox;

const Fadeout = keyframes`
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zindex};
  background-color: rgba(0, 0, 0, 0.8);
  color: ${Color.FONT_COLOR_REVERSAL};
  box-shadow: 0px 6px 6px -6px rgba(0, 0, 0, 0.29);
  -webkit-box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0px 6px 6px -6px rgba(0, 0, 0, 0.29);

  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;

  margin: auto;
  padding: 16px;
  width: 60%;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
  bottom: 80px;
  left: 0;
  right: 0;

  text-align: center;
  min-height: 32px;
  min-width: 200px;

  transition: opacity 500ms ease-in-out;
  &.message-box-enter {
    opacity: 0;
  }
  &.message-box-enter-active {
    opacity: 1;
    transition: all 300ms ease-in-out;
  }
  &.message-box-enter-done {
    opacity: 1;
    animation: ${Fadeout} 1s linear 3s 1;
    animation-fill-mode: forwards;
  }
  &.message-box-exit {
    display: none;
  }
`;
