export const TRACKEVENT_CATEGORY = {
  HEADER: "Header", // global header
  SPOT: "Spot", // spots
  POST: "Post", // posts
  FOLLOW: "Follow", // follows

  // modals
  SEARCH_MODAL: "SearchModal",
  POST_MODAL: "PostModal",
  COMMENT_MODAL: "CommentModal",
  EDIT_OPTION_MODAL: "EditOptionModal",
  USER_CONTROL_MODAL: "UserControlModal",

  // pages
  USERS_MY: "UsersMy",
  USERS_MY_PROFILES_EDIT: "UsersMyProfilesEdit",
  USERS_PROFILES_SHOW: "UsersProfilesShow",
  USERS_SPOTS_SHOW: "UsersSpotsShow",
  USERS_CALENDARS_VISIT: "UsersCalendarsVisit",
  POSTS_SHOW: "PostsShow",
  POSTS_RECENT: "PostsRecent",
  POSTS_POPULAR: "PostsPopular",
  POSTS_POPULAR_GOSHUIN: "PostsPopularGoshuin",
  SPOTS_SHOW_TYPE: "SpotsShowType",
  TAGS_SHOW: "TagsShow",
};

export const gaTrackEvent = (
  category: string,
  action: string,
  value: number = null
) => {
  //if (process.env.NODE_ENV !== "production") {
  //  return;
  //}
  //ga("send", "event", category, action, `${location.href}`, value, {
  //  nonInteraction: true,
  //});
};

export const gaPageview = (title: string, url: string) => {
  //if (process.env.NODE_ENV !== "production") {
  //  return;
  //}
  //ga("send", {
  //  hitType: "pageview",
  //  title: title,
  //  page: url,
  //});
};
