import {
  getPost,
  pushPost,
  usecasePostPopular,
  usecasePostRecent,
} from "../../domain/usecases/posts";
import { csrfToken } from "../../lib/dom-helper";
import { evaluateContent } from "../../domain/usecases/evaluations";
import { isEmpty } from "../../lib/string-util";
import Message from "../components/const/Message";
import { RedsealState } from "../store/createStore";
import { createSlice, Action, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import EvaluationConst from "../components/const/EvaluationConst";
import StatusConst from "../components/const/StatusConst";
import { postContainerDispatcher } from "../../lib/module-util";

// state -------
export interface PostsState {
  posts: IPost[];
}

const PostsStateInitialState: PostsState = {
  posts: [],
};

// payload -------
export interface SetPostsAction extends Action {
  type: string;
  posts: IPost[];
}

export interface RemovePostsAction extends Action {
  type: string;
  posts: IPost[];
}

export interface UpdatePostEvaluationNumAction extends Action {
  type: string;
  evaluation: IEvaluation;
}

export interface UpdatePostCommentNumAction extends Action {
  type: string;
  comment: IComment;
}

// selector -------
export const postsSelector = (state: RedsealState): PostsState => {
  return state.posts;
};

// reducers -------
export const postsModule = createSlice({
  name: "posts",
  initialState: PostsStateInitialState,
  reducers: {
    setPostsAction: (
      state: PostsState,
      action: PayloadAction<SetPostsAction>
    ) => {
      state.posts = mergeState(action.payload.posts, state);
    },
    removePostsAction: (
      state: PostsState,
      action: PayloadAction<RemovePostsAction>
    ) => {
      state.posts = removeState(action.payload.posts, state);
    },
    updatePostEvaluationNumAction: (
      state: PostsState,
      action: PayloadAction<UpdatePostEvaluationNumAction>
    ) => {
      state.posts = updatePostGoodNum(action.payload.evaluation, state);
    },
    updatePostCommentNumAction: (
      state: PostsState,
      action: PayloadAction<UpdatePostCommentNumAction>
    ) => {
      state.posts = updatePostCommentNum(action.payload.comment, state);
    },
  },
});

// actions -------

export const postShowAction = (
  dispatch: Dispatch<Action<any>>,
  postId: number,
  onSuccess: () => void,
  onError: () => void
) => {
  getPost(postId)
    .then((response) => {
      postContainerDispatcher(dispatch, response);
      onSuccess();
    })
    .catch((e) => {
      console.log(e);
      onError();
    });
};

/**
 * 新着の投稿を取得する.
 * @param dispatch
 * @param page
 * @param onSuccess
 * @param onError
 */
export const postRecentAction = (
  dispatch: Dispatch<Action<any>>,
  page: number,
  onSuccess: (postIdList: number[], hasNext: boolean) => void,
  onError: () => void
) => {
  usecasePostRecent(page)
    .then((response) => {
      postContainerDispatcher(dispatch, response);
      onSuccess(
        response.posts.map((post: IPost) => {
          return post.post_id;
        }),
        response.paginator.has_next
      );
    })
    .catch((e) => {
      console.log(e);
      onError();
    });
};

/**
 * 人気の投稿を取得する.
 * @param dispatch
 * @param postType
 * @param page
 * @param onSuccess
 * @param onError
 */
export const postPopularAction = (
  dispatch: Dispatch<Action<any>>,
  postType: string,
  page: number,
  onSuccess: (postIdList: number[], hasNext: boolean) => void,
  onError: () => void
) => {
  usecasePostPopular(postType, page)
    .then((response) => {
      postContainerDispatcher(dispatch, response);
      onSuccess(
        response.posts.map((post: IPost) => {
          return post.post_id;
        }),
        response.paginator.has_next
      );
    })
    .catch((e) => {
      console.log(e);
      onError();
    });
};

/**
 * コメントに「いいね」するアクション.
 * @param dispatch
 * @param postId
 * @param onSuccess
 * @param onError
 */
export const evaluateAction = (
  dispatch: Dispatch<Action<any>>,
  postId: number,
  onSuccess: (response: IEvaluation) => void,
  onError: (message: string) => void
) => {
  const formData = new FormData();
  formData.append("authenticity_token", csrfToken());
  formData.append("evaluation[evaluable_id]", `${postId}`);
  formData.append("evaluation[evaluable_type]", EvaluationConst.TYPE_POST);

  evaluateContent(formData)
    .then((response) => {
      onSuccess(response);

      dispatch(
        postsModule.actions.updatePostEvaluationNumAction({
          type: "updatePostEvaluationAction",
          evaluation: response,
        })
      );
    })
    .catch((e) => {
      console.log(e);
      onError(isEmpty(e.message) ? Message.UNEXPECTED_ERROR : e.message);
    });
};

export const pushAction = (postId: number, onSuccess: () => void) => {
  pushPost(postId).then((response) => {
    onSuccess();
  });
};

// utils -------

const mergeState = (targets: IPost[], state: PostsState): IPost[] => {
  const newState: IPost[] = [...state.posts];
  for (let i = 0; i < targets.length; i++) {
    if (!updateState(targets[i], newState)) {
      newState.push(targets[i]);
    }
  }
  return newState;
};

const removeState = (targets: IPost[], state: PostsState): IPost[] => {
  const newState: IPost[] = [...state.posts];
  for (let i = 0; i < targets.length; i++) {
    for (let j = 0; j < newState.length; j++) {
      if (targets[i].post_id === newState[j].post_id) {
        newState.splice(j, 1);
        break;
      }
    }
  }
  return newState;
};

const updateState = (target: IPost, source: IPost[]): boolean => {
  for (let i = 0; i < source.length; i++) {
    if (
      target.post_id === source[i].post_id &&
      target.updated_at > source[i].updated_at
    ) {
      source[i] = target;
      return true;
    }
  }
  return false;
};

/**
 * 投稿のいいね数を更新する
 * @param target
 * @param state
 */
const updatePostGoodNum = (target: IEvaluation, state: PostsState): IPost[] => {
  const newState: IPost[] = [...state.posts];
  for (let i = 0; i < newState.length; i++) {
    if (
      newState[i].post_id === target.evaluable_id &&
      target.evaluable_type === EvaluationConst.TYPE_POST
    ) {
      newState[i].post_eval_good = target.evaluate_result;
      break;
    }
  }
  return newState;
};

/**
 * 投稿についているコメント数を更新する
 * @param target
 * @param state
 */
const updatePostCommentNum = (target: IComment, state: PostsState): IPost[] => {
  const newState: IPost[] = [...state.posts];
  for (let i = 0; i < newState.length; i++) {
    if (newState[i].post_id === target.post_id) {
      if (target.status === StatusConst.PUBLIC) {
        newState[i].post_eval_comment = newState[i].post_eval_comment + 1;
      } else if (target.status === StatusConst.DELETED) {
        newState[i].post_eval_comment =
          newState[i].post_eval_comment <= 0
            ? 0
            : newState[i].post_eval_comment - 1;
      }
      break;
    }
  }
  return newState;
};

export const findPost = (id: number, state: PostsState): IPost | null => {
  for (let i = 0; i < state.posts.length; i++) {
    if (state.posts[i].post_id === id) {
      return state.posts[i];
    }
  }
  return null;
};
