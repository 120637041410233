/**
 * nullかどうかを返す
 * @param obj
 */
export const isNull = (obj: any | null | undefined): boolean => {
  return obj === null;
};

/**
 * undefinedかどうかを返す
 * @param obj
 */
export const isUndefined = (obj: any | null | undefined): boolean => {
  return obj === undefined;
};

/**
 * null もしくは undefinedかどうかを返す
 * @param obj
 */
export const isNullOrUndefined = (obj: any | null | undefined): boolean => {
  return obj == null;
};
