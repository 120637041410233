import * as React from "react";
import PostTile from "../PostTile";
import {
  postsSelector,
  PostsState,
  findPost,
} from "../../../modules/postsModule";
import { useSelector } from "react-redux";
import {
  spotsSelector,
  SpotsState,
  findSpot,
} from "../../../modules/spotsModule";
import styled from "styled-components";

interface IProps {
  postIdList: number[];
}

const PostTileList: React.FC<IProps> = (props): JSX.Element => {
  const postsState: PostsState = useSelector(postsSelector);
  const spotsState: SpotsState = useSelector(spotsSelector);

  const renderPosts = (): JSX.Element[] => {
    const posts = [];
    for (let i = 0; i < props.postIdList.length; i++) {
      const post = findPost(props.postIdList[i], postsState);
      if (post == null) {
        continue;
      }
      const spot = findSpot(post.spot_id, spotsState);
      posts.push(
        <PostTile post={post} spot={spot} key={props.postIdList[i]} />
      );
    }
    return posts;
  };

  return <Wrapper>{renderPosts()}</Wrapper>;
};
export default PostTileList;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;
