import { isNullOrUndefined } from "./object-util";

/**
 * null, undefined, 空文字列かどうかを返す
 * @param str
 */
export const isEmpty = (str: string | null | undefined): boolean => {
  if (isNullOrUndefined(str)) {
    return true;
  }
  return str === "";
};

/**
 * null, undefined, 空文字列, 空白文字かどうかを返す
 * @param str
 */
export const isBlank = (str: string): boolean => {
  if (isEmpty(str)) {
    return true;
  }
  return str.match(/[^\s　]/) === null;
};

/**
 * 文字列がEmailアドレスかどうかを返す。
 * @param str
 */
export const isEmail = (str: string): boolean => {
  return (
    str.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    ) != null
  );
};

export const isNumericOnly = (str: string): boolean => {
  return str.match(/^[0-9]+$/) === null;
}

export const isAvailableSlugChar = (str: string): boolean => {
  return str.match(/^[0-9a-zA-Z\-_]+$/) !== null;
}

export const zeroPadding = (num: number, length: number): string => {
  return ("0000000000" + num).slice(-length);
};

export const numberShorten = (num: number): string => {
  if (num > 1000) {
    return `${Math.floor(num / 100) / 10}K`;
  }
  return `${num}`;
};

export const fulltrim = (str: string): string => {
  if (isEmpty(str)) {
    return "";
  }
  return str.replace(/\s+/g, "");
};

export const truncate = (str: string, len: number): string => {
  if (isEmpty(str)) {
    return "";
  }
  return str.length <= len ? str : str.substr(0, len) + "...";
};

export const delimited = (num: number): string => {
  if (num === null) {
    return "0";
  }
  return num.toLocaleString();
};
