import Prefectures, {
  OVERALL_PREFECTURE_ID,
  OVERALL_PREFECTURE_SLUG,
} from "../presentation/components/const/Prefectures";

export const isPrefSlug = (prefSlug: string): boolean => {
  for (let i = 0; i < Prefectures.length; i++) {
    if (Prefectures[i].slug === prefSlug) {
      return true;
    }
  }
  return false;
};

export const getPrefSlugById = (id: number): string => {
  for (let i = 0; i < Prefectures.length; i++) {
    if (Prefectures[i].id === id) {
      return Prefectures[i].slug;
    }
  }
  return OVERALL_PREFECTURE_SLUG;
};

export const getPrefIdBySlug = (slug: string): number => {
  for (let i = 0; i < Prefectures.length; i++) {
    if (Prefectures[i].slug === slug) {
      return Prefectures[i].id;
    }
  }
  return OVERALL_PREFECTURE_ID;
};
