export const isiPhone = (): boolean => {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.indexOf("iphone") > -1;
};

export const isiPad = (): boolean => {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.indexOf("ipad") > -1;
};

export const isiPod = (): boolean => {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.indexOf("ipod") > -1;
};

export const isiOS = (): boolean => {
  return isiPhone() || isiPad() || isiPod();
};

export const isIE = (): boolean => {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.indexOf("msie") > -1 || ua.indexOf("trident") > -1;
};

export const isBot = (): boolean => {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.indexOf("Googlebot") > -1;
};
