import { RedsealState } from "../store/createStore";
import { createSlice, PayloadAction, Action, Dispatch } from "@reduxjs/toolkit";
import { destroyComment, createComment } from "../../domain/usecases/comments";
import { csrfToken } from "../../lib/dom-helper";
import { postsModule } from "./postsModule";
import { evaluateContent } from "../../domain/usecases/evaluations";
import EvaluationConst from "../components/const/EvaluationConst";
import Message from "../components/const/Message";
import { isEmpty } from "../../lib/string-util";

// state -------
export interface CommentsState {
  comments: IComment[];
}

const CommentsStateInitialState: CommentsState = {
  comments: [],
};

// payload -------
export interface SetCommentsAction extends Action {
  type: string;
  comments: IComment[];
}

export interface RemoveCommentsAction extends Action {
  type: string;
  comments: IComment[];
}

export interface UpdateCommentEvaluationNumAction extends Action {
  type: string;
  evaluation: IEvaluation;
}

// selector -------
export const commentsSelector = (state: RedsealState): CommentsState => {
  return state.comments;
};

// reducers -------
export const commentsModule = createSlice({
  name: "comments",
  initialState: CommentsStateInitialState,
  reducers: {
    setCommentsAction: (
      state: CommentsState,
      action: PayloadAction<SetCommentsAction>
    ) => {
      state.comments = mergeState(action.payload.comments, state);
    },
    removeCommentsAction: (
      state: CommentsState,
      action: PayloadAction<RemoveCommentsAction>
    ) => {
      state.comments = removeState(action.payload.comments, state);
    },
    updateCommentEvaluationNumAction: (
      state: CommentsState,
      action: PayloadAction<UpdateCommentEvaluationNumAction>
    ) => {
      state.comments = updateCommentGoodNum(action.payload.evaluation, state);
    },
  },
});

// actions -------

/**
 * 取得したコメントをStateに保存するアクション.
 * @param dispatch
 * @param comments
 */
export const setCommentsAction = (
  dispatch: Dispatch<Action<any>>,
  comments: IComment[]
) => {
  dispatch(
    commentsModule.actions.setCommentsAction({
      type: "setCommentsAction",
      comments: comments,
    })
  );
};

/**
 * コメント投稿アクション
 * @param dispatch 
 * @param postId 
 * @param description 
 * @param onSuccess 
 * @param onError 
 */
export const createAction = (
  dispatch: Dispatch<Action<any>>,
  postId: number,
  description: string,
  onSuccess: (response: IComment) => void,
  onError: () => void
) => {
  const formData = new FormData();
  formData.append("authenticity_token", csrfToken());
  formData.append("comment[post_id]", `${postId}`);
  formData.append("comment[description]", `${description}`);

  createComment(formData)
    .then((response) => {
      onSuccess(response);
      dispatch(
        commentsModule.actions.setCommentsAction({
          type: "setCommentsAction",
          comments: [response],
        })
      );
      dispatch(
        postsModule.actions.updatePostCommentNumAction({
          type: "updatePostCommentNumAction",
          comment: response,
        })
      );
    })
    .catch((e) => {
      console.log(e);
      onError();
    });
};

/**
 * 指定のコメントを削除するアクション.
 * @param dispatch
 * @param commentId
 * @param onSuccess
 * @param onError
 */
export const destroyAction = (
  dispatch: Dispatch<Action<any>>,
  commentId: number,
  onSuccess: (response: IComment) => void,
  onError: () => void
) => {
  destroyComment(commentId)
    .then((response) => {
      onSuccess(response);
      dispatch(
        commentsModule.actions.removeCommentsAction({
          type: "removeCommentsAction",
          comments: [response],
        })
      );
      dispatch(
        postsModule.actions.updatePostCommentNumAction({
          type: "updatePostCommentNumAction",
          comment: response,
        })
      );
    })
    .catch((e) => {
      console.log(e);
      onError();
    });
};

/**
 * コメントに「いいね」をするアクション.
 * @param commentId
 * @param onSuccess
 * @param onError
 */
export const evaluateAction = (
  dispatch: Dispatch<Action<any>>,
  commentId: number,
  onSuccess: (response: IEvaluation) => void,
  onError: (message: string) => void
) => {
  const formData = new FormData();
  formData.append("authenticity_token", csrfToken());
  formData.append("evaluation[evaluable_id]", `${commentId}`);
  formData.append("evaluation[evaluable_type]", EvaluationConst.TYPE_COMMENT);

  evaluateContent(formData)
    .then((response) => {
      onSuccess(response);

      dispatch(
        commentsModule.actions.updateCommentEvaluationNumAction({
          type: "updateCommentEvaluationAction",
          evaluation: response,
        })
      );
    })
    .catch((e) => {
      console.log(e);
      onError(isEmpty(e.message) ? Message.UNEXPECTED_ERROR : e.message);
    });
};

// utils -------

const mergeState = (targets: IComment[], state: CommentsState): IComment[] => {
  const newState: IComment[] = [...state.comments];
  for (let i = 0; i < targets.length; i++) {
    if (!updateState(targets[i], newState)) {
      newState.push(targets[i]);
    }
  }
  return newState;
};

const removeState = (targets: IComment[], state: CommentsState): IComment[] => {
  const newState: IComment[] = [...state.comments];
  for (let i = 0; i < targets.length; i++) {
    for (let j = 0; j < newState.length; j++) {
      if (targets[i].comment_id === newState[j].comment_id) {
        newState.splice(j, 1);
        break;
      }
    }
  }
  return newState;
};

const updateState = (target: IComment, source: IComment[]): boolean => {
  for (let i = 0; i < source.length; i++) {
    if (
      target.comment_id === source[i].comment_id &&
      target.updated_at > source[i].updated_at
    ) {
      source[i] = target;
      return true;
    }
  }
  return false;
};

export const findComment = (
  id: number,
  state: CommentsState
): IComment | null => {
  for (let i = 0; i < state.comments.length; i++) {
    if (state.comments[i].comment_id === id) {
      return state.comments[i];
    }
  }
  return null;
};

/**
 * 指定の post_id のコメントを comment_id の昇順で返す.
 * @param id
 * @param state
 */
export const findCommentsByPostId = (
  id: number,
  state: CommentsState
): IComment[] => {
  const comments: IComment[] = [];
  for (let i = 0; i < state.comments.length; i++) {
    if (state.comments[i].post_id === id) {
      comments.push(state.comments[i]);
    }
  }
  if (comments.length === 0) {
    return comments;
  }
  comments.sort(
    (a: IComment, b: IComment): number => {
      if (a.comment_id < b.comment_id) {
        return -1;
      }
      if (a.comment_id > b.comment_id) {
        return 1;
      }
      return 0;
    }
  );
  return comments;
};

/**
 * コメントのいいね数を更新する
 * @param target
 * @param state
 */
const updateCommentGoodNum = (target: IEvaluation, state: CommentsState): IComment[] => {
  const newState: IComment[] = [...state.comments];
  for (let i = 0; i < newState.length; i++) {
    if (
      newState[i].comment_id === target.evaluable_id &&
      target.evaluable_type === EvaluationConst.TYPE_COMMENT
    ) {
      newState[i].good_num = target.evaluate_result;
      break;
    }
  }
  return newState;
};
