import * as React from "react";
import LazyLoad from "react-lazyload";
import styled from "styled-components";
import Color from "../../const/Color";
import { OBJECT_FIT } from "src/js/redseal/lib/image-util";

interface IProps {
  src: string;
  height: string | null;
  width: string | null;
  alt: string;
  objectFit: OBJECT_FIT;
  noImage: string | null;
}

const LazyLoadImage: React.FC<IProps> = (props: IProps): JSX.Element => {
  return (
    <LazyLoad hight={props.height} once>
      <Img
        src={props.src}
        alt={props.alt}
        width={props.width}
        height={props.height}
        onError={(e) => {
          if (props.noImage !== null) {
            e.currentTarget.src = props.noImage;
          }
        }}
        theme={{ objectFit: props.objectFit }}
      />
    </LazyLoad>
  );
};
export default LazyLoadImage;

const Img = styled.img`
  background: ${Color.LOADING_PLACE_HOLDER_COLOR};
  object-fit: ${({ theme }) => theme.objectFit};
`;
