import * as React from "react";
import styled from "styled-components";
import FontAwesome from "../../atoms/FontAwesome/FontAwesome";
import Color from "../../const/Color";
import { fontsize } from "../../const/Mixin";
import { useDispatch } from "react-redux";
import { showMessageBoxAction } from "../../../modules/messageBoxModule";
import { isSignedIn } from "src/js/redseal/lib/auth-util";
import { wishAction } from "../../../modules/spotModule";
import { useState, useEffect } from "react";
import Message from "../../const/Message";
import {
  gaTrackEvent,
  TRACKEVENT_CATEGORY,
} from "src/js/redseal/lib/analytics-util";

interface IProps {
  checked: boolean;
  spotId: number;
}

const WishButton: React.FC<IProps> = (props: IProps): JSX.Element => {
  const dispatch = useDispatch();

  // Local state
  const [isWish, setIsWish] = useState(props.checked);

  const handleClickWish = () => {
    if (isSignedIn()) {
      if (isWish) {
        if (!window.confirm("行きたいを取り消します。よろしいですか？")) {
          return;
        }
        gaTrackEvent(TRACKEVENT_CATEGORY.SPOT, "Unwish", props.spotId);
      } else {
        if (!window.confirm("行きたい登録します。よろしいですか？")) {
          return;
        }
        gaTrackEvent(TRACKEVENT_CATEGORY.SPOT, "Wish", props.spotId);
      }
      wishAction(props.spotId, handleOnWishSuccess, handleOnWishError);
    } else {
      showMessageBoxAction(
        dispatch,
        "行きたいを登録するにはログインする必要があります。"
      );
      gaTrackEvent(TRACKEVENT_CATEGORY.SPOT, "UnsignedWish", props.spotId);
    }
  };

  const handleOnWishSuccess = (response: ISpotWish) => {
    const message =
      response.wish_status === 0
        ? "行きたいを取り消しました。"
        : "行きたい登録しました。";
    showMessageBoxAction(dispatch, message);
    setIsWish(response.wish_status === 0 ? false : true);
  };

  const handleOnWishError = () => {
    showMessageBoxAction(dispatch, Message.UNEXPECTED_ERROR);
  };

  useEffect(() => {}, [isWish]);

  return (
    <Wrapper onClick={() => handleClickWish()}>
      <FontAwesome
        className={`fa ${isWish ? "fa-bookmark" : "fa-bookmark-o"}`}
      />
      <Text>行きたい</Text>
    </Wrapper>
  );
};
export default WishButton;

const Wrapper = styled.div`
  margin: 0 8px;
  background-color: ${Color.SUB_COLOR_PURPLE};
  color: ${Color.FONT_COLOR_REVERSAL};
  width: 48px;
  height: 48px;
  border-radius: 50%;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span.fa {
    color: white;
  }

  &:hover {
    transition: transform 0.2s ease-out;
    transform: scale(1.1);
  }
`;

const Text = styled.span`
  ${fontsize(8)}
`;
