export default class RsGeolocation {
  static readonly API_UNAVAILABLE = 0;
  static readonly PERMISSION_DENIED = 1;
  static readonly POSITION_UNAVAILABLE = 2;
  static readonly TIMEOUT = 3;

  static getCurrentLocation(): Promise<any> {
    if (navigator.geolocation) {
      return new Promise(
        (
          resolve: (value?: Position) => void,
          reject: (reason?: PositionError) => void
        ) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        }
      );
    } else {
      return new Promise((resolve, reject) => {
        reject(this.API_UNAVAILABLE);
      });
    }
  }

  static isPositionError(x: any): x is PositionError {
    return x !== null && typeof x === "object" && typeof x.code === "number";
  }

  static handlingGeoErrorMessage(errorCode: number): string {
    let message = "予期せぬエラーが発生しました。";
    switch (errorCode) {
      case this.API_UNAVAILABLE:
        message = "お使いの端末では位置を判定できません。";
        break;
      case this.PERMISSION_DENIED:
        message = "位置情報の利用が許可されていません。";
        break;
      case this.POSITION_UNAVAILABLE:
        message = "端末の位置が判定できませんでした。";
        break;
      case this.TIMEOUT:
        message = "タイムアウトしました。";
        break;
    }
    return message;
  }
}
