import * as React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import PostContainer from "../../molecules/PostContainer";
import { useEffect, useState } from "react";
import {
  postShowAction,
  PostsState,
  postsSelector,
  findPost,
} from "../../../modules/postsModule";
import { showMessageBoxAction } from "../../../modules/messageBoxModule";
import PostPlaceholder from "../../molecules/PostContainer/PostPlaceholder";
import { CSSTransition } from "react-transition-group";
import {
  SpotsState,
  spotsSelector,
  findSpot,
} from "../../../modules/spotsModule";
import FontAwesome from "../../atoms/FontAwesome";
import Link from "../../atoms/Link";
import Color from "../../const/Color";
import { normalBox } from "../../const/Mixin";
import PostContainerTransitionWrapper from "../../atoms/PostContainerTransitionWrapper";

interface IProps {
  postId: number;
}

const PostsShow: React.FC<IProps> = (props: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const postsState: PostsState = useSelector(postsSelector);
  const spotsState: SpotsState = useSelector(spotsSelector);

  // Local state
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    didMount(props.postId);
  }, []);

  const didMount = (postId: number) => {
    postShowAction(dispatch, postId, handleOnSuccess, handleOnError);
  };

  const handleOnSuccess = () => {
    setIsLoading(false);
  };

  const handleOnError = () => {
    showMessageBoxAction(dispatch, "予期せぬエラーが発生しました。");
  };

  return (
    <React.Fragment>
      <CSSTransition
        in={isLoading}
        timeout={400}
        classNames="posts-show"
        onExited={() => {
          setIsLoaded(true);
        }}
        unmountOnExit
      >
        <PostContainerTransitionWrapper transitionClassName={"posts-show"} isAdjustTopMargin={true}>
          <PostPlaceholder />
        </PostContainerTransitionWrapper>
      </CSSTransition>
      <CSSTransition
        in={isLoaded}
        timeout={400}
        classNames="posts-show"
        unmountOnExit
      >
        <PostContainerTransitionWrapper transitionClassName={"posts-show"} isAdjustTopMargin={true}>
          <PostContainer postId={props.postId} usePush={false} />
          {(() => {
            const post = findPost(props.postId, postsState);
            if (post !== null) {
              const spot = findSpot(post.spot_id, spotsState);
              if (spot !== null) {
                return (
                  <SpotLink>
                    <Link url={`/spots/${spot.spot_id}`}>
                      <div>
                        {spot.name}の情報をもっと見る
                        <FontAwesome className="fa fa-chevron-circle-right" />
                      </div>
                    </Link>
                  </SpotLink>
                );
              } else {
                return null;
              }
            } else {
              return null;
            }
          })()}
        </PostContainerTransitionWrapper>
      </CSSTransition>
    </React.Fragment>
  );
};

export default PostsShow;

const SpotLink = styled.div`
  a {
    color: ${Color.FONT_COLOR};
    text-decoration: none;
  }
  a:link {
    color: ${Color.FONT_COLOR};
    text-decoration: none;
  }
  a:hover {
    color: ${Color.FONT_COLOR};
    text-decoration: none;
  }
  a:visited {
    color: ${Color.FONT_COLOR};
    text-decoration: none;
  }

  div {
    ${normalBox}
    text-align: center;
    padding: 8px 16px;
    cursor: pointer;

    span {
      margin-left: 8px;
    }

    &:hover {
      background: ${Color.BG_COLOR_BOX_HOVER};
    }
    @media screen and (max-width: 768px) {
      margin: 0 8px;
    }
  }
`;
