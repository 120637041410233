export const OVERALL_PREFECTURE_ID = 0;
export const OVERALL_PREFECTURE_SLUG = "all";

export default [
  { id: 1, name: "北海道", slug: "hokkaido" },
  { id: 2, name: "青森県", slug: "aomori" },
  { id: 3, name: "岩手県", slug: "iwate" },
  { id: 4, name: "宮城県", slug: "miyagi" },
  { id: 5, name: "秋田県", slug: "akita" },
  { id: 6, name: "山形県", slug: "yamagata" },
  { id: 7, name: "福島県", slug: "fukushima" },
  { id: 8, name: "茨城県", slug: "ibaraki" },
  { id: 9, name: "栃木県", slug: "tochigi" },
  { id: 10, name: "群馬県", slug: "gunma" },
  { id: 11, name: "埼玉県", slug: "saitama" },
  { id: 12, name: "千葉県", slug: "chiba" },
  { id: 13, name: "東京都", slug: "tokyo" },
  { id: 14, name: "神奈川県", slug: "kanagawa" },
  { id: 15, name: "新潟県", slug: "niigata" },
  { id: 16, name: "富山県", slug: "toyama" },
  { id: 17, name: "石川県", slug: "ishikawa" },
  { id: 18, name: "福井県", slug: "fukui" },
  { id: 19, name: "山梨県", slug: "yamanashi" },
  { id: 20, name: "長野県", slug: "nagano" },
  { id: 21, name: "岐阜県", slug: "gifu" },
  { id: 22, name: "静岡県", slug: "shizuoka" },
  { id: 23, name: "愛知県", slug: "aichi" },
  { id: 24, name: "三重県", slug: "mie" },
  { id: 25, name: "滋賀県", slug: "shiga" },
  { id: 26, name: "京都府", slug: "kyoto" },
  { id: 27, name: "大阪府", slug: "osaka" },
  { id: 28, name: "兵庫県", slug: "hyogo" },
  { id: 29, name: "奈良県", slug: "nara" },
  { id: 30, name: "和歌山県", slug: "wakayama" },
  { id: 31, name: "鳥取県", slug: "tottori" },
  { id: 32, name: "島根県", slug: "shimane" },
  { id: 33, name: "岡山県", slug: "okayama" },
  { id: 34, name: "広島県", slug: "hiroshima" },
  { id: 35, name: "山口県", slug: "yamaguchi" },
  { id: 36, name: "徳島県", slug: "tokushima" },
  { id: 37, name: "香川県", slug: "kagawa" },
  { id: 38, name: "愛媛県", slug: "ehime" },
  { id: 39, name: "高知県", slug: "kochi" },
  { id: 40, name: "福岡県", slug: "fukuoka" },
  { id: 41, name: "佐賀県", slug: "saga" },
  { id: 42, name: "長崎県", slug: "nagasaki" },
  { id: 43, name: "熊本県", slug: "kumamoto" },
  { id: 44, name: "大分県", slug: "oita" },
  { id: 45, name: "宮崎県", slug: "miyazaki" },
  { id: 46, name: "鹿児島県", slug: "kagoshima" },
  { id: 47, name: "沖縄県", slug: "okinawa" },
  { id: 48, name: "海外（ハワイ・台湾）", slug: "overseas" },
];
