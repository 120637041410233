import * as React from "react";
import styled from "styled-components";
import { normalBox, fontsize } from "../../const/Mixin";
import Loading from "../../atoms/Loading";
import Color from "../../const/Color";
import FontAwesome from "../../atoms/FontAwesome";

const PostPlaceholder: React.FC = (): JSX.Element => {
  return (
    <Wrapper>
      <PostHeader>
        <UserIcon />
        <UserSpotInfo>
          <Paragraph className="w20" />
          <Paragraph className="w50 h14" />
          <Paragraph className="w50 h14" />
        </UserSpotInfo>
      </PostHeader>
      <PostPicture>
        <Loading />
      </PostPicture>
      <PostTypeScores>
        <PostType />
        <PostScoreItem>
          <FontAwesome className={"fa fa-heart"} />
        </PostScoreItem>
        <PostScoreItem>
          <FontAwesome className={"fa fa-comment"} />
        </PostScoreItem>
        <PostScoreItem>
          <FontAwesome className={"fa fa-signal"} />
        </PostScoreItem>
      </PostTypeScores>
      <PostDesc>
        <Paragraph className="w100" />
        <Paragraph className="w80" />
        <Paragraph className="w60" />
      </PostDesc>
    </Wrapper>
  );
};
export default PostPlaceholder;

const Wrapper = styled.div`
  ${normalBox()};
`;

const PostHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px;
`;

const UserIcon = styled.div`
  height: 40px;
  min-height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 20px;
  background: ${Color.LOADING_PLACE_HOLDER_COLOR};
`;

const UserSpotInfo = styled.div`
  padding-left: 16px;
  width: 100%;
`;

const PostPicture = styled.div`
  background: ${Color.LOADING_PLACE_HOLDER_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${Math.round((638 * 5) / 8)}px;
  @media screen and (max-width: 768px) {
    height: ${Math.round((window.innerWidth * 5) / 8)}px;
  }
`;

const PostTypeScores = styled.div`
  display: flex;
  margin-top: 8px;
  padding: 0 16px;
  ${fontsize(14)}
`;

const PostType = styled.div`
  width: 56px;
  height: 21px;
  border-radius: 10px;
  background: ${Color.LOADING_PLACE_HOLDER_COLOR};
  margin-right: 16px;
`;

const PostScoreItem = styled.div`
  margin-right: 32px;
  span {
    color: ${Color.LOADING_PLACE_HOLDER_COLOR} !important;
  }
`;

const PostDesc = styled.div`
  padding: 16px;
`;

const Paragraph = styled.div`
  background: linear-gradient(
    to right,
    ${Color.LOADING_PLACE_HOLDER_COLOR} 50%,
    #fff
  );
  border-radius: 3px;
  height: 16px;
  margin-bottom: 6px;
  &.h14 {
    height: 14px;
  }
  &.w100 {
    width: 100%;
  }
  &.w80 {
    width: 80%;
  }
  &.w60 {
    width: 60%;
  }
  &.w50 {
    width: 50%;
  }
  &.w20 {
    width: 20%;
  }
`;
