import * as React from "react";
import styled from "styled-components";
import FontAwesome from "../../atoms/FontAwesome/FontAwesome";
import Color from "../../const/Color";
import { fontsize } from "../../const/Mixin";
import { useDispatch } from "react-redux";
import {
  openPostModalAction,
  selectSpotAction,
} from "../../../modules/postFormModule";
import PostConst from "../../const/PostConst";
import { showMessageBoxAction } from "../../../modules/messageBoxModule";
import { isSignedIn } from "src/js/redseal/lib/auth-util";
import {
  gaTrackEvent,
  TRACKEVENT_CATEGORY,
} from "src/js/redseal/lib/analytics-util";

interface IProps {
  spotId: number | null;
  spotName: string;
}

const PostMultiButton: React.FC<IProps> = (props: IProps): JSX.Element => {
  const dispatch = useDispatch();

  const handleClickPost = () => {
    if (isSignedIn()) {
      selectSpotAction(dispatch, props.spotId, props.spotName);
      openPostModalAction(dispatch, PostConst.POST_MODE_CREATE);
      gaTrackEvent(TRACKEVENT_CATEGORY.POST, "OpenPostModal");
    } else {
      showMessageBoxAction(
        dispatch,
        "投稿するにはログインする必要があります。"
      );
      gaTrackEvent(TRACKEVENT_CATEGORY.POST, "UnsignedOpenPostModal");
    }
  };

  return (
    <Wrapper onClick={() => handleClickPost()}>
      <FontAwesome className="fa fa-camera" />
      <Text>投稿</Text>
    </Wrapper>
  );
};
export default PostMultiButton;

const Wrapper = styled.div`
  margin: 0 8px;
  background-color: ${Color.SUB_COLOR_RED};
  color: ${Color.FONT_COLOR_REVERSAL};
  width: 48px;
  height: 48px;
  border-radius: 50%;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span.fa {
    color: white;
  }

  &:hover {
    transition: transform 0.2s ease-out;
    transform: scale(1.1);
  }
`;

const Text = styled.span`
  ${fontsize(10)}
`;
