import * as React from "react";
import styled from "styled-components";

const Loading: React.FC = (): JSX.Element => (
  <Wrapper>
    <i className="fa fa-spinner fa-pulse" />
  </Wrapper>
);
export default Loading;

const Wrapper = styled.div`
  text-align: center;
`;
