import * as React from "react";
import styled from "styled-components";
import Color from "../../const/Color";
import { useDispatch, useSelector } from "react-redux";
import FontAwesome from "../../atoms/FontAwesome";
import {
  SearchSpotState,
  searchSpotSelector,
  switchViewTypeAction,
  closeSearchSpotModalAction,
  openSearchSpotModalAction,
  searchByGeoAction,
} from "../../../modules/searchSpotModule";
import SearchSpotList from "../../molecules/SearchSpotList";
import { fontsize } from "../../const/Mixin";
import { CSSTransition } from "react-transition-group";
import SearchSpotMap from "../../molecules/SearchSpotMap";
import SearchSpotConst from "../../const/SearchSpotConst";
import { useEffect, createRef } from "react";
import RsGeolocation from "src/js/redseal/lib/RsGeolocation";
import { showMessageBoxAction } from "../../../modules/messageBoxModule";
import {
  gaTrackEvent,
  TRACKEVENT_CATEGORY,
} from "src/js/redseal/lib/analytics-util";

interface IProps {}

const SearchSpotModal: React.FC<IProps> = (props): JSX.Element => {
  const dispatch = useDispatch();
  const searchSpotState: SearchSpotState = useSelector(searchSpotSelector);
  const keywordInputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    didMount(props);
  }, []);

  /**
   *
   * @param props
   */
  const didMount = (props: IProps) => {
    if (document.getElementById("top_menu_item_current_location")) {
      // 現在地検索
      const element: HTMLElement = document.getElementById(
        "top_menu_item_current_location"
      );
      element.addEventListener("click", () => {
        openSearchSpotModalAction(dispatch, SearchSpotConst.MODE_SEARCH);
        searchByGeoAction(dispatch, (errorCode: number) => {
          showMessageBoxAction(
            dispatch,
            RsGeolocation.handlingGeoErrorMessage(errorCode)
          );
        });
      });
    }
  };

  const handleClosePanel = () => {
    closeSearchSpotModalAction(dispatch);
    gaTrackEvent(TRACKEVENT_CATEGORY.SEARCH_MODAL, "CloseSearchSpotModal");
  };

  const handleSwitchMap = () => {
    switchViewTypeAction(dispatch, SearchSpotConst.VIEW_TYPE_MAP);
    gaTrackEvent(TRACKEVENT_CATEGORY.SEARCH_MODAL, "SwitchMap");
  };

  const handleSwitchList = () => {
    switchViewTypeAction(dispatch, SearchSpotConst.VIEW_TYPE_LIST);
    gaTrackEvent(TRACKEVENT_CATEGORY.SEARCH_MODAL, "SwitchList");
  };

  const useGeo = (state: SearchSpotState): boolean => {
    return (
      state.spots !== null &&
      state.spots.length > 0 &&
      state.conditions.position.latitude !== null &&
      state.conditions.position.longitude !== null
    );
  };

  return (
    <CSSTransition
      in={searchSpotState.isShowModal}
      timeout={0}
      classNames="search-spot-modal"
      onEntered={() => {
        keywordInputRef.current && keywordInputRef.current.focus();
      }}
    >
      <SearchSpotModalContainer theme={{ zindex: searchSpotState.zindex }}>
        <Wrapper>
          <Header>
            <HeaderTitle>寺社検索</HeaderTitle>
            <HideSearch onClick={() => handleClosePanel()}>
              <FontAwesome className="fa fa-times" />
            </HideSearch>
          </Header>
          <SearchSpotListWrapper
            className={
              searchSpotState.viewType === SearchSpotConst.VIEW_TYPE_LIST
                ? "show"
                : ""
            }
          >
            <SearchSpotList
              isLoading={searchSpotState.isLoading}
              mode={searchSpotState.mode}
              spots={searchSpotState.spots}
              ref={keywordInputRef}
            />
          </SearchSpotListWrapper>
          <SearchSpotMapWrapper
            className={
              searchSpotState.viewType === SearchSpotConst.VIEW_TYPE_MAP
                ? "show"
                : ""
            }
          >
            <SearchSpotMap
              mode={searchSpotState.mode}
              spots={searchSpotState.spots}
              latitude={searchSpotState.conditions.position.latitude}
              longitude={searchSpotState.conditions.position.longitude}
            />
          </SearchSpotMapWrapper>
          {useGeo(searchSpotState) && (
            <SwitchListMap>
              <SwitchContainer>
                {searchSpotState.viewType === SearchSpotConst.VIEW_TYPE_LIST ? (
                  <SwitchMap onClick={() => handleSwitchMap()}>
                    <FontAwesome className="fa fa-map-marker" />
                    地図
                  </SwitchMap>
                ) : (
                  <SwitchList onClick={() => handleSwitchList()}>
                    <FontAwesome className="fa fa-list" />
                    リスト
                  </SwitchList>
                )}
              </SwitchContainer>
            </SwitchListMap>
          )}
        </Wrapper>
      </SearchSpotModalContainer>
    </CSSTransition>
  );
};
export default SearchSpotModal;

const SearchSpotModalContainer = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zindex};
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${Color.BG_COLOR_MODAL};

  transition: all 500ms ease-in-out;
  &.search-spot-modal-enter {
    opacity: 0;
    height: 0;
  }
  &.search-spot-modal-enter-done {
    opacity: 1;
    height: 100%;
  }
  &.search-spot-modal-exit {
    opacity: 1;
    height: 100%;
  }
  &.search-spot-modal-exit-active {
    opacity: 1;
    height: 0%;
  }
  &.search-spot-modal-exit-done {
    opacity: 0;
    height: 0;
  }
`;

const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 600px;
  height: 100%;
  background-color: ${Color.BG_COLOR_BOX};
  color: ${Color.FONT_COLOR};
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Header = styled.div`
  width: 100%;
  padding: 8px 16px;
  position: relative;
`;

const HeaderTitle = styled.div`
  width: 100%;
  text-align: center;
  font-weight: bold;
`;

const HideSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 40px;
  span {
    cursor: pointer;
  }
`;

const SearchSpotListWrapper = styled.div`
  @keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  display: none;
  &.show {
    display: block;
    animation: show 500ms linear 0s;
  }
`;

const SearchSpotMapWrapper = styled.div`
  @keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  &.show {
    display: block;
    animation: show 500ms linear 0s;
  }
`;

const SwitchListMap = styled.div`
  position: absolute;
  bottom: calc(16px + env(safe-area-inset-bottom));
  width: 100%;
`;

const SwitchContainer = styled.div`
  display: flex;
  width: 96px;
  margin: auto;
  cursor: pointer;
  &:hover {
    transition: transform 0.2s ease-out;
    transform: scale(1.1);
  }
`;

const SwitchItem = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 20px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  width: 100%;
  padding: 8px 16px;
  ${fontsize(14)}
  text-align: center;
`;

const SwitchMap = styled(SwitchItem)`
  background: ${Color.SUB_COLOR_PURPLE};
  color: ${Color.FONT_COLOR_REVERSAL};
  span {
    color: ${Color.FONT_COLOR_REVERSAL};
    margin-right: 4px;
  }
`;

const SwitchList = styled(SwitchItem)`
  background: ${Color.BG_COLOR_BOX};
  color: ${Color.FONT_COLOR};
  span {
    color: ${Color.FONT_COLOR};
    margin-right: 4px;
  }
`;
