import * as React from "react";
import styled, { css, keyframes } from "styled-components";
import FontAwesome from "../../atoms/FontAwesome/FontAwesome";
import Color from "../../const/Color";
import { useDispatch } from "react-redux";
import { showMessageBoxAction } from "../../../modules/messageBoxModule";
import { isSignedIn } from "src/js/redseal/lib/auth-util";
import { useState, useEffect } from "react";
import { evaluateAction as evaluatePostAction } from "../../../modules/postsModule";
import { evaluateAction as evaluateCommentAction } from "../../../modules/commentsModule";
import EvaluationConst from "../../const/EvaluationConst";
import { gaTrackEvent } from "src/js/redseal/lib/analytics-util";

interface IProps {
  isGood: boolean;
  evaluableId: number;
  evaluableType: string;
  width: number;
}

const EvaluateButton: React.FC<IProps> = (props: IProps): JSX.Element => {
  const dispatch = useDispatch();

  // Local state
  const [isGood, setIsGood] = useState(props.isGood);

  const handleClickEvaluate = () => {
    if (isSignedIn()) {
      switch (props.evaluableType) {
        case EvaluationConst.TYPE_POST:
          setIsGood(!isGood);
          evaluatePostAction(
            dispatch,
            props.evaluableId,
            handleOnSuccess,
            handleOnError
          );
          break;
        case EvaluationConst.TYPE_COMMENT:
          setIsGood(!isGood);
          evaluateCommentAction(
            dispatch,
            props.evaluableId,
            handleOnSuccess,
            handleOnError
          );
          break;
        default:
          break;
      }
    } else {
      showMessageBoxAction(
        dispatch,
        "いいねするにはログインする必要があります。"
      );
      gaTrackEvent(
        props.evaluableType,
        "UnsignedEvaluation",
        props.evaluableId
      );
    }
  };

  const handleOnSuccess = (response: IEvaluation) => {
    const message =
      response.evaluate_status === 1
        ? "いいねしました。"
        : "いいねを取り消しました。";
    showMessageBoxAction(dispatch, message);

    const action =
      response.evaluate_status === 1 ? "Evaluation" : "Unevaluation";
    gaTrackEvent(props.evaluableType, action, props.evaluableId);
  };

  const handleOnError = (message: string) => {
    setIsGood(!isGood);
    showMessageBoxAction(dispatch, message);
  };

  useEffect(() => {}, [isGood]);

  return (
    <Wrapper
      onClick={() => handleClickEvaluate()}
      theme={{ isGood: isGood, width: props.width }}
    >
      <FontAwesome className={`fa ${isGood ? "fa-heart" : "fa-heart-o"}`} />
    </Wrapper>
  );
};
export default EvaluateButton;

interface WrapperProps {
  theme: any;
}

const Wrapper = styled.div<WrapperProps>`
  cursor: pointer;
  width: ${({ theme }) => theme.width}px;
  height: ${({ theme }) => theme.width}px;
  span {
    font-size: ${({ theme }) => theme.width}px;
    font-size: ${({ theme }) => (theme.width / 10) * 1}rem;
  }
  &:hover {
    color: ${Color.SUB_COLOR_PINK};
  }

  ${({ theme }) =>
    theme.isGood
      ? css`
          animation: ${BounceIn} 0.5s linear 0.5s 1;
          animation-fill-mode: both;
        `
      : ""}
`;

const BounceIn = keyframes`
  0%, 20%, 40%, 60%, 80%, to {
    animation-timing-function:cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    transform:scale3d(.3, .3, .3)
  }

  20% {
    transform:scale3d(1.1, 1.1, 1.1)
  }

  40% {
    transform:scale3d(.9, .9, .9)
  }

  60% {
    transform:scale3d(1.03, 1.03, 1.03)
  }

  80% {
    transform:scale3d(.97, .97, .97)
  }

  to {
    transform:scaleX(1)
  }
`;
