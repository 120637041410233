import * as React from "react";
import styled from "styled-components";
import FontAwesome from "../../atoms/FontAwesome/FontAwesome";
import Color from "../../const/Color";
import { fontsize } from "../../const/Mixin";
import { useDispatch } from "react-redux";
import SearchSpotConst from "../../const/SearchSpotConst";
import { openSearchSpotModalAction } from "../../../modules/searchSpotModule";
import {
  gaTrackEvent,
  TRACKEVENT_CATEGORY,
} from "src/js/redseal/lib/analytics-util";

/**
 * スポット検索モーダル 表示ボタン
 */
const SearchSpotButton: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();

  /**
   * モーダル表示イベント
   */
  const handleOpenModal = () => {
    openSearchSpotModalAction(dispatch, SearchSpotConst.MODE_SEARCH);
    gaTrackEvent(TRACKEVENT_CATEGORY.HEADER, "OpenSearchSpotModal");
  };

  return (
    <Wrapper onClick={() => handleOpenModal()}>
      <Inner>
        <FontAwesome className="fa fa-search" />
        <Text>神社・お寺を探す</Text>
      </Inner>
    </Wrapper>
  );
};
export default SearchSpotButton;

const Wrapper = styled.div`
  height: 40px;
  width: 200px;
  @media screen and (max-width: 768px) {
    width: 40px;
  }
`;

const Inner = styled.div`
  cursor: pointer;
  height: 100%;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 20px;
  text-align: left;
  padding: 8px 16px;
  color: ${Color.SUB_COLOR_GOLD};
  @media screen and (max-width: 768px) {
    text-align: center;
    padding: 0;
    line-height: 38px;
  }
  ${fontsize(14)}
  .fa-search {
    margin-right: 16px;
    @media screen and (max-width: 768px) {
      margin-right: 0;
    }
  }
`;

const Text = styled.span`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
