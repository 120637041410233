export default {
  POST_MODE_CREATE: "create",
  POST_MODE_EDIT: "edit",

  // 同時投稿最大数
  MAX_POST_NUM: 5,

  // 説明文 文字数の最小、最大数
  DESCRIPTION_MIN: 10,
  DESCRIPTION_MAX: 600,

  // 訪問日を表示するかしないか
  SHOW_VISITED_DATE_OFF: 0, // 表示しない
  SHOW_VISITED_DATE_ON: 1, // 表示する

  // 投稿タイプ
  POST_TYPE_GOSHUIN: 0, // 御朱印
  POST_TYPE_GOODS: 1, // 授与品
  POST_TYPE_POINT: 2, // 見どころ
  POST_TYPE_DROP: 3, // 立ち寄り
  POST_TYPE_GOSHUINCHO: 4, // 御朱印帳

  // Push履歴用 LocalStorage Key
  LS_PUSH_HISTORY_KEY: "rs_push_history",
  LS_PUSH_HISTORY_ITEM_MAX: 100,
};
