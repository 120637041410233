export default {
  MAIN_COLOR: "#e64215",
  SUB_COLOR_RED: "#e64215",
  SUB_COLOR_YELLOW: "#E0B34E",
  SUB_COLOR_GREEN: "#94B78D",
  SUB_COLOR_BLUE: "#1E88A8",
  SUB_COLOR_GRAY: "#edeeee",
  SUB_COLOR_PURPLE: "#aa89bd",
  SUB_COLOR_GOLD: "#b3ada0",
  SUB_COLOR_PINK: "#f58f98",
  SUB_COLOR_RANK_GOLD: "#e6b422",
  SUB_COLOR_RANK_SILVER: "#afafb0",
  SUB_COLOR_RANK_BRONZE: "#ae7c58",
  FONT_COLOR: "#333",
  FONT_COLOR_REVERSAL: "#FFF",
  FONT_COLOR_GRAY: "#737373",
  BG_COLOR: "#fcfcfc",
  BG_COLOR_BOX: "#fff",
  BG_COLOR_BOX_HOVER: "#e5e5e5",
  BG_COLOR_MODAL: "rgba(46, 41, 48, 0.9)",
  BTN_BG_COLOR_NORMAL: "#FFF",
  BTN_BG_COLOR_NORMAL_HOVER: "#b3ada0",
  LIST_BORDER_COLOR: "#edeeee",
  LOADING_PLACE_HOLDER_COLOR: "#edeeee",
};
