import { post, del } from "../network/api-client";

export const create = async (
  form: FormData
): Promise<IRedsealAPIResponse<IComment>> => {
  try {
    const API_URI = "/api/comments/create";
    return await post<IComment>(API_URI, form);
  } catch (err) {
    throw err;
  }
};

/**
 * コメント削除
 * @param commentId
 */
export const destroy = async (
  commentId: number
): Promise<IRedsealAPIResponse<IComment>> => {
  try {
    const API_URI = "/api/comments/destroy";
    const data = {
      comment: {
        comment_id: commentId,
      },
    };
    return await del<IComment>(API_URI, data);
  } catch (err) {
    throw err;
  }
};
