import * as React from "react";
import styled from "styled-components";
import WishButton from "../../atoms/WishButton";
import PostMultiButton from "../../atoms/PostMultiButton";

interface IProps {
  showPostButton: boolean;
  showWishButton: boolean;
  spotId: number | null;
  spotName: string | null;
  wishChecked: boolean;
}

const BottomNavigation: React.FC<IProps> = (props): JSX.Element => {
  return (
    <Wrapper>
      {props.showPostButton && (
        <PostMultiButton spotId={props.spotId} spotName={props.spotName} />
      )}
      {props.showWishButton && props.spotId !== null && (
        <WishButton checked={props.wishChecked} spotId={props.spotId} />
      )}
    </Wrapper>
  );
};
export default BottomNavigation;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 16px;
  @media screen and (max-width: 768px) {
    bottom: 80px;
  }
  right: 8px;
`;
