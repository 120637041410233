import { post } from "../network/api-client";

export const evaluate = async (
  form: FormData
): Promise<IRedsealAPIResponse<IEvaluation>> => {
  try {
    const API_URI = "/api/evaluations/create";
    return await post<IEvaluation>(API_URI, form);
  } catch (err) {
    throw err;
  }
};
