import { isiOS } from "./ua-util";

export default class BodyFixed {
  private scrollPositionX = 0;
  private scrollPositionY = 0;
  private counter = 0;

  private static _instance: BodyFixed = null;

  private constructor() {}

  public static get instance(): BodyFixed {
    if (!this._instance) {
      this._instance = new BodyFixed();
    }
    return this._instance;
  }

  on() {
    this.counter++;
    if (this.counter > 1) {
      return;
    }
    if (isiOS()) {
      this.scrollPositionX = window.scrollX;
      this.scrollPositionY = window.scrollY;
      document.body.setAttribute(
        "style",
        `width: 100%; position: fixed; top: -${this.scrollPositionY}px;`
      );
    } else {
      document.body.setAttribute("style", "overflow: hidden;");
    }
  }

  off() {
    this.counter--;
    if (this.counter > 0) {
      return;
    }
    document.body.removeAttribute("style");
    if (isiOS()) {
      window.scrollTo(this.scrollPositionX, this.scrollPositionY);
    }
  }
}
