export default {
  MESSAGE_BOX: 10001,
  OVERLAY: 10000,
  SEARCH_MODAL: 1011,
  POST_MODAL: 1010,
  DATE_PICKER_MODAL: 1011,
  POST_COMMENT_MODAL: 1011,
  POST_EDIT_OPTION_MODAL: 1009,
  USER_CONTROL_MODAL: 1009,
};
