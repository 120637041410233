import * as React from "react";
import styled from "styled-components";
import Color from "../../const/Color";
import { useDispatch, useSelector } from "react-redux";
import { normalBox, lineClamp, fontsize, toolTipTop } from "../../const/Mixin";
import CircleUser from "../../atoms/CircleUser";
import Link from "../../atoms/Link";
import FontAwesome from "../../atoms/FontAwesome";
import LazyLoadImage from "../../atoms/LazyLoadImage";
import EvaluateButton from "../../atoms/EvaluateButton";
import PostContainerComment from "./PostContainerComment";
import Paragraph from "../../atoms/Paragraph";
import {
  postsSelector,
  PostsState,
  findPost,
  pushAction,
} from "../../../modules/postsModule";
import {
  UsersState,
  usersSelector,
  findUser,
} from "../../../modules/usersModule";
import {
  spotsSelector,
  SpotsState,
  findSpot,
} from "../../../modules/spotsModule";
import {
  CommentsState,
  commentsSelector,
  findCommentsByPostId,
} from "../../../modules/commentsModule";
import EvaluationConst from "../../const/EvaluationConst";
import { useState } from "react";
import PostTypeScores from "./PostTypeScores";
import { getSpotNoImagePathBySpotType } from "src/js/redseal/lib/image-util";
import {
  gaTrackEvent,
  TRACKEVENT_CATEGORY,
  gaPageview,
} from "src/js/redseal/lib/analytics-util";
import { useIntersection } from "src/js/redseal/lib/inview-util";
import { truncate, fulltrim } from "src/js/redseal/lib/string-util";
import { AccountState, accountSelector } from "../../../modules/accountModule";
import { isSignedIn } from "src/js/redseal/lib/auth-util";
import { showMessageBoxAction } from "../../../modules/messageBoxModule";
import PostConst from "../../const/PostConst";
import { isNullOrUndefined } from "src/js/redseal/lib/object-util";
import ModalPortal from "../../organisms/ModalPortal";
import PostCommentModal from "../../organisms/PostCommentModal";
import BodyFixed from "src/js/redseal/lib/BodyFixed";
import PostEditOptionModal from "../../organisms/PostEditOptionModal";

interface IProps {
  postId: number;
  usePush: boolean;
}

const PostContainer: React.FC<IProps> = (props): JSX.Element => {
  const dispatch = useDispatch();

  const accountState: AccountState = useSelector(accountSelector);
  const postsState: PostsState = useSelector(postsSelector);
  const commentsState: CommentsState = useSelector(commentsSelector);
  const usersState: UsersState = useSelector(usersSelector);
  const spotsState: SpotsState = useSelector(spotsSelector);

  // Local state
  const [isExpandComments, setIsExpandComments] = useState(false);
  const [isInviewed, setIsInviewed] = useState(false);

  const [isShowCommentModal, setIsShowCommentModal] = useState(false);
  const [isShowPostEditModal, setIsShowPostEditModal] = useState(false);

  const isIncludesPost = (postId: number): boolean => {
    return accountState.evaluatePosts.indexOf(postId) > -1;
  };

  /**
   * コメントモーダル オープン
   */
  const handleAddComment = (postId: number) => {
    if (!isSignedIn()) {
      showMessageBoxAction(
        dispatch,
        "コメントするにはログインする必要があります。"
      );
      return;
    }
    handleCommentModalState(true);
    gaTrackEvent(TRACKEVENT_CATEGORY.POST, "OpenCommentModal", postId);
  };

  /**
   * コメントモーダルの状態管理
   * @param isShow 
   */
  const handleCommentModalState = (isShow: boolean) => {
    if (isShow) {
      BodyFixed.instance.on();
    } else {
      BodyFixed.instance.off();
    }
    setIsShowCommentModal(isShow);
  };

  /**
   * 投稿編集モーダル オープン
   * @param postId 
   */
  const handleEditOption = (postId: number) => {
    handlePostEditModalState(true);
    gaTrackEvent(TRACKEVENT_CATEGORY.POST, "OpenPostEditOptionModal", postId);
  };

  /**
   * 投稿編集モーダルの状態管理
   * @param isShow 
   */
  const handlePostEditModalState = (isShow: boolean) => {
    if (isShow) {
      BodyFixed.instance.on();
    } else {
      BodyFixed.instance.off();
    }
    setIsShowPostEditModal(isShow);
  };

  const handleViewMoreComments = (postId: number) => {
    setIsExpandComments(true);
    gaTrackEvent(TRACKEVENT_CATEGORY.POST, "ViewMoreComments", postId);
  };

  const expandComments = (): JSX.Element[] => {
    return comments.map((comment: IComment) => {
      return (
        <PostContainerComment
          comment={comment}
          user={findUser(comment.user_id, usersState)}
          key={comment.comment_id}
        />
      );
    });
  };

  const post = findPost(props.postId, postsState);
  if (post === null) {
    return null;
  }
  const postUser = findUser(post.user_id, usersState);
  const spot = findSpot(post.spot_id, spotsState);
  const comments = findCommentsByPostId(post.post_id, commentsState);
  if (postUser === null || spot === null) {
    return null;
  }

  const pageTitle = `${spot.name}-${""}の${post.post_type_name}。${truncate(
    fulltrim(post.description),
    20
  )}`;

  const inviewTarget = React.useRef<HTMLDivElement>(null);
  if (props.usePush) {
    useIntersection(inviewTarget, () => {
      if (!isInviewed) {
        setIsInviewed(true);

        if (isMyPost(post.user_id)) {
          return;
        }

        if (isPushedPost(post.post_id)) {
          return;
        }

        pushAction(post.post_id, () => {
          gaPageview(pageTitle, `/posts/${post.post_id}`);
        });
      }
    });
  }

  const isMyPost = (userId: number): boolean => {
    if (!isNullOrUndefined(accountState) && accountState.userId === userId) {
      return true;
    }
    return false;
  };

  const isPushedPost = (postId: number): boolean => {
    if (!window.isAvailableLocalStorage) {
      return false;
    }
    const strPostId = postId.toString(10);
    const pushedListStr = localStorage.getItem(PostConst.LS_PUSH_HISTORY_KEY);
    if (isNullOrUndefined(pushedListStr)) {
      localStorage.setItem(PostConst.LS_PUSH_HISTORY_KEY, strPostId);
      return false;
    }
    const pushedList = pushedListStr.split(",");
    if (pushedList.includes(strPostId)) {
      return true;
    }

    pushedList.push(strPostId);
    if (pushedList.length > PostConst.LS_PUSH_HISTORY_ITEM_MAX) {
      pushedList.shift();
    }
    localStorage.setItem(PostConst.LS_PUSH_HISTORY_KEY, pushedList.join(","));
    return false;
  };

  return (
    <Wrapper>
      <PostHeader>
        <PostUserSpotInfo>
          <CircleUser
            userId={postUser.user_id}
            profileImagePath={postUser.profile_image_100}
            name={postUser.name}
            slug={postUser.slug}
            width={40}
          />
          <PostInfo>
            <PostUserName>
              <Link url={`/users/${postUser.uid}`}>{postUser.name}</Link>
              {postUser.is_certified && !postUser.is_withdraw && (
                <FontAwesome className={"fa fa-check-circle certified"} />
              )}
            </PostUserName>
            <PostSpotInfo>
              <Link url={`/spots/${spot.spot_id}`}>{spot.name}</Link>
            </PostSpotInfo>
            <PostSpotInfo>
              {spot.spot_city && (
                <React.Fragment>
                  <Link url={`/city/${spot.spot_city_id}`}>
                    {spot.spot_city}
                  </Link>
                  /
                </React.Fragment>
              )}
              <Link url={`/pref/${spot.spot_pref_slug}`}>{spot.spot_pref}</Link>
            </PostSpotInfo>
          </PostInfo>
        </PostUserSpotInfo>
      </PostHeader>
      <PostPicture>
        <LazyLoadImage
          src={post.post_picture_800}
          height={null}
          width={"100%"}
          alt={`${spot.name},${post.post_type_name},${postUser.name}`}
          noImage={getSpotNoImagePathBySpotType(spot.spot_type)}
          objectFit={null}
        />
      </PostPicture>
      <PostTypeScores
        post_type_key={post.post_type_key}
        post_type_name={post.post_type_name}
        post_eval_good={post.post_eval_good}
        post_eval_comment={post.post_eval_comment}
        post_eval_pageview={post.post_eval_pageview}
        post_created={post.post_created}
      />
      <PostDesc ref={inviewTarget}>
        <Paragraph content={post.description} />
      </PostDesc>
      {post.visited_date !== null &&
        post.show_visited_date === PostConst.SHOW_VISITED_DATE_ON && (
          <PostVisitedDate>
            <FontAwesome className={"fa fa-calendar"} />
            <span>訪問日</span>
            {post.visited_date_in_time_zone}
          </PostVisitedDate>
        )}
      {(() => {
        if (comments.length === 0) {
          return null;
        } else if (comments.length === 1) {
          return (
            <PostContainerComment
              comment={comments[0]}
              user={findUser(comments[0].user_id, usersState)}
              key={comments[0].comment_id}
            />
          );
        } else if (isExpandComments) {
          return expandComments();
        } else {
          return (
            <React.Fragment>
              <PostContainerComment
                comment={comments[0]}
                user={findUser(comments[0].user_id, usersState)}
                key={comments[0].comment_id}
              />
              <ViewMoreComments
                onClick={() => handleViewMoreComments(post.post_id)}
              >
                <FontAwesome className={"fa fa-angle-down"} />
                全てのコメントを見る
              </ViewMoreComments>
            </React.Fragment>
          );
        }
      })()}
      {(() => {
        if (postUser.is_withdraw) {
          return null;
        } else {
          return (
            <React.Fragment>
              <Separator />
              <EvaluateAndComment>
                {postUser.is_accept_good ? (
                  <EvaluateButton
                    isGood={isIncludesPost(post.post_id)}
                    evaluableId={post.post_id}
                    evaluableType={EvaluationConst.TYPE_POST}
                    width={24}
                  />
                ) : (
                  <React.Fragment />
                )}
                {postUser.is_accept_comment ? (
                  <AddComment onClick={() => handleAddComment(post.post_id)}>
                    コメントする…
                  </AddComment>
                ) : (
                  <AddComment />
                )}
                <EditOption data-tooltip="投稿の編集" onClick={() => handleEditOption(post.post_id)}>
                  <FontAwesome className={"fa fa-ellipsis-h"} />
                </EditOption>
              </EvaluateAndComment>
            </React.Fragment>
          );
        }
      })()}
      <ModalPortal targetId={"post_comment_modal"}>
        <PostCommentModal
          postId={props.postId}
          isShowModal={isShowCommentModal}
          setIsShowModal={handleCommentModalState}
        />
      </ModalPortal>
      <ModalPortal targetId={"post_edit_option_modal"}>
        <PostEditOptionModal
          targetId={props.postId}
          targetType={"Post"}
          isShowModal={isShowPostEditModal}
          setIsShowModal={handlePostEditModalState}
        />
      </ModalPortal>
    </Wrapper>
  );
};
export default PostContainer;

const Wrapper = styled.div`
  ${normalBox()};
  margin-bottom: 24px;
`;

const PostHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
`;

const PostUserSpotInfo = styled.div`
  display: flex;
`;

const PostInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  width: 100%;

  a {
    color: ${Color.SUB_COLOR_BLUE};
    text-decoration: none;
  }
  a:link {
    color: ${Color.SUB_COLOR_BLUE};
    text-decoration: none;
  }
  a:hover {
    color: ${Color.SUB_COLOR_BLUE};
    text-decoration: underline;
  }
  a:visited {
    color: ${Color.SUB_COLOR_BLUE};
    text-decoration: none;
  }
`;

const PostUserName = styled.div`
  ${lineClamp(1)}
  font-weight: bold;
  span.certified {
    margin-left: 2px;
  }
`;

const PostSpotInfo = styled.div`
  ${lineClamp(1)}
  ${fontsize(14)}
`;

const PostPicture = styled.div``;

const PostDesc = styled.div`
  padding: 16px;
  word-break: break-all;
`;

const PostVisitedDate = styled.div`
  color: ${Color.SUB_COLOR_GOLD};
  ${fontsize(14)}
  padding: 0 24px 8px 24px;
  text-align: right;
  span {
    margin-right: 4px;
  }
`;

const EvaluateAndComment = styled.div`
  display: flex;
  padding: 24px 16px;
`;

const AddComment = styled.div`
  cursor: pointer;
  color: ${Color.SUB_COLOR_GOLD};
  flex: 1;
  margin: 0 16px;
  padding-left: 8px;
`;

const EditOption = styled.div`
  cursor: pointer;
  margin-left: auto;
  width: 24px;
  height: 24px;
  text-align: center;
  color: ${Color.FONT_COLOR_GRAY};
  border-radius: 12px;

  ${toolTipTop()}
`;

const Separator = styled.div`
  border-top: 1px solid ${Color.LIST_BORDER_COLOR};
  margin: 0 16px;
`;

const ViewMoreComments = styled.div`
  color: ${Color.SUB_COLOR_GOLD};
  cursor: pointer;
  ${fontsize(14)}
  padding: 0 0 16px;
  text-align: center;
  span {
    margin-right: 8px;
  }
  &:hover {
    text-decoration: underline;
  }
`;
