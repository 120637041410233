import Color from "./Color";

export const fontsize = (size: number, base: number = 10) => `
  font-size: ${size}px;
  font-size: ${(size / base) * 1}rem;
`;

export const normalBox = () => `
  box-shadow: 0 1px 4px rgba(0, 0, 0, .04);
  border: 1px solid rgba(0, 0, 0, .09);
  border-radius: 3px;
  background: ${Color.BG_COLOR_BOX};
`;

export const squreBox = (diameter: number, radius: number = 3) => `
  overflow: hidden;
  width: ${diameter}px;
  height: ${diameter}px;
  border-radius: ${radius}px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .1),0 1px 2px rgba(0, 0, 0, .1);
  background: ${Color.BG_COLOR_BOX};
`;

export const userCircleBox = (diameter: number) => `
  overflow: hidden;
  width: ${diameter}px;
  height: ${diameter}px;
  border-radius: ${diameter / 2}px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, .1),0 1px 2px rgba(0, 0, 0, .1);
  background: ${Color.LOADING_PLACE_HOLDER_COLOR};
`;

export const formControl = () => `
  display: block;
  width: 100%;
  height: 38px;
  padding: 6px 12px;
  color: ${Color.FONT_COLOR};
  background-color: ${Color.BG_COLOR_BOX};
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  }
`;

export const lineClamp = (line: number) => `
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${line};
  text-overflow: ellipsis;
  white-space: normal;
`;

export const toolTipTop = () => `
  display: inline-block;
  position: relative;
  &:before,
  &:after {
    -webkit-transition: all 0.2s;
    opacity: 0;
    position: absolute;
    transition: all 0.2s;
    visibility: hidden;
    z-index: 11;
  }
  &:after {
    background: #5f6368;
    border-radius: 3px;
    color: #fff;
    content: attr(data-tooltip);
    display: block;
    font-size: 11px;
    font-weight: 600;    
    padding: 5px 10px;
    top: calc(-50% - 8px);
    left: calc(-50% - 13.39px);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    white-space: nowrap;
  }
  &:hover {
    cursor: pointer;
  }
  &:hover::before {
    opacity: 1;
    visibility: visible;
  }
  &:hover::after {
    opacity: 1;
    visibility: visible;
  }
`;
