import * as React from "react";
import styled from "styled-components";
import { fontsize, formControl } from "../../const/Mixin";

interface IProps {
  selected: string;
  fn(arg0: string): void;
  className?: string;
}

const PostTypeSelect: React.FC<IProps> = (props): JSX.Element => {
  const handleChange = (event: React.FormEvent<HTMLSelectElement>) => {
    props.fn(event.currentTarget.value);
  };

  return (
    <Wrapper className={props.className}>
      <Select
        value={props.selected}
        onChange={(event: React.FormEvent<HTMLSelectElement>) => {
          handleChange(event);
        }}
      >
        <option value={"all"} key={0}>
          すべての投稿
        </option>
        <option value={"goshuin"} key={1}>
          御朱印
        </option>
        <option value={"goshuincho"} key={2}>
          御朱印帳
        </option>
        <option value={"point"} key={3}>
          見どころ
        </option>
        <option value={"drop"} key={4}>
          立ち寄り
        </option>
        <option value={"goods"} key={5}>
          授与品
        </option>
      </Select>
    </Wrapper>
  );
};
export default PostTypeSelect;

const Wrapper = styled.div`
  margin-right: 4px;
`;

const Select = styled.select`
  ${fontsize(14)}
  ${formControl()}
`;
