import { Action, Dispatch } from "@reduxjs/toolkit";
import { accountModule } from "../presentation/modules/accountModule";
import { commentsModule } from "../presentation/modules/commentsModule";
import { postsModule } from "../presentation/modules/postsModule";
import { spotsModule } from "../presentation/modules/spotsModule";
import { usersModule } from "../presentation/modules/usersModule";

export const postContainerDispatcher = (
  dispatch: Dispatch<Action<any>>,
  response: IPostContainer
) => {
  dispatch(
    postsModule.actions.setPostsAction({
      type: "setPostsAction",
      posts: response.posts,
    })
  );

  dispatch(
    commentsModule.actions.setCommentsAction({
      type: "setCommentsAction",
      comments: response.comments,
    })
  );

  dispatch(
    usersModule.actions.setUsersAction({
      type: "setUsersAction",
      users: response.users,
    })
  );

  dispatch(
    spotsModule.actions.setSpotsAction({
      type: "setSpotsAction",
      spots: response.spots,
    })
  );

  if (response.account) {
    dispatch(
      accountModule.actions.setAccountAction({
        type: "setAccountAction",
        account: response.account,
      })
    );
  }
};
