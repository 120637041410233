import {
  aroundById,
  getCertifiedUser,
  searchByGeo,
  searchByKeyword,
  spotShowType,
  wish,
} from "src/js/redseal/data/datastores/spots";
import RsGeolocation from "../../lib/RsGeolocation";

/**
 * 指定のスポットIDから近隣のスポットを検索する
 * @param spotId
 */
export const around = async (spotId: number): Promise<ISpotsSearch[]> => {
  const response = await aroundById(spotId);
  return response.data;
};

export const usecaseSpotsSearchByCurrentLocation = async (): Promise<
  [IPosition, ISpotsSearch[]]
> => {
  const position = await RsGeolocation.getCurrentLocation();
  const response = await searchByGeo(
    String(position.coords.latitude),
    String(position.coords.longitude)
  );
  return [position, response.data];
};

export const usecaseSpotsSearchByKeyword = async (
  keyword: string,
  prefectureId?: number
): Promise<ISpotsSearch[]> => {
  const response = await searchByKeyword(keyword, prefectureId);
  return response.data;
};

export const usecaseSpotsWish = async (formData: FormData): Promise<ISpotWish> => {
  const response = await wish(formData);
  return response.data;
};

export const usecaseSpotShowType = async (
  spotId: number,
  postType: string,
  postId: number | null,
  page: number
): Promise<IPostContainer> => {
  const response = await spotShowType(spotId, postType, postId, page);
  return response.data;
};

export const usecaseSpotShow = async (
  spotId: number
): Promise<ICertifiedUser> => {
  const response = await getCertifiedUser(spotId);
  return response.data;
};
