import { create, destroy } from "../../data/datastores/comments";

export const createComment = async (formData: FormData): Promise<IComment> => {
  const response = await create(formData);
  return response.data;
};

export const destroyComment = async (commentId: number): Promise<IComment> => {
  const response = await destroy(commentId);
  return response.data;
};
