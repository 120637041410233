import * as React from "react";
import styled from "styled-components";
import { fontsize, formControl } from "../../const/Mixin";

interface IProps {
  selected: number;
  fn(arg0: number): void;
}

const DaySelect: React.FC<IProps> = (props): JSX.Element => {
  const handleChange = (event: React.FormEvent<HTMLSelectElement>) => {
    props.fn(parseInt(event.currentTarget.value));
  };

  const items = (): number[] => {
    const _items = [];
    for (let i = 0; i < 31; i++) {
      _items.push(i + 1);
    }
    return _items;
  };

  return (
    <Wrapper>
      <Select
        value={props.selected}
        onChange={(event: React.FormEvent<HTMLSelectElement>) => {
          handleChange(event);
        }}
      >
        {items().map((item, index: number) => {
            return (
              <option value={item} key={index}>
                {item}日
              </option>
            );
          })}
      </Select>
    </Wrapper>
  );
};
export default DaySelect;

const Wrapper = styled.div`
  margin-right: 4px;
`;

const Select = styled.select`
  ${fontsize(16)}
  ${formControl()}
`;
