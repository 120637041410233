import * as React from "react";
import styled from "styled-components";
import Link from "../../atoms/Link";
import LazyLoadImage from "../../atoms/LazyLoadImage";
import { getSpotNoImagePathBySpotType } from "src/js/redseal/lib/image-util";
import Color from "../../const/Color";

interface IProps {
  post: IPost;
  spot: ISpot;
}

const PostTile: React.FC<IProps> = (props): JSX.Element => {
  return (
    <Wrapper>
      <Link url={`/posts/${props.post.post_id}`}>
        <Inner>
          <PostPicture>
            <LazyLoadImage
              src={props.post.post_picture_800}
              height={"100%"}
              width={"100%"}
              alt={`${props.spot.name},${props.post.post_type_name}`}
              noImage={getSpotNoImagePathBySpotType(props.spot.spot_type)}
              objectFit={"cover"}
            />
            <SpotName className={"post-tile-spot-name"}>
              {props.spot.name}
            </SpotName>
          </PostPicture>
        </Inner>
      </Link>
    </Wrapper>
  );
};
export default PostTile;

const Wrapper = styled.div`
  width: 33%;
  width: calc(100% / 3);
  padding: 4px;
  @media screen and (max-width: 768px) {
    padding: 1px;
  }
`;

const Inner = styled.div`
  position: relative;
  &:before {
    display: block;
    content: "";
    padding-top: 100%;
  }
  &:hover .post-tile-spot-name {
    z-index: 1;
  }
`;

const PostPicture = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  box-sizing: border-box;
`;

const SpotName = styled.div`
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Color.FONT_COLOR_REVERSAL};
  background: rgba(0, 0, 0, 0.5);
  padding: 8px;
`;
