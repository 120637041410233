import SpotConst from "../presentation/components/const/SpotConst";

export const MATERIAL_BASE_PATH_NAME = "material";
export type ROTATE_ANGLE = 90 | 180 | 270 | 360;
export type OBJECT_FIT = "fill" | "contain" | "cover" | "none" | "scale-down";

const LANDSCAPE_MAX_WIDTH = 1280;
const PORTRAIT_MAX_WIDTH = 960;

/**
 *
 * @param file
 */
export const load = async (file: File) =>
  new Promise<string>((resolve) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = 0;
      canvas.height = 0;

      const iw = image.width;
      const ih = image.height;
      let w = 0;
      if (iw > ih) {
        w = iw > LANDSCAPE_MAX_WIDTH ? LANDSCAPE_MAX_WIDTH : iw;
      } else {
        w = iw > PORTRAIT_MAX_WIDTH ? PORTRAIT_MAX_WIDTH : iw;
      }
      const h = image.height * (w / iw);
      canvas.width = w;
      canvas.height = h;
      ctx.drawImage(image, 0, 0, w, h);

      const img = document.createElement("img");
      const base64 = canvas.toDataURL(file.type);

      img.src = base64;

      resolve(base64);
    };
  });

/**
 *
 * @param angle
 * @param image
 */
export const rotate = async (angle: ROTATE_ANGLE, image: HTMLImageElement) =>
  new Promise<string>((resolve) => {
    image.crossOrigin = "Anonymous";
    image.onload = () => {
      const width = image.width;
      const height = image.height;
      const canvas = document.createElement("canvas");
      if (angle === 90 || angle === 270) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }
      const ctx = canvas.getContext("2d");
      ctx.rotate((angle * Math.PI) / 180);
      if (angle === 90) {
        ctx.translate(0, -height);
      } else if (angle === 180) {
        ctx.translate(-width, -height);
      } else if (angle === 270) {
        ctx.translate(-width, 0);
      } else if (angle === 360) {
        ctx.translate(0, 0);
      }

      ctx.drawImage(image, 0, 0, width, height);
      resolve(canvas.toDataURL());
    };
  });

export const toBlob = (base64: string, contentType: string): Blob => {
  const bin = atob(base64.replace(/^.*,/, ""));
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }
  return new Blob([buffer.buffer], { type: contentType });
};

export const getSpotNoImagePath = (spotType: string): string => {
  switch (spotType) {
    case "l_shrine":
      return getSpotNoImagePathBySpotType(SpotConst.SPOT_TYPE_SHRINE);
    case "l_temple":
      return getSpotNoImagePathBySpotType(SpotConst.SPOT_TYPE_TEMPLE);
    default:
      return getSpotNoImagePathBySpotType(SpotConst.SPOT_TYPE_OTHER);
  }
};

export const getSpotNoImagePathBySpotType = (spotType: number): string => {
  switch (spotType) {
    case SpotConst.SPOT_TYPE_SHRINE:
      return `/${MATERIAL_BASE_PATH_NAME}/no_photo_shrine.png`;
    case SpotConst.SPOT_TYPE_TEMPLE:
      return `/${MATERIAL_BASE_PATH_NAME}/no_photo_temple.png`;
    default:
      return `/${MATERIAL_BASE_PATH_NAME}/no_photo_other.png`;
  }
};
