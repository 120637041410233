import { MATERIAL_BASE_PATH_NAME } from "./image-util";

export default class GoogleMap {
  private XDIST_BASE = 318;
  private markers: google.maps.Marker[] = [];
  private activeMarkerIndex: number = 0;
  private map: google.maps.Map;

  constructor(
    ref: React.MutableRefObject<any>,
    latitude: number,
    longitude: number
  ) {
    this.map = new google.maps.Map(ref.current, {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: 14,
      zoomControl: true,
      zoomControlOptions: { position: google.maps.ControlPosition.LEFT_TOP },
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
    });

    // Create center marker
    new google.maps.Marker({
      map: this.map,
      position: new google.maps.LatLng(latitude, longitude),
      icon: {
        url: `/${MATERIAL_BASE_PATH_NAME}/current-location.png`,
        scaledSize: new google.maps.Size(24, 24),
      },
    });
  }

  /**
   * マーカをマップに登録する.
   * @param spots
   */
  setMarkers(spots: ISpotsSearch[]): void {
    let xdist = this.XDIST_BASE;
    for (let index = 0; index < spots.length; index++) {
      const spot = spots[index];
      this.markers[index] = new google.maps.Marker({
        map: this.map,
        position: new google.maps.LatLng(spot.latitude, spot.longitude),
      });

      xdist = xdist - this.XDIST_BASE;
      this.addMarkerEvent(this.markers[index], index, xdist);
    }
  }

  /**
   * マーカにイベントを登録.
   * @param marker
   * @param index
   * @param xdist
   */
  addMarkerEvent(
    marker: google.maps.Marker,
    index: number,
    xdist: number
  ): void {
    marker.addListener("click", () => {
      this.setCurrentSlides(xdist);
      this.changeCurrentItem(index);
    });
  }

  /**
   * カルーセルアイテムをスライドさせる.
   * @param xdist
   */
  setCurrentSlides(xdist: number): void {
    const carouselContainer: HTMLElement = document.getElementById(
      "map_spot_carousel"
    );
    carouselContainer.setAttribute(
      "style",
      `transform: translate3d(${xdist}px, 0px, 0px);`
    );
  }

  /**
   * 選択されたマーカを排他的にバウンスさせる.
   * @param index
   */
  changeCurrentItem(index: number): void {
    this.bounceMarker(index);
    this.activeMarkerIndex = index;
  }

  /**
   * 地図マーカを排他的にバウンスさせる.
   * @param index
   */
  bounceMarker(index: number): void {
    if (this.markers[this.activeMarkerIndex].getAnimation()) {
      this.markers[this.activeMarkerIndex].setAnimation(null);
    }
    this.markers[index].setAnimation(google.maps.Animation.BOUNCE);
  }

  /**
   * 選択されたマーカを中心位置にする.
   * @param index
   */
  panTo(index: number): void {
    this.map.panTo(this.markers[index].getPosition());
  }
}
