import * as React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Color from "../../const/Color";
import { fontsize } from "../../const/Mixin";
import LazyLoadImage from "../../atoms/LazyLoadImage";
import { selectSpotAction } from "../../../modules/postFormModule";
import { MouseEvent, TouchEvent } from "react";
import SearchSpotConst from "../../const/SearchSpotConst";
import { closeSearchSpotModalAction } from "../../../modules/searchSpotModule";
import { getSpotNoImagePath } from "src/js/redseal/lib/image-util";
import {
  gaTrackEvent,
  TRACKEVENT_CATEGORY,
} from "src/js/redseal/lib/analytics-util";

interface IProps {
  mode: string;
  key: number;
  spot: ISpotsSearch;
  transform: string;
  xdist: number;
  xdistBase: number;
  setCurrentSlides: (xdist: number) => void;
  changeCurrentItem: (index: number) => void;
  panTo: (markerIndex: number) => void;
}

interface IItemProps {
  transform: string;
}

const SearchSpotCarouselItem: React.FC<IProps> = (props): JSX.Element => {
  const dispatch = useDispatch();

  const spotImage = (
    <LazyLoadImage
      src={
        props.spot.pict_100
          ? props.spot.pict_100
          : getSpotNoImagePath(props.spot.spot_type_name)
      }
      alt={props.spot.name}
      width={"92"}
      height={"92"}
      objectFit={"cover"}
      noImage={getSpotNoImagePath(props.spot.spot_type_name)}
    />
  );

  const handleClickSpot = (spotId: number, spotName: string, mode: string) => {
    if (isSwiped) {
      isSwiped = false;
      return;
    }
    if (mode === SearchSpotConst.MODE_POST) {
      gaTrackEvent(
        TRACKEVENT_CATEGORY.SEARCH_MODAL,
        "SelectMapCarouselItemToPost",
        spotId
      );
      isSwiped = false;
      selectSpotAction(dispatch, spotId, spotName);
      closeSearchSpotModalAction(dispatch);
    } else {
      gaTrackEvent(
        TRACKEVENT_CATEGORY.SEARCH_MODAL,
        "SelectMapCarouselItemToSpot",
        spotId
      );
      isSwiped = false;
      window.open(`/spots/${spotId}`, "_blank");
    }
  };

  let swipeStartPosition = 0;
  let swipeOffsetPosition = 0;
  let isSwiped = false;
  const SWIPE_THRESHOLD = 70;

  const getTouchPosition = (event: TouchEvent): number => {
    return event.touches[0].clientX;
  };

  const getMousePosition = (event: MouseEvent): number => {
    return event.pageX;
  };

  const handleTouchStart = (event: TouchEvent) => {
    _handleTouchStart(getTouchPosition(event));
  };

  const handleMouseDown = (event: MouseEvent) => {
    _handleTouchStart(getMousePosition(event));
  };

  /**
   * カルーセルをタッチした瞬間の処理
   * 初期タップ時の座標を保持
   * @param position
   */
  const _handleTouchStart = (position: number) => {
    swipeStartPosition = position;
  };

  const handleTouchMove = (event: TouchEvent) => {
    _handleTouchMove(getTouchPosition(event));
  };

  const handleMouseMove = (event: MouseEvent) => {
    _handleTouchMove(getMousePosition(event));
  };

  /**
   * スワイプ中の処理
   * 初期タップ時の座標と現在の座標の差分を移動量として保持
   * @param position
   */
  const _handleTouchMove = (position: number) => {
    if (swipeStartPosition === 0) {
      // スワイプ中に、タッチ時のカルーセルアイテムの外に出た場合
      return;
    }
    swipeOffsetPosition = swipeStartPosition - position;
  };

  const handleTouchEnd = (xdist: number) => {
    _handleTouchEnd(xdist);
  };

  const handleMouseUp = (xdist: number) => {
    _handleTouchEnd(xdist);
  };

  const _handleTouchEnd = (xdist: number) => {
    if (
      swipeOffsetPosition === 0 ||
      Math.abs(swipeOffsetPosition) < SWIPE_THRESHOLD
    ) {
      initSwipePosition();
      return;
    }

    let x = 0;
    if (swipeOffsetPosition > 0) {
      // left
      x = xdist - props.xdistBase;
    } else {
      // right
      x = xdist + props.xdistBase;
    }

    initSwipePosition();
    isSwiped = true;

    props.setCurrentSlides(x);
    const markerIndex = Math.abs(x) / props.xdistBase;
    props.changeCurrentItem(markerIndex);
    props.panTo(markerIndex);

    gaTrackEvent(TRACKEVENT_CATEGORY.SEARCH_MODAL, "SwipeCarouselItem");
  };

  const initSwipePosition = () => {
    swipeStartPosition = 0;
    swipeOffsetPosition = 0;
  };

  return (
    <Item key={props.spot.spot_id} transform={props.transform}>
      <Inner
        onClick={() =>
          handleClickSpot(props.spot.spot_id, props.spot.name, props.mode)
        }
        onTouchStart={(event: TouchEvent) => {
          handleTouchStart(event);
        }}
        onTouchMove={(event: TouchEvent) => {
          handleTouchMove(event);
        }}
        onTouchEnd={() => {
          handleTouchEnd(props.xdist);
        }}
        onMouseDown={(event: MouseEvent) => {
          handleMouseDown(event);
        }}
        onMouseMove={(event: MouseEvent) => {
          handleMouseMove(event);
        }}
        onMouseUp={() => {
          handleMouseUp(props.xdist);
        }}
      >
        <Pict>{spotImage}</Pict>
        <Info>
          <Name>{props.spot.name}</Name>
          <Address>{props.spot.address}</Address>
          <Distance>現在地から{props.spot.distance}</Distance>
          <Scores>
            <div>
              <span className="fa fa-signal " />
              <EvalNum>{props.spot.pageview}</EvalNum>
            </div>
            <div>
              <span className="fa fa-camera" />
              <EvalNum>{props.spot.post_count}</EvalNum>
            </div>
            <ViewMore>
              <span>詳細を見る</span>
            </ViewMore>
          </Scores>
        </Info>
      </Inner>
    </Item>
  );
};
export default SearchSpotCarouselItem;

const Item = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  padding: 0 4px;
  transform: ${(props: IItemProps) => props.transform};
  cursor: pointer;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 4px;
  width: 310px;
  height: 100px;
  background-color: ${Color.BG_COLOR_BOX};
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
`;

const Pict = styled.div`
  width: 92px;
  min-width: 92px;
  height: 92px;
  min-height: 92px;
  background-color: ${Color.LOADING_PLACE_HOLDER_COLOR};
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding-left: 4px;
  width: 100%;
`;

const Name = styled.div`
  ${fontsize(14)}
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  white-space: normal;
  margin-bottom: 1px;
`;

const Address = styled.div`
  ${fontsize(12)}
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  white-space: normal;
  color: ${Color.SUB_COLOR_GOLD};
  margin-bottom: 1px;
`;

const Distance = styled.div`
  ${fontsize(12)}
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  white-space: normal;
  color: ${Color.SUB_COLOR_GOLD};
  margin-bottom: 1px;
`;

const Scores = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: auto;
  align-items: center;
  ${fontsize(14)}
`;

const EvalNum = styled.span`
  margin-left: 4px;
  margin-right: 8px;
`;

const ViewMore = styled.div`
  margin-left: auto;
  color: ${Color.FONT_COLOR_REVERSAL};
  background-color: ${Color.SUB_COLOR_PINK};
  padding: 0 8px;
  border-radius: 4px;
  margin-right: 2px;
  margin-bottom: 4px;
`;
