import { get, post } from "../network/api-client";
import { isNullOrUndefined } from "../../lib/object-util";
import { isPostType } from "../../lib/post-type-util";

/**
 * キーワードからスポットを検索するAPI
 * @param kw
 * @param prefectureId
 */
export const searchByKeyword = async (
  kw: string,
  prefectureId?: number
): Promise<IRedsealAPIResponse<ISpotsSearch[]>> => {
  try {
    var API_URI = "/api/spots/search/keyword?kw=<kw>";
    if (!isNullOrUndefined(prefectureId)) {
      API_URI = `${API_URI}&prefecture_id=${prefectureId}`;
    }
    return await get<ISpotsSearch[]>(API_URI.replace("<kw>", kw));
  } catch (err) {
    throw err;
  }
};

/**
 * 位置情報からスポットを検索するAPI
 * @param latitude
 * @param longitude
 */
export const searchByGeo = async (
  latitude: string,
  longitude: string
): Promise<IRedsealAPIResponse<ISpotsSearch[]>> => {
  try {
    const API_URI = "/api/spots/search/geo?lat=<latitude>&lon=<longitude>";
    return await get<ISpotsSearch[]>(
      API_URI.replace("<latitude>", latitude).replace("<longitude>", longitude)
    );
  } catch (err) {
    throw err;
  }
};

/**
 * 指定のスポットIDから近隣のスポットを検索するAPI
 * @param spotId
 */
export const aroundById = async (
  spotId: number
): Promise<IRedsealAPIResponse<ISpotsSearch[]>> => {
  try {
    const API_URI = "/api/spots/search/around?spot_id=<spotId>";
    return await get<ISpotsSearch[]>(
      API_URI.replace("<spotId>", spotId.toString(10))
    );
  } catch (err) {
    throw err;
  }
};

/**
 * 行きたいを更新するAPI
 * @param form
 */
export const wish = async (
  form: FormData
): Promise<IRedsealAPIResponse<ISpotWish>> => {
  try {
    const API_URI = "/api/spots/wish";
    return await post<ISpotWish>(API_URI, form);
  } catch (err) {
    throw err;
  }
};

/**
 * 公式ユーザを取得するAPI
 * @param spotId 
 */
export const getCertifiedUser = async (
  spotId: number
): Promise<IRedsealAPIResponse<ICertifiedUser>> => {
  try {
    const API_URI = "/api/spots/<spotId>/certified";
    return await get<ICertifiedUser>(API_URI.replace("<spotId>", spotId.toString(10)));
  } catch (err) {
    throw err;
  }
};

export const spotShowType = async (
  spotId: number,
  postType: string,
  postId: number | null,
  page: number
): Promise<IRedsealAPIResponse<IPostContainer>> => {
  try {
    const params = [];
    if (postId !== null) {
      params.push(`post_id=${postId.toString(10)}`);
    }
    params.push(`page=${page.toString(10)}`);

    const API_URI = "/api/spots/<spotId>/<postType>";
    return await get<IPostContainer>(
      `${API_URI.replace("<spotId>", spotId.toString(10)).replace(
        "<postType>",
        postType
      )}?${params.join("&")}`
    );
  } catch (err) {
    throw err;
  }
};
