import * as React from "react";
import styled from "styled-components";
import { fontsize } from "../../const/Mixin";
import Color from "../../const/Color";
import FontAwesome from "../FontAwesome";

interface IProps {
  selected: string;
  fn(arg0: string): void;
}

const PostViewTypeSwitch: React.FC<IProps> = (props): JSX.Element => {
  const handleSwitchViewType = (viewType: string) => {
    props.fn(viewType);
  };

  return (
    <Wrapper>
      <ViewType
        className={props.selected === "list" ? "selected" : ""}
        onClick={() => handleSwitchViewType("list")}
      >
        <ViewTypeIcon>
          <FontAwesome className={"fa fa-list"} />
        </ViewTypeIcon>
        <ViewTypeName>投稿</ViewTypeName>
      </ViewType>
      <ViewType
        className={props.selected === "tile" ? "selected" : ""}
        onClick={() => handleSwitchViewType("tile")}
      >
        <ViewTypeIcon>
          <FontAwesome className={"fa fa-th"} />
        </ViewTypeIcon>
        <ViewTypeName>写真</ViewTypeName>
      </ViewType>
    </Wrapper>
  );
};
export default PostViewTypeSwitch;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const ViewType = styled.div`
  margin: auto 8px;
  ${fontsize(24)}
  &.selected {
    color: ${Color.SUB_COLOR_BLUE};
  }
  &:hover {
    cursor: pointer;
  }
`;

const ViewTypeIcon = styled.div`
  line-height: 1;
`;

const ViewTypeName = styled.div`
  ${fontsize(12)}
`;
