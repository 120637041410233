import * as React from "react";
import styled from "styled-components";
import Color from "../../const/Color";
import Loading from "../../atoms/Loading";
import { OverlayState, overlaySelector } from "../../../modules/overlayModule";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";

interface IProps {}

const Overlay: React.FC<IProps> = (props): JSX.Element => {
  const dispatch = useDispatch();
  const overlayState: OverlayState = useSelector(overlaySelector);

  return (
    <CSSTransition
      in={overlayState.isShow}
      timeout={0}
      classNames="overlay"
      unmountOnExit
    >
      <Wrapper theme={{ zindex: overlayState.zindex }}>
        <Loading />
        <Message>{overlayState.message}</Message>
      </Wrapper>
    </CSSTransition>
  );
};
export default Overlay;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.8);
  padding: 16px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${Color.FONT_COLOR_REVERSAL};
  z-index: ${({ theme }) => theme.zindex};

  transition: opacity 300ms ease-in-out;
  &.overlay-enter {
    opacity: 0;
  }
  &.overlay-enter-active {
    opacity: 1;
  }
  &.overlay-enter-done {
    opacity: 1;
  }
  &.overlay-exit {
    opacity: 1;
  }
  &.overlay-exit-active {
    opacity: 0;
  }
  &.overlay-exit-done {
    opacity: 0;
  }
`;

const Message = styled.div`
  color: ${Color.FONT_COLOR_REVERSAL};
  margin-top: 8px;
`;
