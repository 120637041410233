import * as React from "react";
import styled from "styled-components";
import Color from "../../const/Color";
import { useSelector } from "react-redux";
import { lineClamp, fontsize, toolTipTop } from "../../const/Mixin";
import CircleUser from "../../atoms/CircleUser";
import Link from "../../atoms/Link";
import EvaluateButton from "../../atoms/EvaluateButton";
import Paragraph from "../../atoms/Paragraph";
import FontAwesome from "../../atoms/FontAwesome";
import EvaluationConst from "../../const/EvaluationConst";
import {
  gaTrackEvent,
  TRACKEVENT_CATEGORY,
} from "src/js/redseal/lib/analytics-util";
import { AccountState, accountSelector } from "../../../modules/accountModule";
import ModalPortal from "../../organisms/ModalPortal";
import PostEditOptionModal from "../../organisms/PostEditOptionModal";
import { useState } from "react";
import BodyFixed from "src/js/redseal/lib/BodyFixed";

interface IProps {
  comment: IComment;
  user: IUser;
}

const PostContainerComment: React.FC<IProps> = (props): JSX.Element => {

  const accountState: AccountState = useSelector(accountSelector);

  // Local state
  const [isShowPostEditModal, setIsShowPostEditModal] = useState(false);

  const isIncludesComment = (commentId: number): boolean => {
    return accountState.evaluateComments.indexOf(commentId) > -1;
  };

  /**
   * 投稿編集モーダル オープン
   */
  const handleEditOption = () => {
    handlePostEditModalState(true);
    gaTrackEvent(
      TRACKEVENT_CATEGORY.POST,
      "OpenCommentEditOptionModal",
      props.comment.comment_id
    );
  };

  /**
   * 投稿編集モーダルの状態管理
   * @param isShow 
   */
  const handlePostEditModalState = (isShow: boolean) => {
    if (isShow) {
      BodyFixed.instance.on();
    } else {
      BodyFixed.instance.off();
    }
    setIsShowPostEditModal(isShow);
  };

  return (
    <React.Fragment>
      <Separator />
      <Comment>
        <CommentContent>
          <CircleUser
            userId={props.user.user_id}
            profileImagePath={props.user.profile_image_100}
            name={props.user.name}
            slug={props.user.slug}
            width={32}
          />
          <CommentUserNameDesc>
            {!props.user.is_withdraw && (
              <CommentUserOption>
                <CommentUserName>
                  <Link url={`/users/${props.user.uid}`}>
                    {props.user.name}
                  </Link>
                  {props.user.is_certified && (
                    <FontAwesome className={"fa fa-check-circle certified"} />
                  )}
                </CommentUserName>
                <CommentDate>{props.comment.comment_created}</CommentDate>
                {accountState.myComments.includes(props.comment.comment_id) && (
                  <CommentEditOption data-tooltip="コメントの編集" onClick={() => handleEditOption()}>
                    <FontAwesome className={"fa fa-ellipsis-h"} />
                  </CommentEditOption>
                )}
              </CommentUserOption>
            )}
            <CommentDesc>
              <Paragraph content={props.comment.description} />
            </CommentDesc>
          </CommentUserNameDesc>
        </CommentContent>
        {(() => {
          if (props.user.is_withdraw) {
            return null;
          } else if (!props.user.is_accept_good) {
            return null;
          } else {
            return (
              <CommentFooter>
                <EvaluateButton
                  isGood={isIncludesComment(props.comment.comment_id)}
                  evaluableId={props.comment.comment_id}
                  evaluableType={EvaluationConst.TYPE_COMMENT}
                  width={20}
                />
                {props.comment.good_num > 0 && (
                  <CommentGoodNum>{props.comment.good_num}</CommentGoodNum>
                )}
              </CommentFooter>
            );
          }
        })()}
      </Comment>
      <ModalPortal targetId={"post_edit_option_modal"}>
        <PostEditOptionModal
          targetId={props.comment.comment_id}
          targetType={"Comment"}
          isShowModal={isShowPostEditModal}
          setIsShowModal={handlePostEditModalState}
        />
      </ModalPortal>
    </React.Fragment>
  );
};
export default PostContainerComment;

const Separator = styled.div`
  border-top: 1px solid ${Color.LIST_BORDER_COLOR};
  margin: 0 16px;
`;

const Comment = styled.div`
  padding: 16px;
`;

const CommentContent = styled.div`
  display: flex;
`;

const CommentUserNameDesc = styled.div`
  margin-left: 16px;
  width: 100%;
  span.certified {
    margin-left: 2px;
  }
`;

const CommentUserOption = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CommentUserName = styled.div`
  margin-right: auto;
  ${lineClamp(1)}
  a {
    color: ${Color.SUB_COLOR_BLUE};
    text-decoration: none;
  }
  a:link {
    color: ${Color.SUB_COLOR_BLUE};
    text-decoration: none;
  }
  a:hover {
    color: ${Color.SUB_COLOR_BLUE};
    text-decoration: underline;
  }
  a:visited {
    color: ${Color.SUB_COLOR_BLUE};
    text-decoration: none;
  }
`;

const CommentEditOption = styled.div`
  height: 24px;
  width: 24px;
  cursor: pointer;
  margin-left: 8px;
  text-align: center;
  border-radius: 12px;
  color: ${Color.FONT_COLOR_GRAY};
  &:hover {
    background-color: ${Color.BG_COLOR_BOX_HOVER};
    color: ${Color.FONT_COLOR};
  }

  ${toolTipTop()}
`;

const CommentDesc = styled.div`
  ${fontsize(14)}
  word-break: break-all;
`;

const CommentFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CommentDate = styled.div`
  color: ${Color.SUB_COLOR_GOLD};
  ${fontsize(12)}
  margin: auto 0 auto 8px;
`;

const CommentGoodNum = styled.div`
  color: ${Color.SUB_COLOR_GOLD};
  ${fontsize(12)}
  margin: auto 0 auto 8px;
`;
