import * as React from "react";
import styled from "styled-components";
import Loading from "../../atoms/Loading";
import Color from "../../const/Color";

const PostTileListPlaceholder: React.FC = (): JSX.Element => {
  return (
    <Wrapper>
      <Tile>
        <Inner>
          <PostPicture>
            <Loading />
          </PostPicture>
        </Inner>
      </Tile>
      <Tile>
        <Inner>
          <PostPicture>
            <Loading />
          </PostPicture>
        </Inner>
      </Tile>
      <Tile>
        <Inner>
          <PostPicture>
            <Loading />
          </PostPicture>
        </Inner>
      </Tile>
      <Tile>
        <Inner>
          <PostPicture>
            <Loading />
          </PostPicture>
        </Inner>
      </Tile>
      <Tile>
        <Inner>
          <PostPicture>
            <Loading />
          </PostPicture>
        </Inner>
      </Tile>
      <Tile>
        <Inner>
          <PostPicture>
            <Loading />
          </PostPicture>
        </Inner>
      </Tile>
    </Wrapper>
  );
};
export default PostTileListPlaceholder;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

const Tile = styled.div`
  width: 33%;
  width: calc(100% / 3);
  padding: 4px;
  @media screen and (max-width: 768px) {
    padding: 1px;
  }
`;

const Inner = styled.div`
  position: relative;
  &:before {
    display: block;
    content: "";
    padding-top: 100%;
  }
`;

const PostPicture = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  box-sizing: border-box;
  background: ${Color.LOADING_PLACE_HOLDER_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
`;
