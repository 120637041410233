import * as React from "react";
import styled, { keyframes, css } from "styled-components";
import FontAwesome from "../../atoms/FontAwesome/FontAwesome";
import Color from "../../const/Color";
import { fontsize } from "../../const/Mixin";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Link from "../Link";
import {
  notificationCountAction,
  AccountState,
  accountSelector,
  notificationDeleteAction,
} from "../../../modules/accountModule";
import UserConst from "../../const/UserConst";
import { getBadgeText } from "src/js/redseal/lib/user-util";

interface IBadgeProps {
  notificationCount: number;
}

const NotificationButton: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const accountState: AccountState = useSelector(accountSelector);

  const didMount = async () => {
    if (location.pathname === "/users/my/notifications") {
      notificationDeleteAction(dispatch);
    } else {
      notificationCountAction(dispatch);
    }
  };

  useEffect(() => {
    didMount();
  }, []);

  return (
    <Wrapper>
      <Link url="/users/my/notifications" target="_self">
        <FontAwesome className="fa fa-bell" />
        {accountState.notificationCount > UserConst.DISP_NOTIFICATION_NUM_MIN && (
          <Badge notificationCount={accountState.notificationCount}>
            {getBadgeText(accountState.notificationCount)}
          </Badge>
        )}
      </Link>
    </Wrapper>
  );
};
export default NotificationButton;

const Wrapper = styled.div`
  height: 40px;
  width: 40px;
  background-color: ${Color.BG_COLOR_BOX};
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 20px;
  color: ${Color.SUB_COLOR_PINK};
  text-align: center;
  padding: 0;
  line-height: 38px;
  a {
    color: ${Color.SUB_COLOR_PINK};
    text-decoration: none;
  }
  a:link {
    color: ${Color.SUB_COLOR_PINK};
    text-decoration: none;
  }
  a:hover {
    color: ${Color.SUB_COLOR_PINK};
    text-decoration: none;
  }
  a:visited {
    color: ${Color.SUB_COLOR_PINK};
    text-decoration: none;
  }
  position: relative;
`;

const BounceIn = keyframes`
  0%, 20%, 40%, 60%, 80%, to {
    animation-timing-function:cubic-bezier(.215, .61, .355, 1)
  }

  0% {
    opacity: 0;
    transform:scale3d(.3, .3, .3)
  }

  20% {
    transform:scale3d(1.1, 1.1, 1.1)
  }

  40% {
    transform:scale3d(.9, .9, .9)
  }

  60% {
    opacity: 1;
    transform:scale3d(1.03, 1.03, 1.03)
  }

  80% {
    transform:scale3d(.97, .97, .97)
  }

  to {
    opacity: 1;
    transform:scaleX(1)
  }
`;

const Badge = styled.div<IBadgeProps>`
  ${(props: IBadgeProps) =>
    props.notificationCount > UserConst.DISP_NOTIFICATION_NUM_MIN
      ? css`
          animation: ${BounceIn} 1s linear 1s 1;
          animation-fill-mode: both;
        `
      : ""}

  background-color: ${Color.SUB_COLOR_PINK};
  border-radius: 9px;
  color: ${Color.FONT_COLOR_REVERSAL};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -4px;
  right: -4px;
  height: 18px;
  min-width: 18px;
  ${fontsize(10)}
  font-weight: 600;
`;
