import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action, Dispatch } from "redux";
import { RedsealState } from "../store/createStore";
import Zindex from "../components/const/Zindex";

// state -------
export interface MessageBoxState {
  isShow: boolean;
  message: string;
  zindex: number;
}

const MessageBoxInitialState: MessageBoxState = {
  isShow: false,
  message: "",
  zindex: Zindex.MESSAGE_BOX
};

// payload -------
export interface ShowAction extends Action {
  type: string;
  message: string;
}

export interface HideAction extends Action {
  type: string;
}

// selector -------
export const messageBoxSelector = (state: RedsealState): MessageBoxState => {
  return state.messageBox;
};

// actions & reducers -------
export const messageBoxModule = createSlice({
  name: "messageBox",
  initialState: MessageBoxInitialState,
  reducers: {
    show: (state: MessageBoxState, action: PayloadAction<ShowAction>) => {
      state.isShow = true;
      state.message = action.payload.message;
    },
    hide: (state: MessageBoxState, action: PayloadAction<HideAction>) => {
      state.isShow = false;
    }
  }
});

// actions -------
export const showMessageBoxAction = (
  dispatch: Dispatch<Action<any>>,
  message: string
) => {
  dispatch(
    messageBoxModule.actions.show({
      type: "show",
      message: message
    })
  );
};

export const hideMessageBoxAction = (dispatch: Dispatch<Action<any>>) => {
  dispatch(
    messageBoxModule.actions.hide({
      type: "hide"
    })
  );
};
