import * as React from "react";
import LazyLoad from "react-lazyload";

interface IProps {
  url: string;
  size: string;
  className: string;
  height: number;
}

const BackgroundImage: React.FC<IProps> = (props): JSX.Element => {
  const style = {
    backgroundSize: `${props.size}`,
    backgroundImage: `url('${props.url}')`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat no-repeat",
    height: `${props.height}px`
  };
  return (
    <LazyLoad hight={props.height} once>
      <div className={props.className} style={style} />
    </LazyLoad>
  );
};

export default BackgroundImage;
