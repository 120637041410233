import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action, Dispatch } from "redux";
import { RedsealState } from "../store/createStore";
import { around, usecaseSpotsWish } from "../../domain/usecases/spots";

// state -------
export interface SpotState {
  isLoading: boolean;
  spotId: number;
  spots: ISpotsSearch[];
}

const SpotInitialState: SpotState = {
  isLoading: true,
  spotId: null,
  spots: [],
};

// payload -------
export interface AroundAction extends Action {
  type: string;
  spots: ISpotsSearch[];
}

// selector -------
export const spotSelector = (state: RedsealState): SpotState => {
  return state.spot;
};

// reducers -------
export const spotModule = createSlice({
  name: "spot",
  initialState: SpotInitialState,
  reducers: {
    around: (state: SpotState, action: PayloadAction<AroundAction>) => {
      state.isLoading = false;
      state.spots = action.payload.spots;
    },
  },
});

// actions -------
export const aroundAction = (
  dispatch: Dispatch<Action<any>>,
  spotId: number
) => {
  around(spotId).then((spots: ISpotsSearch[]) => {
    dispatch(
      spotModule.actions.around({
        type: "around",
        spots: spots,
      })
    );
  });
};

export const wishAction = (
  spotId: number,
  onSuccess: (response: ISpotWish) => void,
  onError: () => void
) => {
  const csrfToken = document.querySelector("meta[name=csrf-token]")["content"];
  const formData = new FormData();
  formData.append("authenticity_token", csrfToken);
  formData.append("spot_id", `${spotId}`);

  usecaseSpotsWish(formData)
    .then((response) => {
      onSuccess(response);
    })
    .catch((e) => {
      console.log(e);
      onError();
    });
};
