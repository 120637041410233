import * as React from "react";
import styled from "styled-components";

interface IProps {
  transitionClassName: string;
  isAdjustTopMargin: boolean;
}

const PostContainerTransitionWrapper: React.FC<IProps> = (props): JSX.Element => {
  return  <Wrapper theme={{ className: props.transitionClassName, isAdjustTopMargin: props.isAdjustTopMargin }}>{props.children}</Wrapper>;
};
export default PostContainerTransitionWrapper;

const Wrapper = styled.div`
  ${({ theme }) => theme.isAdjustTopMargin && `
    margin-top: 24px;
    @media screen and (max-width: 768px) {
      margin-top: 0px;
    }
  `}

  transition: opacity 400ms linear;
  &.${({ theme }) => theme.className}-enter {
    opacity: 0;
  }
  &.${({ theme }) => theme.className}-enter-active {
    opacity: 1;
  }
  &.${({ theme }) => theme.className}-enter-done {
    opacity: 1;
  }
  &.${({ theme }) => theme.className}-exit {
    opacity: 1;
  }
  &.${({ theme }) => theme.className}-exit-active {
    opacity: 0;
  }
  &.${({ theme }) => theme.className}-exit-done {
    opacity: 0;
  }
`;
