import * as React from "react";
import styled from "styled-components";
import FontAwesome from "../../atoms/FontAwesome/FontAwesome";
import Color from "../../const/Color";
import { fontsize } from "../../const/Mixin";
import { useDispatch, useSelector } from "react-redux";
import {
  PostFormState,
  postFormSelector,
  PostData,
  removeAction,
  rotateAction,
  editAction,
  getPostDescriptionLength,
} from "../../../modules/postFormModule";
import {
  enablePostTypes,
  postTypeColor,
  postTypeDescriptions,
} from "src/js/redseal/lib/post-type-util";
import PostConst from "../../const/PostConst";
import {
  gaTrackEvent,
  TRACKEVENT_CATEGORY,
} from "src/js/redseal/lib/analytics-util";

interface IProps {
  post: PostData;
}

interface IPostTypeLabelProps {
  postType: string;
}

const PostItem: React.FC<IProps> = (props): JSX.Element => {
  const dispatch = useDispatch();
  const postFormState: PostFormState = useSelector(postFormSelector);

  /**
   * 投稿削除ボタン.
   */
  const handleRemovePost = () => {
    removeAction(dispatch, props.post, postFormState.posts);
    gaTrackEvent(TRACKEVENT_CATEGORY.POST_MODAL, "Remove", props.post.postId);
  };

  /**
   * 画像回転ボタン.
   * @param event
   * @param angle
   * @param src
   */
  const handleRotateFile = (angle: string, src: string) => {
    rotateAction(dispatch, angle, src, props.post, postFormState.posts);
    gaTrackEvent(
      TRACKEVENT_CATEGORY.POST_MODAL,
      angle === "left" ? "RotateLeft" : "RotateRight",
      props.post.postId
    );
  };

  /**
   * 投稿タイプ選択.
   * @param event
   */
  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    editAction(
      dispatch,
      props.post,
      postFormState.posts,
      "postType",
      event.target.value
    );
    gaTrackEvent(
      TRACKEVENT_CATEGORY.POST_MODAL,
      "ChangePostType",
      props.post.postId
    );
  };

  /**
   * 本文.
   * @param event
   */
  const handleChangeTextArea = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    editAction(
      dispatch,
      props.post,
      postFormState.posts,
      "description",
      event.target.value
    );
  };

  return (
    <PostContainer>
      <ImageContainer>
        <img src={props.post.imageSrc} />
        <ImageControl>
          {postFormState.mode === PostConst.POST_MODE_CREATE && (
            <PostRemoveButton onClick={() => handleRemovePost()}>
              <FontAwesome className="fa fa-trash" />
              <span className="icon-text">削除</span>
            </PostRemoveButton>
          )}
          <ImageRotate>
            <RotateButton
              onClick={() => handleRotateFile("left", props.post.imageSrc)}
            >
              <FontAwesome className="fa fa-rotate-left" />
              <span className="icon-text">左回転</span>
            </RotateButton>
            <RotateButton
              onClick={() => handleRotateFile("right", props.post.imageSrc)}
            >
              <FontAwesome className="fa fa-rotate-right" />
              <span className="icon-text">右回転</span>
            </RotateButton>
          </ImageRotate>
        </ImageControl>
      </ImageContainer>
      <PostTypeContainer>
        <PostTypeTitle>投稿の種類</PostTypeTitle>
        <PostTypeGroup>
          {enablePostTypes.map((postType, idx) => {
            return (
              <div key={idx}>
                <PostTypeInput
                  type="radio"
                  id={`${postType.value}_${props.post.postId}`}
                  name={`post_${props.post.postId}`}
                  value={postType.value}
                  checked={props.post.postType === postType.value ? true : false}
                  postType={postType.value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangeRadio(event);
                  }}
                />
                <PostTypeLabel htmlFor={`${postType.value}_${props.post.postId}`}>
                  {postType.name}
                </PostTypeLabel>
              </div>
            );
          })}
        </PostTypeGroup>
        <PostTypeDesc>
          {postTypeDescriptions[props.post.postType]}
        </PostTypeDesc>
      </PostTypeContainer>
      <TextareaContainer>
        <textarea
          maxLength={PostConst.DESCRIPTION_MAX}
          placeholder={`コメントを入れてください (${
            PostConst.DESCRIPTION_MIN
          }文字以上${PostConst.DESCRIPTION_MAX}文字以内)`}
          value={props.post.description ? props.post.description : ""}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            handleChangeTextArea(event);
          }}
        />
        <CharCount>
          {PostConst.DESCRIPTION_MAX - getPostDescriptionLength(props.post.description)}
        </CharCount>
      </TextareaContainer>
    </PostContainer>
  );
};
export default PostItem;

// style
const PostContainer = styled.div`
  margin-top: 12px;
  width: 100%;

  &.fade-item-enter {
    opacity: 0;
  }
  &.fade-item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  &.fade-item-exit {
    opacity: 1;
  }
  &.fade-item-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  border-radius: 4px;
  text-align: center;
  img {
    max-width: 100%;
    max-height: 300px;
  }
`;

const ImageControl = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: ${Color.FONT_COLOR_REVERSAL};
  padding: 8px 0;
  ${fontsize(24)}
  span {
    padding: 0 8px;
    cursor: pointer;
    text-shadow: black 0 0 2px;
  }
`;

const PostRemoveButton = styled.div`
  margin-right: auto;
  display: flex;
  flex-direction: column;
  .icon-text {
    ${fontsize(10)}
  }

  &:hover {
    transform: scale(1.1);
  }
`;

const ImageRotate = styled.div`
  display: flex;
  margin-left: auto;
`;

const RotateButton = styled.div`
  display: flex;
  flex-direction: column;
  .icon-text {
    ${fontsize(10)}
  }
  &:hover {
    transform: scale(1.1);
  }
`;

const PostTypeContainer = styled.div`
  margin-top: 8px;
`;

const PostTypeTitle = styled.div`
  margin-bottom: 4px;
  ${fontsize(10)}
  text-align: center;
`;

const PostTypeGroup = styled.div`
  display: flex;
  justify-content: center;
`;

const PostTypeLabel = styled.label`
  border: 2px solid ${Color.FONT_COLOR_REVERSAL};
  border-radius: 16px;
  color: ${Color.FONT_COLOR_REVERSAL};
  cursor: pointer;
  ${fontsize(10)}
  font-weight: bold;
  margin: 0 4px;
  padding: 8px;
  position: relative;

`;

const PostTypeInput = styled.input`
  display: none;
  &:checked ~ label {
    background-color: ${(props: IPostTypeLabelProps) =>
      postTypeColor(props.postType)};
    border: 2px solid
      ${(props: IPostTypeLabelProps) => postTypeColor(props.postType)};

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      bottom: -10px;
      left: 16px;
      border-bottom: 6px solid white;
      border-top: 6px solid transparent;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }
  }
`;

const PostTypeDesc = styled.div`
  border: 2px solid white;
  border-radius: 3px;
  ${fontsize(10)}
  margin-top: 8px;
  padding: 8px;
`;

const TextareaContainer = styled.div`
  position: relative;
  textarea {
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 8px;
    width: 100%;
    height: 120px;
    color: ${Color.FONT_COLOR};
    padding: 4px;

    &::placeholder {
      font-size: 14px;
    }
  }
`;

const CharCount = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  color: ${Color.SUB_COLOR_GOLD};
`;
