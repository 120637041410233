import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { showMessageBoxAction } from "../../../modules/messageBoxModule";
import { accountSelector, AccountState, addImageAction, getProfileAction, postProfileAction, removePictureAction, rotateImageAction, slugCheckAction } from "../../../modules/accountModule";
import {
  TRACKEVENT_CATEGORY,
  gaTrackEvent,
} from "src/js/redseal/lib/analytics-util";
import styled from "styled-components";
import { formControl, normalBox, fontsize } from "../../const/Mixin";
import Color from "../../const/Color";
import FontAwesome from "../../atoms/FontAwesome";
import YearSelect from "../../atoms/YearSelect";
import MonthSelect from "../../atoms/MonthSelect";
import DaySelect from "../../atoms/DaySelect";
import Link from "../../atoms/Link";
import Loading from "../../atoms/Loading";
import { isAvailableSlugChar, isBlank, isNumericOnly } from "src/js/redseal/lib/string-util";
import { hideOverlayAction, showOverlayAction } from "../../../modules/overlayModule";

interface IProps {
  showWelcome: boolean;
}

const UsersMyProfilesEdit: React.FC<IProps> = (props): JSX.Element => {
  const dispatch = useDispatch();
  const accountState: AccountState = useSelector(accountSelector);

  // Local state
  const [profile, setProfile] = useState<IAccount>(null);
  const [existPicture, setExistPicture] = useState(false);
  const [tmpSlug, setTmpSlug] = useState("");
  const [validateSlugClass, setValidateSlugClass] = useState(null);

  const slugRef = useRef(null);

  const INVALID_CLASS_NAME = "invalid";

  let fileInput = null;

  useEffect(() => {
    didMount();
  }, []);

  /**
   * 初期実行
   */
  const didMount = () => {
    getProfileAction(handleOnGetProfileActionSuccess, handleOnError);
  };

  const handleOnGetProfileActionSuccess = (user: IAccount) => {
    setProfile(user);
    setTmpSlug(user.slug);
    setExistPicture(isUserSettingImage(user.profile_image_original));
  };

  const handleOnError = () => {
    showMessageBoxAction(dispatch, "予期せぬエラーが発生しました。");
  };

  const isUserSettingImage = (path: string): boolean => {
    return !/assets\/user_icon/.test(path)
  }

  /**
   * 写真選択アイコン クリックイベント
   */
  const handleOnSelectImage = () => {
    fileInput.click();
  };

  /**
   * 写真選択イベント
   * @param event 
   */
  const handleOnChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files.length <= 0) {
      return;
    }
    addImageAction(event.target.files, (dataUrl: string) => {
      const newUser = { ...profile };
      newUser.profile_image_100 = dataUrl;
      newUser.profile_image_200 = dataUrl;
      newUser.profile_image_400 = dataUrl;
      newUser.profile_image_800 = dataUrl;
      newUser.profile_image_original = dataUrl;
      setProfile(newUser);
      setExistPicture(isUserSettingImage(newUser.profile_image_original))
    })
    gaTrackEvent(TRACKEVENT_CATEGORY.USERS_MY_PROFILES_EDIT, "SelectPicture");
  };

  /**
   * 写真回転イベント
   * @param angle 
   * @param src 
   */
  const handleRotateFile = (angle: string, src: string) => {
    if (!existPicture) {
      return;
    }
    rotateImageAction(angle, src, (dataUrl: string) => {
      const newUser = { ...profile };
      newUser.profile_image_100 = dataUrl;
      newUser.profile_image_200 = dataUrl;
      newUser.profile_image_400 = dataUrl;
      newUser.profile_image_800 = dataUrl;
      newUser.profile_image_original = dataUrl;
      setProfile(newUser);
    });
    gaTrackEvent(TRACKEVENT_CATEGORY.USERS_MY_PROFILES_EDIT, "RotatePicture");
  };

  /**
   * 写真削除イベント
   * @returns 
   */
  const handleOnRemove = () => {
    if (!existPicture) {
      return;
    }
    if (!window.confirm("プロフィール画像を削除します。よろしいですか？")) {
      return;
    }

    showOverlayAction(dispatch, "削除中…");
    removePictureAction(
      dispatch,
      profile,
      handleOnRemovePictureActionSuccess,
      handleOnRemovePictureActionError
    );
    gaTrackEvent(TRACKEVENT_CATEGORY.USERS_MY_PROFILES_EDIT, "RemovePicture");
  };

  const handleOnRemovePictureActionSuccess = (user: IAccount) => {
    showMessageBoxAction(dispatch, "プロフィール画像を削除しました。");
    hideOverlayAction(dispatch);

    const newUser = { ...profile };
    newUser.profile_image_100 = user.profile_image_100;
    newUser.profile_image_200 = user.profile_image_200;
    newUser.profile_image_400 = user.profile_image_400;
    newUser.profile_image_800 = user.profile_image_800;
    newUser.profile_image_original = user.profile_image_original;
    setProfile(newUser);
    setExistPicture(isUserSettingImage(user.profile_image_original));
  };

  const handleOnRemovePictureActionError = () => {
    showMessageBoxAction(dispatch, "申し訳ありません。削除に失敗しました。時間を空けて再度お試しください。");
    hideOverlayAction(dispatch);
  };

  /**
   * 名前.
   * @param event
   */
   const handleOnChangeName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const newUser = { ...profile };
    newUser.name = event.target.value;
    setProfile(newUser);
  };

  const handleOnBlurName = () => {
    if (validateName(profile.name) === INVALID_CLASS_NAME) {
      showMessageBoxAction(dispatch, "名前は1文字以上15文字以内で設定してください。");
      return false;
    }
    return true;
  };

  const validateName = (value: string): string => {
    if (isBlank(value) || (value.length >= 1 && value.length <= 15)) {
      return "";
    }
    return INVALID_CLASS_NAME;
  };

  /**
   * スラッグ.
   * @param event
   */
   const handleOnChangeSlug = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    setTmpSlug(event.target.value);
  };

  const handleOnBlurSlug = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    const slug = event.target.value;
    if (!validateSlugChar(slug)) {
      return false;
    }
    if (!isBlank(slug)) {
      slugCheckAction(
        slug,
        handleOnSlugCheckActionSuccess,
        handleOnSlugCheckActionError
      );
    }
  };

  const validateSlugChar = (slug: string): boolean => {
    if (!validateSlug(slug)) {
      setValidateSlugClass(INVALID_CLASS_NAME);
      slugRef.current.focus();
      showMessageBoxAction(dispatch, "ユニークURLは15文字以内の半角英数字で設定してください。ただし数字のみは使用できません。");
      return false;
    }
    return true;
  }

  const validateSlug = (value: string): boolean => {
    if (isBlank(value)) {
      return true;
    }
    if (!isNumericOnly(value)) {
      return false;
    }
    if (!isAvailableSlugChar(value)) {
      return false;
    }

    return true;
  };

  const handleOnSlugCheckActionSuccess = (user: IAccount) => {
    setValidateSlugClass(null);
  };

  const handleOnSlugCheckActionError = (message: string) => {
    setValidateSlugClass(INVALID_CLASS_NAME);
    slugRef.current.focus();
    showMessageBoxAction(dispatch, message);
    hideOverlayAction(dispatch);
  };

  /**
   * 自己紹介.
   * @param event
   */
  const handleOnChangeDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    const newUser = { ...profile };
    newUser.description = event.target.value;
    setProfile(newUser);
  };

  const handleOnBlurDescription = () => {
    if (validateDescription(profile.description) === INVALID_CLASS_NAME) {
      showMessageBoxAction(dispatch, "自己紹介は10文字以上400文字以内で設定してください。");
      return false;
    }
    return true;
  };

  const validateDescription = (value: string): string => {
    if (isBlank(value) || (value.length >= 10 && value.length <= 400)) {
      return "";
    }
    return INVALID_CLASS_NAME;
  };

  /**
   * 生年月日.
   * @param year 
   */
   const _handleOnBirthday = (index: number, value: number) => {
    const newUser = { ...profile };
    const birthdayArray = profile.birthday.split("-");
    birthdayArray[index] = value.toString(10);
    newUser.birthday = birthdayArray.join("-");
    setProfile(newUser);
  }

  const handleOnSelectYear = (year: number) => {
    _handleOnBirthday(0, year);
  };

  const handleOnSelectMonth = (month: number) => {
    _handleOnBirthday(1, month);
  };

  const handleOnSelectDay = (day: number) => {
    _handleOnBirthday(2, day);
  };

  /**
   * 性別ラジオクリックイベント
   * @param event 
   */
  const handleOnGenderRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newUser = { ...profile };
    newUser.gender = parseInt(event.target.value);
    setProfile(newUser);
  };

  /**
   * 郵便番号.
   * @param event
   */
   const handleOnChangeZipCode = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const newUser = { ...profile };
    newUser.zip_code = event.target.value;
    setProfile(newUser);
  };

  /**
   * 保存イベント
   */
  const handleOnSubmit = () => {
    // slug check
    if (!validateSlugChar(tmpSlug)) {
      return false;
    }

    if (!window.confirm("プロフィール情報を保存します。よろしいですか？")) {
      return;
    }

    showOverlayAction(dispatch, "保存中…");

    const newUser = { ...profile };
    newUser.slug = tmpSlug;
    setProfile(newUser);

    postProfileAction(
      dispatch,
      newUser,
      handleOnPostProfileActionSuccess,
      handleOnPostProfileActionError
    );
    gaTrackEvent(TRACKEVENT_CATEGORY.USERS_MY_PROFILES_EDIT, "Save");
  };

  const handleOnPostProfileActionSuccess = (user: IAccount) => {
    showMessageBoxAction(dispatch, "プロフィールを保存しました。");
    setExistPicture(isUserSettingImage(user.profile_image_original));
    hideOverlayAction(dispatch);  
  };

  const handleOnPostProfileActionError = () => {
    showMessageBoxAction(dispatch, "申し訳ありません。保存に失敗しました。時間を空けて再度お試しください。");
    hideOverlayAction(dispatch);
  };

  const getBirthdayYear = (birthday: string): number => {
    if (isBlank(birthday)) {
      return 2021;
    }
    const birthdayArray = birthday.split("-");
    if (birthdayArray.length != 3) {
      return 2021;
    }
    return parseInt(birthdayArray[0]);
  };

  const getBirthdayMonth = (birthday: string): number => {
    if (isBlank(birthday)) {
      return 1;
    }
    const birthdayArray = birthday.split("-");
    if (birthdayArray.length != 3) {
      return 1;
    }
    return parseInt(birthdayArray[1]);
  };

  const getBirthdayDay = (birthday: string): number => {
    if (isBlank(birthday)) {
      return 1;
    }
    const birthdayArray = birthday.split("-");
    if (birthdayArray.length != 3) {
      return 1;
    }
    return parseInt(birthdayArray[2]);
  };

  if (profile === null) {
    return <Wrapper><Loading /></Wrapper>;
  }

  return (
    <Wrapper>
      <h1>プロフィールを編集</h1>
      {props.showWelcome && (
        <Welcome>
          <p>
            ようこそ O<span className="logo">m</span>a<span className="logo">i</span>r<span className="logo">i</span> へ！<br />
            <br />
            ユーザー登録していただきありがとうございます。<br />
            Omairi はユーザーのみなさんで作るサービスです。Omairi を一層楽しんでいただくために、あなたのプロフィール情報をぜひ設定してください。<br />
            <br />
            このサービスがあなたの世界を広げる一助になれれば幸いです。
          </p>
        </Welcome>
      )}
      <FormGroup>
        <Label>プロフィール写真</Label>
        <ImageContainer>
          <img src={profile.profile_image_800} />
          <ImageControl>
            <ImageControlButton
              onClick={() => handleOnSelectImage()}
            >
              <FontAwesome className="fa fa-camera" />
              <span className="icon-text">選択</span>
              <input
                    type="file"
                    name="profile[picture]"
                    id="profile_picture"
                    ref={(input: HTMLInputElement) => {
                      fileInput = input;
                    }}
                    onChange={(event) => handleOnChangeFile(event)}
              />
            </ImageControlButton>
            <ImageControlButton
              theme={{ disabled: !existPicture }}
              onClick={() => handleRotateFile("left", profile.profile_image_original)}
            >
              <FontAwesome className="fa fa-rotate-left" />
              <span className="icon-text">左回転</span>
            </ImageControlButton>
            <ImageControlButton
              theme={{ disabled: !existPicture }}
              onClick={() => handleRotateFile("right", profile.profile_image_original)}
            >
              <FontAwesome className="fa fa-rotate-right" />
              <span className="icon-text">右回転</span>
            </ImageControlButton>
            <ImageControlButton
              theme={{ disabled: !existPicture }}
              onClick={() => handleOnRemove()}
            >
              <FontAwesome className="fa fa-trash" />
              <span className="icon-text">削除</span>
            </ImageControlButton>
        </ImageControl>
      </ImageContainer>
      </FormGroup>
      <FormGroup>
        <Label>名前</Label>
        <Input
          className={validateName(profile.name)}
          maxLength={15}
          value={profile.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleOnChangeName(event);
          }}
          onBlur={() => {
            handleOnBlurName();
          }}
        />
        <Notice className={validateName(profile.name)}>
          <FontAwesome className="fa fa-asterisk" />
          サイト上に表示される名称になります。1文字以上15文字以内で設定してください。
        </Notice>
      </FormGroup>
      <FormGroup>
        <Label>ユニークURL</Label>
        <UniqueURL>
          <span>https://omairi.club/users/</span>
          <Input
            ref={slugRef}
            className={validateSlugClass}
            maxLength={15}
            value={tmpSlug}
            disabled={!isBlank(profile.slug)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleOnChangeSlug(event)
            }}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              handleOnBlurSlug(event);
            }}  
          />
        </UniqueURL>
        <Notice>
          <FontAwesome className="fa fa-asterisk" />
          あなたのプロフィールページのURLになります。一度しか設定できないので慎重に設定してください。
        </Notice>
        <Notice>
          <FontAwesome className="fa fa-asterisk" />
          使用できる文字は半角英数字と記号[-][_]です。ただし数字のみは使用できません。
        </Notice>
      </FormGroup>
      <FormGroup>
        <Label>自己紹介</Label>
        <div>
          <Textarea
            className={validateDescription(profile.description)}
            value={profile.description}
            placeholder={"コメントを書く…"}
            maxLength={400}
            rows={60}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              handleOnChangeDescription(event);
            }}
            onBlur={() => {
              handleOnBlurDescription();
            }}  
           />
        </div>
        <Notice className={validateDescription(profile.description)}>
          <FontAwesome className="fa fa-asterisk" />
          10文字以上400文字以内で設定してください。
        </Notice>
      </FormGroup>
      <FormGroup>
        <Label>生年月日</Label>
        <BirthDay>
          <YearSelect
            selected={getBirthdayYear(profile.birthday)}
            fn={handleOnSelectYear}
          />
          <MonthSelect
            selected={getBirthdayMonth(profile.birthday)}
            fn={handleOnSelectMonth}
          />
          <DaySelect
            selected={getBirthdayDay(profile.birthday)}
            fn={handleOnSelectDay}
          />
        </BirthDay>
        <Notice>
          <FontAwesome className="fa fa-asterisk" />
          パスワードを再発行する際に本人確認のために使用します。未設定ですとパスワードの再発行はできません。必ずしも実際の生年月日である必要はございませんので何かしらの年月日を設定しておいてください。
        </Notice>
      </FormGroup>
      <FormGroup>
        <Label>性別</Label>
        <Gender>
          <input
            type="radio"
            value="1"
            checked={profile.gender === 1 ? true : false}
            id="profile_gender_1"
            name="profile_gender"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleOnGenderRadio(event);
            }}
          />
          <label htmlFor="profile_gender_1">男</label>
          <input
            type="radio"
            value="2"
            checked={profile.gender === 2 ? true : false}
            id="profile_gender_2"
            name="profile_gender"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleOnGenderRadio(event);
            }}
          />
          <label htmlFor="profile_gender_2">女</label>
          <input
            type="radio"
            value="0"
            checked={profile.gender === 0 ? true : false}
            id="profile_gender_0"
            name="profile_gender"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleOnGenderRadio(event);
            }}
          />
          <label htmlFor="profile_gender_0">ないしょ</label>
        </Gender>
      </FormGroup>
      <FormGroup>
        <Label>郵便番号</Label>
        <Input
          value={profile.zip_code}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleOnChangeZipCode(event);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>メールアドレス</Label>
        <div>{profile.email}</div>
        <Notice>
          <FontAwesome className="fa fa-angle-double-right" />
          <Link url={"/users/email/new"}>
            メールアドレスを変更する
          </Link>
        </Notice>
      </FormGroup>
      <FormGroup>
        <SubmitButton
          type="button"
          onClick={handleOnSubmit}
          disabled={false}
        >
          変更を保存する
        </SubmitButton>
      </FormGroup>
    </Wrapper>
  );
};
export default UsersMyProfilesEdit;

const Wrapper = styled.div`
  ${normalBox()};
  padding: 16px;
  margin-top: 24px;
  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
  h1 {
    margin: 0 0 32px 0;
  }
`;

const Welcome = styled.div`
    ${normalBox()}
    padding: 8px;
    margin-bottom: 16px;

    span.logo {
      color: ${Color.MAIN_COLOR};
    }
`;

const FormGroup = styled.div`
  margin-bottom: 16px;

  input.invalid {
    border: 1px solid ${Color.SUB_COLOR_RED};
  }
`;

const Label = styled.label`
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  img {
    background: ${Color.LIST_BORDER_COLOR};
    border-radius: 65px;
    width: 130px;
    height: 130px;
    object-fit: cover;
  }
`;

const ImageControl = styled.div`
  display: flex;
  justify-content: center;
  bottom: 0;
  width: 100%;
  padding: 8px 0;
  ${fontsize(24)}
  span {
    padding: 0 8px;
    cursor: pointer;
  }
`;

const ImageControlButton = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  .fa {
    color: ${Color.FONT_COLOR};
  }
  .icon-text {
    ${fontsize(10)}
  }

  input {
    display: none;
  }

  ${( {theme} ) => theme.disabled && `
    opacity: .5;
  `}
  ${( {theme} ) => !theme.disabled && `
    &:hover {
      transform: scale(1.1);
    }
  `}
`;

const Input = styled.input`
  color: ${Color.FONT_COLOR};
  width: 100%;
  ${formControl()}
`;

const Textarea = styled.textarea`
  border: 1px solid #ccc;  
  border-radius: 4px;
  margin-top: 8px;
  width: 100%;
  height: 120px;
  color: ${Color.FONT_COLOR};
  padding: 6px 12px;

  &::placeholder {
    font-size: 14px;
  }

  &.invalid {
    border: 1px solid ${Color.SUB_COLOR_RED}; 
  }
`;

const UniqueURL = styled.div`
  display: table;
  border-collapse: separate;
  width: 100%;
  span {
    display: table-cell;
    background-color: #eeeeee;
    border: 1px solid #ccc;
    color: #555555;
    padding: 6px 12px;
    line-height: 1;
  }
  input {
    display: table-cell;

    &.invalid {
      border: 1px solid ${Color.SUB_COLOR_RED}; 
    }

    &:disabled {
      background-color: #eeeeee;
    }
  }
`;

const Notice = styled.div`
  color: ${Color.SUB_COLOR_GOLD};
  margin-top: 8px;
  ${fontsize(12)}

  &.invalid {
    color: ${Color.SUB_COLOR_RED};
  }
`;

const BirthDay = styled.div`
  display: flex;
`;

const Gender = styled.div`
  display: flex;
  label {
    margin: 0 8px;
  }
`;

const SubmitButton = styled.button`
  background: ${Color.SUB_COLOR_RED};
  border: 3px solid ${Color.SUB_COLOR_RED};
  border-radius: 19px;
  color: ${Color.FONT_COLOR_REVERSAL};
  cursor: pointer;
  font-weight: bold;
  padding: 8px;
  text-align: center;
  width: 100%;
  &:hover {
    opacity: 0.8;
  }
`;
