import * as React from "react";
import styled from "styled-components";
import SearchSpotCarouselItem from "../SearchSpotCarouselItem";
import { useRef, useEffect, useState } from "react";
import GoogleMap from "src/js/redseal/lib/GoogleMap";

interface IProps {
  mode: string;
  spots: ISpotsSearch[];
  latitude: number | null;
  longitude: number | null;
}

/**
 *
 * @param props
 */
const SearchSpotMap: React.FC<IProps> = (props): JSX.Element => {
  const XDIST_BASE = 318;

  // Local state
  const [isUpdatedMap, setIsUpdatedMap] = useState(false);

  const didMount = (
    ref: React.MutableRefObject<any>,
    spots: ISpotsSearch[],
    latitude: number | null,
    longitude: number | null
  ) => {
    if (latitude !== null && longitude !== null) {
      const map = new GoogleMap(ref, latitude, longitude);
      map.setMarkers(spots);
      mapRef.current = map;
      setIsUpdatedMap(true);
    }

    // const script = document.createElement("script");
    // script.type = "text/javascript";
    // script.src = `https://maps.googleapis.com/maps/api/js?key=xxxxx`;
    // script.id = "googleMaps";
    // document.body.appendChild(script);
  };

  /**
   * カルーセルアイテムをスライドさせる.
   * @param xdist
   */
  const setCurrentSlides = (xdist: number) => {
    const carouselContainer: HTMLElement = document.getElementById(
      "map_spot_carousel"
    );
    carouselContainer.setAttribute(
      "style",
      `transform: translate3d(${xdist}px, 0px, 0px);`
    );
  };

  /**
   * 選択されたマーカを排他的にバウンスさせる.
   */
  const changeCurrentItem = (index: number) => {
    mapRef.current.changeCurrentItem(index);
  };

  /**
   * 選択されたマーカを中心位置にする.
   */
  const panTo = (markerIndex: number) => {
    mapRef.current.panTo(markerIndex);
  };

  const renderSpotDOM = (spots: ISpotsSearch[]): JSX.Element[] => {
    if (spots === null || spots.length <= 0) {
      return null;
    }
    let tx = -50;
    let xdist = XDIST_BASE;
    return spots.map((spot: ISpotsSearch, index: number) => {
      xdist = xdist - XDIST_BASE;
      return (
        <SearchSpotCarouselItem
          key={index}
          mode={props.mode}
          spot={spot}
          transform={`translate3d(${tx + index * 100}%, 0px, 0px)`}
          xdist={xdist}
          xdistBase={XDIST_BASE}
          setCurrentSlides={setCurrentSlides}
          changeCurrentItem={changeCurrentItem}
          panTo={panTo}
        />
      );
    });
  };

  const mapRef = useRef(null);
  const mapDomRef = useRef(null);

  useEffect(() => {
    didMount(mapDomRef, props.spots, props.latitude, props.longitude);
  }, [props.latitude, props.longitude, props.spots]);

  //-- renderer
  return (
    <React.Fragment>
      <Map ref={mapDomRef} />
      {isUpdatedMap && (
        <CarouselContainer x-data-xdist="0" id="map_spot_carousel">
          {renderSpotDOM(props.spots)}
        </CarouselContainer>
      )}
    </React.Fragment>
  );
};
export default SearchSpotMap;

const Map = styled.div`
  height: 100%;
`;

const CarouselContainer = styled.div`
  position: absolute;
  bottom: calc(64px + env(safe-area-inset-bottom));
  width: 100%;
  transform: translate3d(0px, 0px, 0px);
  transition: all 300ms ease-in-out 0s;
`;
