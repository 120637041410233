import * as React from "react";
import styled from "styled-components";
import { fontsize, formControl } from "../../const/Mixin";
import Prefectures, { OVERALL_PREFECTURE_ID } from "../../const/Prefectures";

interface IProps {
  showAll: boolean;
  selected: number;
  fn(arg0: number): void;
  className?: string;
}

const PrefectureSelect: React.FC<IProps> = (props): JSX.Element => {
  const handleChange = (event: React.FormEvent<HTMLSelectElement>) => {
    props.fn(parseInt(event.currentTarget.value));
  };

  return (
    <Wrapper className={props.className}>
      <Select
        value={props.selected}
        onChange={(event: React.FormEvent<HTMLSelectElement>) => {
          handleChange(event);
        }}
      >
        {(() => {
          if (props.showAll) {
            return (
              <option value={OVERALL_PREFECTURE_ID} key={0}>
                全国
              </option>
            );
          }
        })()}
        {Prefectures.map((pref, index: number) => {
          return (
            <option value={pref.id} key={index + 1}>
              {pref.name}
            </option>
          );
        })}
      </Select>
    </Wrapper>
  );
};
export default PrefectureSelect;

const Wrapper = styled.div`
  width: 136px;
  margin-right: 4px;
`;

const Select = styled.select`
  ${fontsize(14)}
  ${formControl()}
`;
